// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  Grid,
  Stack,
  Text,
  NewTextField as TextField,
} from '@livingpackets/design-system-react-next';
import { PhoneNumber } from 'components/form/phoneNumber';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ContactForm } from '../../types';

export const InformationsForm = () => {
  const { t } = useTranslation(['contactAddress', 'forms']);

  const { control } = useFormContext<ContactForm>();

  return (
    <Grid container spacing="1rem">
      <Grid
        item
        container
        mobile={12}
        justifyContent="space-between"
        gap=".5rem"
      >
        <Stack gap=".5rem">
          <Text variant="titleL">
            {t('contactAddress:create.block.basicInformation.title')}
          </Text>
          <Text variant="titleXS" color="custom.neutral.black.50">
            {t('contactAddress:create.block.basicInformation.subTitle')}
          </Text>
        </Stack>
      </Grid>
      <Grid item mobile={12} tablet={6}>
        <Controller
          name="firstName"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              required
              label={t('forms:firstName.label')}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item mobile={12} tablet={6}>
        <Controller
          name="lastName"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              required
              label={t('forms:lastName.label')}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item mobile={12} tablet={6}>
        <Controller
          name="email"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              required
              label={t('forms:email.label')}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item mobile={12} tablet={6}>
        <Controller
          name="phoneNumber"
          control={control}
          render={({ field, fieldState }) => (
            <PhoneNumber
              label={t('forms:phoneNumber.label')}
              defaultCountryCode="FR"
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              {...field}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
