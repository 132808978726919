// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback } from 'react';

import PartnerContactAddressOverview from 'components/molecules/contactAddress/PartnerContactAddressOverview';
import { PATHS } from 'configs';
import {
  generatePath,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

import { CreateContact } from './CreateContact';
import { UpdateContact } from './UpdateContact';

// http://localhost:3000/partner/:partnerId/contact-address-management

/**
 * Base URL: /partner/:partnerId/contact-address-management/*
 */
export const ADDRESSES_BOOK_ROOT = '/contact-address-management';

export const AddressesBookRoutes = () => {
  const navigate = useNavigate();

  const activePartner = useMyPartnersStore(activePartnerSelector);

  const backToList = useCallback(() => {
    navigate(
      generatePath(PATHS.CONTACT_ADDRESS_MANAGEMENT.LIST, {
        partnerId: activePartner.id,
      })
    );
  }, [navigate, activePartner]);

  return (
    <Routes>
      <Route path="" index element={<PartnerContactAddressOverview />} />
      <Route
        path="create"
        element={<CreateContact backToList={backToList} />}
      />
      <Route
        path=":contactId/edit"
        element={<UpdateContact backToList={backToList} />}
      />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
};
