// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  BulletEmptyIcon,
  MarkerIcon,
} from '@livingpackets/design-system-react-next';
import LinesIcons from 'assets/img/alert/lines.svg';
import {
  Grid2 as Grid,
  Stack,
  Theme,
  Typography,
  useTheme,
} from 'materialui-next';
import { useTranslation } from 'react-i18next';

import { AlertShipmentEntity } from '../../entities/AlertShipmentEntity';

const DotToPin = ({
  theme,
  disable = false,
}: {
  theme: Theme;
  disable?: boolean;
}) => (
  <Grid
    sx={{
      opacity: disable ? 0.5 : 1,
    }}
  >
    <BulletEmptyIcon
      size="0.625rem"
      color={theme.palette.custom.decorative.red[500]}
    />
    <img src={LinesIcons} alt="lines" />
    <MarkerIcon size="1.25rem" color={theme.palette.custom.neutral.grey[800]} />
  </Grid>
);

const Step = ({ label, value, disable = false }: any) => (
  <Stack
    gap="0.125rem"
    sx={{
      opacity: disable ? 0.5 : 1,
    }}
  >
    <Typography variant="titleXXSUppercase" color="custom.neutral.grey.700">
      {label}
    </Typography>
    <Typography variant="bodyTextS500" color="custom.neutral.grey.900">
      {value}
    </Typography>
  </Stack>
);

type Props = {
  shipment: AlertShipmentEntity;
  originalShipment?: AlertShipmentEntity;
};

export const Timeline = ({ shipment, originalShipment }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation('alerts');

  if (originalShipment) {
    return (
      <Grid container spacing="0.5rem" alignItems="flex-end">
        <Step
          disable
          label={t('items.detail.destinationStartLabel')}
          value={originalShipment?.sender?.address.displayName}
        />
        <DotToPin theme={theme} disable />
        <Step
          disable
          label={t('items.detail.destinationFinishLabel')}
          value={originalShipment?.recipient?.address.displayName}
        />
        <DotToPin theme={theme} />
        <Step
          label={t('items.detail.destinationBackLabel')}
          value={shipment?.recipient?.address.displayName}
        />
      </Grid>
    );
  }

  return (
    <Grid container spacing="0.5rem" alignItems="flex-end">
      <Step
        label={t('items.detail.destinationStartLabel')}
        value={shipment?.sender?.address.displayName}
      />
      <DotToPin theme={theme} />
      <Step
        label={t('items.detail.destinationFinishLabel')}
        value={shipment?.recipient?.address.displayName}
      />
    </Grid>
  );
};
