// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useContext } from 'react';

import { EmptyState } from '@livingpackets/design-system-react-next';
import {
  MuiPickersAdapterContext,
  MuiPickersAdapterContextNullableValue,
} from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import greenDotsLoader from 'assets/lotties/green-dots-loader.json';
import { format } from 'date-fns';
import { normalizeKey } from 'helpers/i18n';
import { isEmpty } from 'lodash/fp';
import { Box, Grid2 as Grid, Typography } from 'materialui-next';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import { useLocation } from 'react-router-dom';

import { DateItem } from './DateItem';
import { Loading } from './Loading';
import { AlertEntity } from '../../entities/AlertEntity';

export const AlertsList = React.forwardRef<
  HTMLDivElement,
  {
    alerts: AlertEntity[];
    isPending: boolean;
  }
>(({ alerts, isPending }, ref) => {
  const { t } = useTranslation('alerts');
  const ctx = useContext<MuiPickersAdapterContextNullableValue<any> | null>(
    MuiPickersAdapterContext
  );

  const { search } = useLocation();
  const hasFilters = !isEmpty(search);

  // Create an array of alerts grouped by same date
  const groupedAlerts = alerts.reduce(
    (acc: Record<string, AlertEntity[]>, alert: AlertEntity) => {
      const date = format(new Date(alert.openedAt), 'd MMMM yyyy', {
        locale: ctx?.utils?.locale,
      });

      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(alert);

      return acc;
    },
    {}
  );

  const loading = (
    <Grid
      size={12}
      container
      mt="1.25rem"
      justifyContent="center"
      data-testid="loading-component"
    >
      <Grid
        sx={{
          width: '3.75rem',
          height: '3.75rem',
        }}
      >
        <Lottie
          isClickToPauseDisabled
          options={{
            loop: true,
            autoplay: true,
            animationData: greenDotsLoader,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
        />
      </Grid>
    </Grid>
  );

  if (alerts.length > 0) {
    return (
      <>
        <Grid size={12} container spacing="0.75rem">
          {Object.keys(groupedAlerts).map((date: string, index) => (
            <DateItem
              key={date}
              date={date}
              alerts={groupedAlerts[date]}
              ref={ref}
              index={index}
            />
          ))}
        </Grid>
        {isPending && loading}
      </>
    );
  }

  if (isPending) {
    return <Loading />;
  }

  return (
    <Box display="flex" justifyContent="center" width="100%">
      <EmptyState type="Results">
        <Grid container direction="column" alignItems="center" gap="6px">
          <Typography variant="titleM" color="custom.neutral.grey.800">
            {t('emptyState.noAlertsFound')}
          </Typography>
          <Typography variant="bodyTextM400" color="custom.neutral.grey.800">
            {t(
              normalizeKey(
                hasFilters
                  ? 'emptyState.noAlertsWithFiltersFoundDescription'
                  : 'emptyState.noAlertsFoundDescription'
              )
            )}
          </Typography>
        </Grid>
      </EmptyState>
    </Box>
  );
});

AlertsList.displayName = 'AlertsList';
