// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { forwardRef } from 'react';

import { BulletEmptyIcon } from '@livingpackets/design-system-react-next';
import {
  Box,
  Grid2,
  Chip as MuiChip,
  ChipProps as MuiChipProps,
} from 'materialui-next';

interface ChipProps extends MuiChipProps {
  withDot?: boolean;
}

const Chip = forwardRef<HTMLDivElement, ChipProps>(
  ({ withDot, avatar, ...props }, ref) => {
    const avatarElement = (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="0.75rem"
        height="0.75rem"
      >
        {avatar}
      </Box>
    );

    let displayAvatar;

    if (avatar && withDot) {
      displayAvatar = (
        <Grid2 container alignItems="center" spacing="0.25rem">
          {avatarElement}
          <BulletEmptyIcon size="0.5rem !important" />
        </Grid2>
      );
    } else if (avatar) {
      displayAvatar = avatarElement;
    } else if (withDot) {
      displayAvatar = <BulletEmptyIcon size="0.5rem !important" />;
    }

    return <MuiChip ref={ref} avatar={displayAvatar} {...props} />;
  }
);

Chip.displayName = 'Chip';

export default Chip;
