// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback, useEffect, useState } from 'react';

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from '@livingpackets/design-system-react';
import {
  ArrowLeftIcon,
  ButtonV2 as Button,
  MinusIcon,
  ShipmentOngoingIcon,
  ShipmentReturnIcon,
  Box,
  Text,
  Stack,
  styled,
} from '@livingpackets/design-system-react-next';
import ShipmentMapFullScreenModal from 'components/molecules/modals/ShipmentMapFullScreenModal';
import { ShipmentDirection } from 'components/organisms/PartnerShipmentsTable';
import ShipmentDetails from 'components/organisms/ShipmentDetails';
import { PATHS } from 'configs';
import { Offer } from 'features/account';
import { normalizeKey } from 'helpers/i18n';
import isCancelledState from 'helpers/isCancelledState';
import useGetPartnerOffer from 'hooks/useGetPartnerOffer';
import useShipmentDetail, { useShipmentStore } from 'hooks/useShipmentDetail';
import { IShipment, ITrackingHistoryResponse } from 'models/shipment';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

const ShipmentDirectionSelectionContainer = styled('div')`
  margin: 12px -25px -25px;
`;

const CardShipmentHeader = styled(CardHeader)`
  background-color: ${({ theme }) => theme.palette.custom.neutral.black[100]};
  color: ${({ theme }) => theme.palette.custom.neutral.white.pure};

  border-radius: 20px 20px 0 0;
  margin-bottom: -15px;
`;

const ShipmentDirectionSelectorBlock = styled(Box, {
  shouldForwardProp: prop =>
    prop !== 'isSelected' && prop !== 'isDirectionInitial',
})<{
  isSelected: boolean;
  isDirectionInitial: boolean;
}>`
  color: ${({ theme, isSelected }) =>
    isSelected
      ? theme.palette.custom.neutral.black[100]
      : theme.palette.custom.neutral.white.pure};
  background-color: ${({ theme, isSelected }) =>
    isSelected
      ? theme.palette.custom.neutral.white.pure
      : theme.palette.custom.neutral.black[100]};
  padding: 8px 25px 28px 25px;
  cursor: pointer;
  border-top-left-radius: ${({ isDirectionInitial }) =>
    isDirectionInitial ? '0' : '20px'};
  border-top-right-radius: 20px;
  transition: all 100ms ease-out;
`;

enum ShipmentDirectionEnum {
  INITIAL = 'initial',
  RETURN = 'return',
}

interface IShipmentDirectionInfo {
  shipment: IShipment;
  direction: ShipmentDirection;
}

function getRelatedShipmentInfo(
  shipment: IShipment
): IShipmentDirectionInfo | null {
  if (shipment.original_shipment) {
    return {
      shipment: shipment.original_shipment,
      direction: 'original',
    };
  } else if (shipment.return_shipment_id) {
    return {
      shipment: shipment.return_shipment!,
      direction: 'return',
    };
  } else {
    return null;
  }
}

function getCurrentShipmentDirection(
  shipment: IShipment
): ShipmentDirectionEnum {
  return shipment.original_shipment
    ? ShipmentDirectionEnum.RETURN
    : ShipmentDirectionEnum.INITIAL;
}

const PartnerShipmentsDetail = () => {
  const { t } = useTranslation('shipments');
  const navigate = useNavigate();
  const location = useLocation();
  const { shipmentId } = useParams<{ shipmentId: string }>();

  const activePartner = useMyPartnersStore(activePartnerSelector);
  const setShipment = useShipmentStore(
    (state: { setShipment: any }) => state.setShipment
  );

  const { getShipmentDetail } = useShipmentDetail();

  const { getCurrentOffer } = useGetPartnerOffer();

  const [shipmentData, setShipmentData] = useState<IShipment>();
  const [offerData, setOfferData] = useState<Offer | null>(null);

  const [relatedShipment, setRelatedShipment] =
    useState<IShipmentDirectionInfo | null>();
  const [currentShipmentDirection, setCurrentShipmentDirection] =
    useState<ShipmentDirectionEnum>();
  const [shipmentDirectionSelected, setShipmentDirectionSelected] =
    useState<ShipmentDirectionEnum>(ShipmentDirectionEnum.INITIAL);
  const [shipmentDisplayed, setShipmentDisplayed] = useState<IShipment>();

  const [showShipmentMapFullScreenModal, setShowShipmentMapFullScreenModal] =
    useState<boolean>(false);

  const isCancelled = isCancelledState(
    relatedShipment?.direction === 'return'
      ? relatedShipment?.shipment
      : relatedShipment?.shipment?.return_shipment
  );

  const [currenTrackingData, setCurrenTrackingData] = useState<
    ITrackingHistoryResponse | undefined
  >(undefined);

  let i18nFromKey = normalizeKey('detail.backToShipmentOverview');
  if (location?.state?.from === 'products') {
    i18nFromKey = normalizeKey('detail.backToProductsOverview');
  } else if (location?.state?.from === 'alerts') {
    i18nFromKey = normalizeKey('detail.backToAlertsOverview');
  }

  const initShipmentData = useCallback(
    (shipment: IShipment) => {
      setShipmentData(shipment);
      setRelatedShipment(getRelatedShipmentInfo(shipment));
      setCurrentShipmentDirection(getCurrentShipmentDirection(shipment));
      setShipmentDirectionSelected(getCurrentShipmentDirection(shipment));

      const defaultDisplayedShipment =
        currentShipmentDirection === ShipmentDirectionEnum.INITIAL
          ? shipment
          : relatedShipment
          ? relatedShipment.shipment
          : shipment;

      setShipmentDisplayed(defaultDisplayedShipment);
    },
    [
      currentShipmentDirection,
      relatedShipment,
      setShipmentData,
      setRelatedShipment,
      setCurrentShipmentDirection,
      setShipmentDisplayed,
    ]
  );

  useEffect(() => {
    if (!shipmentData && shipmentId) {
      getShipmentDetail(activePartner.id, shipmentId).then(
        ({ success, shipment }) => {
          if (!success) {
            navigate(PATHS.PAGE_404);
          } else {
            initShipmentData(shipment);
          }
        }
      );
    }

    return () => setShipment();
  }, [
    getShipmentDetail,
    activePartner,
    shipmentId,
    navigate,
    setShipment,
    shipmentData,
    initShipmentData,
  ]);

  useEffect(() => {
    getCurrentOffer().then(({ success, offer }) => {
      if (!success || !offer) {
        setOfferData(null);
      } else {
        setOfferData(offer);
      }
    });
  }, [getCurrentOffer, setOfferData]);

  const onCurrentTrackingDataUpdate = useCallback(
    (currentTrackingData?: ITrackingHistoryResponse) => {
      if (currentTrackingData) {
        setCurrenTrackingData(currentTrackingData);
      }
    },
    [setCurrenTrackingData]
  );

  const updateShipmentDirectionSelected = useCallback(
    (shipmentDirectionSelected: ShipmentDirectionEnum) => {
      setShipmentDirectionSelected(shipmentDirectionSelected);

      if (shipmentDirectionSelected === ShipmentDirectionEnum.INITIAL) {
        setShipmentDisplayed(
          currentShipmentDirection === ShipmentDirectionEnum.INITIAL
            ? shipmentData
            : relatedShipment
            ? relatedShipment.shipment
            : shipmentData
        );
      } else if (shipmentDirectionSelected === ShipmentDirectionEnum.RETURN) {
        setShipmentDisplayed(
          currentShipmentDirection === ShipmentDirectionEnum.INITIAL
            ? relatedShipment
              ? relatedShipment.shipment
              : shipmentData
            : shipmentData
        );
      }
    },
    [
      shipmentData,
      relatedShipment,
      currentShipmentDirection,
      setShipmentDirectionSelected,
      setShipmentDisplayed,
    ]
  );

  return (
    <>
      <Card>
        <CardShipmentHeader padding={5}>
          <Stack direction="row" alignItems="center">
            <Box marginRight="8px">
              <Button
                variant="tertiary"
                icon={ArrowLeftIcon}
                onClick={() => navigate(-1)}
              />
            </Box>
            <Text variant="bodyTextXS">{t(i18nFromKey)}</Text>
          </Stack>
          <Text variant="titleXS" mt="16px" color="custom.neutral.white.pure">
            {t(`detail.title`)}
          </Text>
          <ShipmentDirectionSelectionContainer>
            <Stack direction="row">
              <ShipmentDirectionSelectorBlock
                data-testid="shipment-details-tab-initial"
                isSelected={
                  shipmentDirectionSelected === ShipmentDirectionEnum.INITIAL
                }
                isDirectionInitial={true}
                onClick={() => {
                  updateShipmentDirectionSelected(
                    ShipmentDirectionEnum.INITIAL
                  );
                }}
              >
                <Stack direction="row" alignItems="center">
                  <ShipmentOngoingIcon />
                  <Text variant="bodyTextL" marginLeft="10px">
                    {t('detail.mainTab.initialShipment')}
                  </Text>
                </Stack>
              </ShipmentDirectionSelectorBlock>
              {relatedShipment && (
                <ShipmentDirectionSelectorBlock
                  data-testid="shipment-details-tab-return"
                  isSelected={
                    shipmentDirectionSelected === ShipmentDirectionEnum.RETURN
                  }
                  isDirectionInitial={false}
                  onClick={() => {
                    updateShipmentDirectionSelected(
                      ShipmentDirectionEnum.RETURN
                    );
                  }}
                >
                  <Stack direction="row" alignItems="center">
                    {isCancelled ? <MinusIcon /> : <ShipmentReturnIcon />}
                    <Text variant="bodyTextL" marginLeft="10px">
                      {t('detail.mainTab.returnShipment')}
                    </Text>
                  </Stack>
                </ShipmentDirectionSelectorBlock>
              )}
            </Stack>
          </ShipmentDirectionSelectionContainer>
        </CardShipmentHeader>
        <CardBody paddingLeft="40px" paddingRight="32px" paddingTop="13px">
          {shipmentDisplayed && offerData && (
            <ShipmentDetails
              shipment={shipmentDisplayed}
              offerData={offerData}
              onShipmentMapZoomClick={() =>
                setShowShipmentMapFullScreenModal(true)
              }
              onCurrentTrackingDataUpdate={onCurrentTrackingDataUpdate}
            />
          )}
        </CardBody>
        <CardFooter backgroundColor="transparent">
          <Stack direction="row" justifyContent="space-between" marginTop={3} />
        </CardFooter>
      </Card>

      <ShipmentMapFullScreenModal
        visible={showShipmentMapFullScreenModal}
        onDismiss={() => setShowShipmentMapFullScreenModal(false)}
        shipment={shipmentDisplayed}
        currenTrackingData={currenTrackingData}
        hideMapFullScreenModal={() => setShowShipmentMapFullScreenModal(false)}
      />
    </>
  );
};

export default PartnerShipmentsDetail;
