// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useContext } from 'react';

import { CloseIcon } from '@livingpackets/design-system-react-next';
import {
  MuiPickersAdapterContext,
  MuiPickersAdapterContextNullableValue,
} from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { format } from 'date-fns';
import { Grid2 as Grid, Typography } from 'materialui-next';

import { FilterSelectModes } from './FilterSelect';
import Chip from '../Chip';

const MAX_LABEL_LENGTH = 20;

const formattedLabel = (label: string) => {
  if (label.length > MAX_LABEL_LENGTH) {
    return label.slice(0, MAX_LABEL_LENGTH).trim() + '…';
  }

  return label;
};

type Props = {
  label: string | null;
  hideLabelWhenValue?: boolean;
  mode: string;
  value: any;
  maxDisplayed?: number;
  theme: any;
  remove: (value: any) => void;
  optionsByValue?: any;
  startIcon?: React.ElementType;
  showEndIcon: boolean;
  arrowIcon: React.ElementType;
};

export const RenderValue = ({
  label,
  hideLabelWhenValue = false,
  mode,
  value,
  maxDisplayed = 4,
  theme,
  remove,
  optionsByValue,
  startIcon,
  showEndIcon,
  arrowIcon,
}: Props) => {
  const ctx = useContext<MuiPickersAdapterContextNullableValue<any> | null>(
    MuiPickersAdapterContext
  );

  let children = null;

  // ----------------------------- //
  // ------ Mode calendar ------ //
  // ----------------------------- //
  if (mode === FilterSelectModes.calendar && value) {
    const formattedDate = format(value as Date, 'P', {
      locale: ctx?.utils?.locale,
    });

    children = (
      <Chip
        size="small"
        data-testid="chip-selected-date"
        label={formattedDate}
        onClick={remove}
        onDelete={remove}
        deleteIcon={<CloseIcon size="1rem" />}
      />
    );
  }

  const showBadge = (
    <Typography
      key="more"
      variant="labelS500"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '.125rem .25rem',
        width: 'fit-content',
        borderRadius: '.125rem',
        height: '1rem',
        border: `.0625rem solid ${theme.palette.primary[600]}`,
      }}
    >
      {`+${value.length - maxDisplayed}`}
    </Typography>
  );

  // ----------------------------- //
  // ------ Mode checkboxes ------ //
  // ----------------------------- //
  if (mode === FilterSelectModes.checkboxes) {
    // Find all parents (ie with options property)
    const allParentOptions = Object.keys(optionsByValue).filter(
      option => 'options' in optionsByValue[option]
    );

    // Find all parents with all children selected
    const fullySelectedParents = allParentOptions.filter(parentOption => {
      const children = optionsByValue[parentOption].options;
      const allChildrenSelected = children.every((child: any) =>
        value.includes(child.value)
      );

      return allChildrenSelected;
    });

    // Remove all selected children from value
    fullySelectedParents.forEach(parentOption => {
      // Get all children values of a parent
      const allChildrenValues = optionsByValue[parentOption].options.map(
        (child: any) => child.value
      );

      // Remove allChildrenValues from value
      value = value.filter((value: any) => !allChildrenValues.includes(value));
    });

    value = [...value, ...fullySelectedParents];

    children = value.length > 0 && (
      <Grid container spacing="0.25rem" alignItems="center">
        {value.slice(0, maxDisplayed).map((selectedValue: any) => (
          <Chip
            key={selectedValue}
            data-testid={`chip-selected-${selectedValue}`}
            size="small"
            label={formattedLabel(optionsByValue[selectedValue].label)}
            // Required by parent to know which value to remove
            data-selected-value={selectedValue}
            onClick={() => remove(selectedValue)}
            onDelete={() => remove(selectedValue)}
            deleteIcon={<CloseIcon size="1rem" />}
          />
        ))}
        {value.length > maxDisplayed && showBadge}
      </Grid>
    );
  }

  if (mode === FilterSelectModes.chips) {
    children = value.length > 0 && (
      <Grid container spacing="0.25rem" alignItems="center">
        {value.slice(0, maxDisplayed).map((selectedValue: any) => (
          <Chip
            withDot
            key={selectedValue}
            data-testid={`chip-selected-${selectedValue}`}
            size="small"
            color={optionsByValue[selectedValue]?.color || 'primary'}
            label={formattedLabel(
              optionsByValue[selectedValue]?.label || 'pouet'
            )}
            // Required by parent to know which value to remove
            data-selected-value={selectedValue}
            onClick={() => remove(selectedValue)}
            onDelete={() => remove(selectedValue)}
            deleteIcon={<CloseIcon size="1rem" />}
          />
        ))}
        {value.length > maxDisplayed && showBadge}
      </Grid>
    );
  }

  const hideLabel = hideLabelWhenValue ? !!value : false;

  return (
    <RenderValueLayout
      label={label || ''}
      hideLabel={hideLabel}
      theme={theme}
      startIcon={startIcon}
      showEndIcon={showEndIcon}
      arrowIcon={arrowIcon}
    >
      {children}
    </RenderValueLayout>
  );
};

const RenderValueLayout = ({
  label,
  hideLabel = false,
  theme,
  startIcon: StartIcon,
  showEndIcon,
  arrowIcon: ArrowIcon,
  children,
}: {
  label: string;
  hideLabel?: boolean;
  theme: any;
  startIcon: any;
  showEndIcon: boolean;
  arrowIcon: React.ElementType;
  children: React.ReactNode;
}) => {
  let renderLabel = (
    <Typography
      variant="bodyTextS500"
      mt=".1875rem"
      color="custom.neutral.grey.700"
    >
      {label}
    </Typography>
  );

  if (hideLabel) {
    renderLabel = <></>;
  }

  return (
    <>
      {StartIcon && (
        <StartIcon color={theme.palette.custom.neutral.grey[400]} size="1rem" />
      )}
      {renderLabel}
      {children}

      {showEndIcon && (
        <ArrowIcon color={theme.palette.custom.neutral.grey[400]} size="1rem" />
      )}
    </>
  );
};
