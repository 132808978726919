// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Grid,
  ButtonV2 as Button,
} from '@livingpackets/design-system-react-next';
import { Partnership } from 'features/account';
import { isEqual } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { contactSchema } from 'schemas/contactAddressSchema';
import { filterEditableValues } from 'utils/filterEditableValues';

import { Addresses } from './Addresses';
import { Informations } from './Informations';
import { ContactEntity } from '../../entities/ContactEntity';
import {
  CONTACT_FORM_KEYS,
  ContactAddressForm,
  ContactForm,
} from '../../types';

type Props = {
  contact: ContactEntity;
  onSubmit: (
    initialValues: ContactForm
  ) => (data: ContactForm) => Promise<void>;
  backToList: () => void;
  partnership: Partnership;
};

export const Detail = ({
  contact,
  onSubmit,
  backToList,
  partnership,
}: Props) => {
  const { t } = useTranslation(['contactAddress']);

  const [editModeContact, setEditModeContact] = useState(false);
  const [editModeAddresses, setEditModeAddresses] = useState<
    undefined | number
  >();

  const response = filterEditableValues({
    values: contact,
    fields: CONTACT_FORM_KEYS,
  }) as ContactForm;

  const computedInitialValues = React.useMemo(
    () => ({
      ...response,
      addresses: response.addresses.map((addr: ContactAddressForm) => ({
        ...addr,
        defaultSender: partnership.default_sender_address_id === addr.id,
        defaultRecipient: partnership.default_recipient_address_id === addr.id,
      })),
    }),
    [
      response,
      partnership.default_sender_address_id,
      partnership.default_recipient_address_id,
    ]
  );

  const methods = useForm<ContactForm>({
    defaultValues: computedInitialValues,
    resolver: yupResolver(contactSchema()),
    mode: 'onTouched',
  });

  useEffect(() => {
    if (!isEqual(methods.formState.defaultValues, computedInitialValues)) {
      methods.reset(computedInitialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [computedInitialValues]);

  const {
    reset,
    handleSubmit,
    formState: { isValid, isDirty },
  } = methods;

  const onDismiss = () => {
    if (editModeAddresses === undefined && !editModeContact) {
      backToList();
    } else {
      setEditModeContact(false);
      setEditModeAddresses(undefined);
      reset(computedInitialValues);
    }
  };

  return (
    <FormProvider {...methods}>
      <Grid container direction="column" spacing="3rem">
        <Grid item mobile={12}>
          <Informations
            contact={contact}
            editMode={editModeContact}
            setEditMode={setEditModeContact}
          />
        </Grid>
        <Grid item mobile={12}>
          <Addresses
            addresses={contact.addresses}
            editMode={editModeAddresses}
            setEditMode={setEditModeAddresses}
          />
        </Grid>
        <Grid
          item
          container
          mobile={12}
          justifyContent="space-between"
          gap=".5rem"
        >
          <Button
            data-testid="dismissBtn"
            variant="secondary"
            onClick={onDismiss}
          >
            {t('contactAddress:form.dismiss.label')}
          </Button>
          <Button
            data-testid="submitBtn"
            variant="primary"
            disabled={!isValid || !isDirty}
            onClick={handleSubmit(onSubmit(computedInitialValues))}
          >
            {t('contactAddress:form.submit.label')}
          </Button>
        </Grid>
      </Grid>
    </FormProvider>
  );
};
