// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useQuery } from '@tanstack/react-query';
import useImperativeRequest from 'hooks/useImperativeRequest';
import { ExtractFnReturnType, QueryConfig } from 'lib/react-query';
import { useParams } from 'react-router-dom';

import { AlertsStatsEntity } from '../entities/AlertsStatsEntity';

export const getAlertsDashboard = async ({
  partnerId,
  makeRequest,
}: {
  partnerId: string;
  makeRequest: any;
}): Promise<AlertsStatsEntity> => {
  const { data, error } = await makeRequest({
    path: `me/partners/${partnerId}/alerts/stats`,
  });

  if (error) {
    throw new Error(error);
  }

  return AlertsStatsEntity.createFromAPI(data);
};

type QueryFnType = typeof getAlertsDashboard;

type UseAlertsDashboardOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useAlertsDashboard = ({
  config,
}: UseAlertsDashboardOptions = {}) => {
  const { partnerId } = useParams() as { partnerId: string };

  const [, makeRequest] = useImperativeRequest('deviceV3');

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['partnership', partnerId, 'alerts-stats'],
    queryFn: () => getAlertsDashboard({ partnerId, makeRequest }),
  });
};
