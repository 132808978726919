// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { AddressTypesKeys } from 'features/account';
import { SentryCaptureException } from 'helpers/tracking';

import { AddressAPI } from '../api/models/AddressAPI';
import { ContactAddressType } from '../types';

export class AddressEntity {
  static create(data: AddressAPI): AddressEntity {
    try {
      // Validate data to ensure it matches the ProductAPI type
      if (!data || typeof data !== 'object') {
        throw new Error('Invalid data: data must be an object');
      }

      return new AddressEntity(data);
    } catch (error: any) {
      SentryCaptureException(error, null);

      throw new Error(`Failed to create ProductEntity: ${error.message}`);
    }
  }

  id: string;
  displayName: string;
  company: string;
  firstName: string;
  lastName: string;
  houseNumber: string;
  phoneNumber: string;
  email: string;
  building: string;
  street: string;
  street2: string;
  postalCode: string;
  city: string;
  state: string;
  countryCode: string;
  addressTypes: AddressTypesKeys[];
  type: ContactAddressType;

  constructor(data: AddressAPI) {
    this.id = data.id;
    this.displayName = data.displayName;
    this.company = data.company;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.houseNumber = data.houseNumber;
    this.phoneNumber = data.phoneNumber;
    this.email = data.email;
    this.building = data.building;
    this.street = data.street;
    this.street2 = data.street2;
    this.postalCode = data.postalCode;
    this.city = data.city;
    this.state = data.state;
    this.countryCode = data.countryCode;
    this.addressTypes = data.addressTypes;
    this.type = data.type;
  }
}
