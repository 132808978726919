// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useRef, useState } from 'react';

import {
  Box,
  BulletWrongIcon,
  ChevronDownIcon,
  SearchIcon,
  Stack,
  Text,
  NewTextField as TextField,
  useTheme,
} from '@livingpackets/design-system-react-next';
import { normalizeKey } from 'helpers/i18n';
import { isEmpty } from 'lodash/fp';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  InputContainer,
  InputIconContainer,
  PartnerAccountSearchFieldContainer,
  PartnerAccountSearchFieldListContainer,
  SearchFieldIconContainer,
  SearchFieldSelector,
} from './Other';

const criteria = ['last_name', 'first_name', 'email'];

export const TextFieldSelect = () => {
  const theme = useTheme();
  const { t } = useTranslation(['general', 'userManagement']);

  const { control, watch, setValue } = useFormContext();
  const formValues = watch();

  const textFieldRef = useRef<HTMLInputElement | null>(null);

  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [isHighlighted, setIsHighLighted] = useState(false);

  return (
    <Stack direction="row">
      <Stack sx={{ position: 'relative' }}>
        <SearchFieldSelector
          isHighlighted={isHighlighted}
          tabIndex={0}
          onClick={() => {
            setIsSelectOpen(prev => !prev);
            setIsHighLighted(prev => !prev);
            textFieldRef.current?.focus();
          }}
          onBlur={() => {
            // Wait a short amount time in order avoid the field selection problem
            setTimeout(function () {
              if (isSelectOpen) setIsSelectOpen(false);
              textFieldRef.current?.focus();
            }, 500);
          }}
          data-testid="partner-account-search-field-selector"
        >
          <Stack
            flex={1}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text variant="bodyTextM" color="custom.neutral.black.50">
              {t(
                normalizeKey(
                  `userManagement:list.partnerAccountSearchField.${formValues.key}`
                )
              )}
            </Text>
            <SearchFieldIconContainer>
              <ChevronDownIcon
                color={
                  isSelectOpen
                    ? theme.palette.custom.primary[100]
                    : theme.palette.custom.neutral.black[50]
                }
              />
            </SearchFieldIconContainer>
          </Stack>
        </SearchFieldSelector>
        {isSelectOpen && (
          <PartnerAccountSearchFieldListContainer>
            {criteria.map(field => (
              <PartnerAccountSearchFieldContainer
                onClick={() => {
                  setValue('key', field);
                  setIsSelectOpen(false);
                  textFieldRef.current?.focus();
                }}
                key={field}
                data-testid={'partner-account-search-field-' + field}
              >
                <Text variant="bodyTextM" color="custom.neutral.black.90">
                  {t(
                    normalizeKey(
                      `userManagement:list.partnerAccountSearchField.${field}`
                    )
                  )}
                </Text>
              </PartnerAccountSearchFieldContainer>
            ))}
          </PartnerAccountSearchFieldListContainer>
        )}
      </Stack>
      <InputContainer style={{ marginLeft: 0 }}>
        <Controller
          name="value"
          control={control}
          render={({ field: { ref, ...rest }, fieldState }) => (
            <TextField
              data-testid="partner-account-search-input"
              placeholder={t('userManagement:list.inputSearch.placeholder')}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              fullWidth
              onFocus={() => {
                setIsHighLighted(true);
              }}
              sx={{
                '& .MuiInputBase-root': {
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  borderStyle: isSelectOpen
                    ? 'solid solid solid none'
                    : isHighlighted
                    ? 'solid solid solid none'
                    : 'none',
                  '&:hover': {
                    borderColor: theme.palette.custom.primary[100],
                  },
                  '& .MuiFilledInput-input': {
                    pb: '1.5625rem',
                  },
                  borderColor: isHighlighted
                    ? theme.palette.custom.primary[100]
                    : theme.palette.custom.neutral.white.pure,
                },
              }}
              {...rest}
              ref={e => {
                ref(e);
                textFieldRef.current = e; // you can still assign to ref
              }}
              onBlur={() => setIsHighLighted(false)}
            />
          )}
        />
        <InputIconContainer
          displaySearchMagnifierIcon={isEmpty(formValues.value)}
        >
          {isEmpty(formValues.value) ? (
            <SearchIcon color={theme.palette.custom.primary[100]} />
          ) : (
            <Box
              role="button"
              aria-label={t('general:clearSearch')}
              title={t('general:clearSearch')}
              onClick={() => setValue('value', '')}
              sx={{ cursor: 'pointer' }}
            >
              <BulletWrongIcon size="1rem" />
            </Box>
          )}
        </InputIconContainer>
      </InputContainer>
    </Stack>
  );
};
