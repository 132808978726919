// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { PaletteMode } from 'materialui-next/styles';

export const palette = {
  mode: 'light' as PaletteMode,
  primary: {
    25: '#ecfff7',
    50: '#cdf6e5',
    100: '#9be9c8',
    200: '#68dbab',
    300: '#00bf6f',
    400: '#0ca86d',
    500: '#059c67',
    600: '#0f7a54',
    700: '#0a6344',
    800: '#0d4a37',
    900: '#0f302a',
  },
  custom: {
    neutral: {
      grey: {
        0: '#ffffff',
        10: '#fafbfb',
        25: '#f4f6f6',
        50: '#e5ecea',
        100: '#ccd8d6',
        200: '#b2c5c1',
        300: '#99b1ad',
        400: '#809e98',
        500: '#5a817a',
        600: '#33645b',
        700: '#1a5047',
        800: '#003d32',
        900: '#000b07',
      },
    },
    semantics: {
      disabled: {
        25: '#f4f6f6',
        100: '#ccd8d6',
      },
      error: {
        50: '#fff3f2',
        100: '#ffe6e5',
        200: '#ffc6c2',
        300: '#ffb3ae',
        400: '#ed877f',
        500: '#da5b50',
        600: '#c82f21',
        700: '#a52319',
        800: '#821611',
        900: '#4e100e',
      },
      info: {
        50: '#dfebfc',
        100: '#bfd7f8',
        200: '#9fc3f5',
        300: '#80aff2',
        400: '#609bee',
        500: '#4087eb',
        600: '#2073e7',
        700: '#005fe4',
        800: '#003e89',
        900: '#002e5b',
      },
      success: {
        25: '#ecfff7',
        50: '#cdf6e5',
        100: '#9be9c8',
        200: '#68dbab',
        300: '#00bf6f',
        400: '#0ca86d',
        500: '#059c67',
        600: '#0f7a54',
        700: '#0a6344',
        800: '#0d4a37',
        900: '#0f302a',
      },
      warning: {
        50: '#fcf2dc',
        100: '#fae1a9',
        200: '#f9d990',
        300: '#f8d076',
        400: '#ddb15b',
        500: '#c39240',
        600: '#a87325',
        700: '#8d540a',
        800: '#773a13',
        900: '#5f2c12',
      },
    },
    decorative: {
      avocado: {
        50: '#eff5e3',
        100: '#e1edc5',
        200: '#d2e5a8',
        300: '#bad77b',
        400: '#9bba60',
        500: '#7d9c46',
        600: '#5f7f2b',
        700: '#406110',
        800: '#304524',
        900: '#1d2818',
      },
      green: {
        25: '#ecfff7',
        50: '#cdf6e5',
        100: '#9be9c8',
        200: '#68dbab',
        300: '#00bf6f',
        400: '#0ca86d',
        500: '#059c67',
        600: '#0f7a54',
        700: '#0a6344',
        800: '#0d4a37',
        900: '#0f302a',
      },
      lila: {
        50: '#f7f4ff',
        100: '#f0e8ff',
        200: '#e8ddff',
        300: '#dbcafe',
        400: '#cdb7fc',
        500: '#b094ea',
        600: '#9372d8',
        700: '#7550c6',
        800: '#582db4',
        900: '#3f2684',
      },
      orange: {
        50: '#fff4f0',
        100: '#fcebe0',
        200: '#ffe2d1',
        300: '#fccbae',
        400: '#fab38c',
        500: '#f79c69',
        600: '#d88252',
        700: '#b9693c',
        800: '#9a4f25',
        900: '#6d251b',
      },
      purple: {
        50: '#fff0ff',
        100: '#ffe8fe',
        200: '#ffdbfe',
        300: '#ffcdfd',
        400: '#ffc0fd',
        500: '#ec9cea',
        600: '#d979d6',
        700: '#c555c3',
        800: '#b231af',
        900: '#8e288e',
      },
      red: {
        50: '#fff3f2',
        100: '#ffe6e5',
        200: '#ffc6c2',
        300: '#ffb3ae',
        400: '#ed877f',
        500: '#da5b50',
        600: '#c82f21',
        700: '#a52319',
        800: '#821611',
        900: '#4e100e',
      },
      turquoise: {
        50: '#e9f8f8',
        100: '#d2f1f1',
        200: '#bceaea',
        300: '#a2e2e2',
        400: '#87d9d9',
        500: '#6abdbd',
        600: '#4ea1a1',
        700: '#318484',
        800: '#146868',
        900: '#0c3d3c',
      },
      yellow: {
        50: '#fcf2dc',
        100: '#fae1a9',
        200: '#f9d990',
        300: '#f8d076',
        400: '#ddb15b',
        500: '#c39240',
        600: '#a87325',
        700: '#8d540a',
        800: '#773a13',
        900: '#5f2c12',
      },
    },
  },
};
