// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import greenDotsLoader from 'assets/lotties/green-dots-loader.json';
import { Grid2 as Grid, Typography } from 'materialui-next';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';

/**
 * Custom Loading component to match new design system
 * No design provided
 **/
export const Loading = ({ height = '25.3125rem' }: { height?: string }) => {
  const { t } = useTranslation('account');

  return (
    <Grid
      size={12}
      container
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      height={height}
      gap=".875rem"
      data-testid="loading-component"
    >
      <Grid
        size={12}
        sx={{
          marginTop: '-1.875rem',
          width: '3.75rem',
          height: '3.75rem',
        }}
      >
        <Lottie
          isClickToPauseDisabled
          options={{
            loop: true,
            autoplay: true,
            animationData: greenDotsLoader,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
        />
      </Grid>
      <Grid
        size={12}
        container
        justifyContent="center"
        alignItems="center"
        gap=".125rem"
      >
        <Grid container direction="column" alignItems="center" gap="6px">
          <Typography variant="titleM" color="custom.neutral.grey.800">
            {t('loading')}
          </Typography>
          <Typography variant="bodyTextM400" color="custom.neutral.grey.800">
            {t('loadingMessage')}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
