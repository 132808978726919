// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useMutation } from '@tanstack/react-query';
import {
  Partnership,
  usePartnership,
  useUpdatePartnership,
} from 'features/account';
import useImperativeRequest from 'hooks/useImperativeRequest';
import { useParams } from 'react-router-dom';

import { AddressEntity } from '../entities/AddressEntity';

const removeAnyDefaultAddress = async ({
  partner,
  addressId,
  updatePartnership,
}: {
  partner: Partnership;
  addressId: string;
  updatePartnership: Function;
}) => {
  const patchedData: any = {};

  if (partner.default_sender_address_id === addressId) {
    patchedData.default_sender_address_id = '';
  }

  if (partner.default_recipient_address_id === addressId) {
    patchedData.default_recipient_address_id = '';
  }

  const shouldUpdate =
    patchedData.default_sender_address_id !==
      partner.default_sender_address_id ||
    patchedData.default_recipient_address_id !==
      partner.default_recipient_address_id;

  if (shouldUpdate) {
    await updatePartnership({
      values: patchedData,
      partnershipId: partner.id,
    });
  }
};

const deleteAddress = async ({
  makeRequest,
  partnerId,
  address,
}: {
  makeRequest: any;
  partnerId: string;
  address: AddressEntity;
}): Promise<AddressEntity> => {
  const { error } = await makeRequest({
    path: `api/v1/me/partners/${partnerId}/addresses/${address.id}`,
    method: 'delete',
  });

  if (error) {
    throw new Error(error);
  }

  return address;
};

type UseDeleteAddressOptions = {
  address: AddressEntity;
};

export const useDeleteAddress = ({ address }: UseDeleteAddressOptions) => {
  const { partnerId } = useParams() as {
    partnerId: string;
  };
  const [, makeRequest] = useImperativeRequest('lpVillage');

  const { data: partner } = usePartnership();
  const { mutateAsync: updatePartnership } = useUpdatePartnership({
    showSuccessToast: false,
  });

  return useMutation({
    onSuccess: async (address: AddressEntity) => {
      removeAnyDefaultAddress({
        partner: partner!,
        addressId: address.id,
        updatePartnership,
      });
    },
    mutationFn: () => deleteAddress({ makeRequest, partnerId, address }),
  });
};
