// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  BoxOpenIcon,
  ButtonV2 as Button,
  Grid,
  HexagoneExclamationMark,
  LockOnIcon2,
  Stack,
  Text,
  useTheme,
} from '@livingpackets/design-system-react-next';
import { PARTNER_PREFIX_ROOT } from 'components/containers/PartnerRouter';
import { ShipmentTimelineEventTypeEnum } from 'enums/ShipmentTimelineEnum';
import { Loading } from 'features/account';
import { normalizeKey } from 'helpers/i18n';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

import { Card } from './Card';
import { Error } from './Error';
import { useAlertsDashboard } from '../api/getAlertsDashboard';
import { AlertStatsItemEntity } from '../entities/AlertsStatsEntity';

const getIcon = (alert_key: string) => {
  switch (alert_key.toUpperCase()) {
    case ShipmentTimelineEventTypeEnum.ALERT_BOX_VIOLATION:
      return BoxOpenIcon;
    case ShipmentTimelineEventTypeEnum.ALERT_BOX_CODE:
      return LockOnIcon2;
  }
};

export const AlertsDashboard = () => {
  const theme = useTheme();
  const { t } = useTranslation(['dashboard', 'partnerSettings']);

  const currentPartner = useMyPartnersStore(activePartnerSelector);
  const alertsBaseUrl = `${PARTNER_PREFIX_ROOT}/${currentPartner.id}/alerts`;

  const { data: alerts, isPending, error } = useAlertsDashboard();

  let children = null;

  if (isPending) {
    children = <Loading height="7.875rem" />;
  }

  if (error) {
    children = (
      <Grid
        container
        justifyContent="center"
        alignContent="center"
        minHeight="7.875rem"
      >
        <Error />
      </Grid>
    );
  }

  if (alerts) {
    children = [
      ...Object.keys(alerts).map(alert_key => {
        const alert = alerts[
          alert_key as keyof typeof alerts
        ] as AlertStatsItemEntity;

        let label;
        const nbAlerts = alert.nbAlerts || 0;

        if (nbAlerts === 0) {
          label = t('dashboard:alerts.cardLabelAlert', {
            count: 0,
          });
        } else if (nbAlerts === 1) {
          label = t('dashboard:alerts.cardLabelAlert', {
            count: alert?.nbShipments || 0,
          });
        } else if (nbAlerts > 1) {
          label = t('dashboard:alerts.cardLabelMore', {
            count: alert?.nbShipments || 0,
          });
        }

        return (
          <Grid item key={alert_key} mobile={12} tablet={4}>
            <Card
              hasBorder
              dataTestId={`alert-stats-${alert_key}`}
              icon={getIcon(alert_key)}
              title={t(normalizeKey(`dashboard:alerts.${alert_key}.title`))}
              tooltip={
                <Text variant="bodyTextS">
                  {t(normalizeKey(`dashboard:alerts.${alert_key}.tooltip`))}
                </Text>
              }
              color={
                (alert.nbAlerts || 0) === 0
                  ? theme.palette.custom.neutral.black[50]
                  : theme.palette.custom.error.red[50]
              }
              value={alert.nbAlerts || 0}
              label={label}
              to={`${alertsBaseUrl}?type=${alert_key}`}
            />
          </Grid>
        );
      }),
      <Grid key="see-all-alerts" item mobile={12} tablet={4}>
        <Grid
          container
          justifyContent="flex-end"
          alignContent="flex-end"
          height="100%"
        >
          <Button
            variant="flat"
            {...{ component: Link, to: alertsBaseUrl }}
            sx={{ textAlign: 'right' }}
          >
            {t('dashboard:alerts.seeAllAlerts')}
          </Button>
        </Grid>
      </Grid>,
    ];
  }

  return (
    <Grid container spacing="0.75rem">
      {/* Header */}
      <Grid item mobile={12}>
        <Grid container>
          <Grid item>
            <Stack direction="row" gap="0.5rem">
              <HexagoneExclamationMark />
              <Stack gap="0.25rem">
                <Text variant="titleL">{t('dashboard:alerts.title')}</Text>
                <Text variant="bodyTextS" color="custom.neutral.black.90">
                  {t('dashboard:alerts.subtitle')}
                </Text>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      {/* Cards */}
      <Grid item mobile={12}>
        <Grid
          data-testid="alerts-dashboard"
          container
          padding="1.5rem"
          gap="1.25rem"
          flexWrap={{ mobile: 'wrap', tablet: 'nowrap' }}
          bgcolor="white"
          borderRadius="1.25rem"
        >
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};
