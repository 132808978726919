// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  ButtonV2 as Button,
  CloseIcon,
  Grid,
  RadioButton,
  RadioButtonGroup,
  NewTextField as TextField,
} from '@livingpackets/design-system-react-next';
import { RHFSelectCountry } from 'components/form/SelectCountry';
import { ContactAddressType } from 'models/contactAddress';
import {
  Controller,
  UseFieldArrayRemove,
  useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DefaultAddresses } from './DefaultAddresses';
import { ContactForm } from '../../types';

const getName = (name: string, index: number) =>
  `addresses.${index}.${name}` as any;

type Props = {
  index: number;
  addressId?: string;
  canRemoveAddress?: boolean;
  remove: UseFieldArrayRemove;
};

export const ItemAddress = ({
  index,
  addressId,
  canRemoveAddress,
  remove,
}: Props) => {
  const { t } = useTranslation(['contactAddress', 'forms']);

  const { control, watch, setValue, clearErrors } =
    useFormContext<ContactForm>();

  return (
    <Grid container spacing="1rem">
      <Grid item container mobile={12} justifyContent="flex-end">
        <Grid item mobile={12}>
          <Grid container justifyContent="space-between" alignItems="baseline">
            <Grid item>
              <Controller
                control={control}
                name={getName('type', index)}
                render={({ field: { onChange, ...rest } }) => (
                  <RadioButtonGroup
                    row
                    onChange={e => {
                      onChange(e);
                      setValue(getName('company', index), undefined);
                      clearErrors(getName('company', index));
                    }}
                    {...rest}
                  >
                    <RadioButton
                      data-testid="radioBtnProfessionnal"
                      label={t('contactAddress:form.type.choices.professional')}
                      value={ContactAddressType.professional}
                    />
                    <RadioButton
                      data-testid="radioBtnPersonal"
                      label={t('contactAddress:form.type.choices.personal')}
                      value={ContactAddressType.personal}
                    />
                  </RadioButtonGroup>
                )}
              />
            </Grid>
            <Grid item>
              {canRemoveAddress && (
                <Button
                  variant="secondary"
                  icon={CloseIcon}
                  onClick={() => remove(index)}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item mobile={12} tablet={6}>
        <Controller
          name={getName('company', index)}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              required
              disabled={
                watch(getName('type', index)) === ContactAddressType.personal
              }
              label={t('contactAddress:form.companyName.placeholder')}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item mobile={12} tablet={6}>
        <Controller
          name={getName('street', index)}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              required
              label={t('contactAddress:form.address.placeholder')}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item mobile={12} tablet={6}>
        <Controller
          name={getName('city', index)}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              required
              label={t('contactAddress:form.city.placeholder')}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item mobile={12} tablet={6}>
        <Controller
          name={getName('postalCode', index)}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              required
              label={t('contactAddress:form.zipCode.placeholder')}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item mobile={12} tablet={6}>
        <RHFSelectCountry
          name={getName('countryCode', index)}
          control={control}
        />
      </Grid>
      <Grid
        item
        tablet={6}
        sx={{ display: { mobile: 'none', tablet: 'initial' } }}
      />
      <Grid item mobile={12}>
        <DefaultAddresses
          addressId={addressId}
          index={index}
          getName={(name: string) => getName(name, index)}
        />
      </Grid>
    </Grid>
  );
};
