// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { Dispatch, SetStateAction, useState } from 'react';

import {
  Box,
  Chip,
  DeleteIcon,
  EditIcon,
  Grid,
  Stack,
  Text,
} from '@livingpackets/design-system-react-next';
import DeleteAddressModal from 'components/molecules/modals/DeleteAddressModal';
import { normalizeKey } from 'helpers/i18n';
import useCountryCodesWrapper from 'hooks/useCountryCodeWrapper';
import useToastMessages from 'hooks/useToastMessages';
import { queryClient } from 'lib/react-query';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Card from './Card';
import { useDeleteAddress } from '../../api/deleteAddress';
import { useContact } from '../../api/getContact';
import { AddressEntity } from '../../entities/AddressEntity';

type Props = {
  index: number;
  address: AddressEntity;
  canDeleteAddress: boolean;
  setEditMode: Dispatch<SetStateAction<number | undefined>>;
  editMode: number | undefined;
  editAddress: () => void;
};

export const AddressCard = ({
  index,
  address,
  canDeleteAddress,
  editAddress,
  setEditMode,
  editMode,
}: Props) => {
  const { t } = useTranslation('contactAddress');
  const { partnerId, contactId } = useParams() as {
    partnerId: string;
    contactId: string;
  };

  const { reset } = useFormContext();

  const { data: contact } = useContact({ contactId });
  const { error: toastError, success: toastSuccess } = useToastMessages();
  const [, getCountryNameFromCode] = useCountryCodesWrapper();
  const { mutateAsync: deleteAddress, isPending } = useDeleteAddress({
    address,
  });

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const onDelete = async () => {
    try {
      await deleteAddress();
      toastSuccess('messages:contactDeleteSuccess.message');

      const contactWithoutDeletedAddress = {
        ...contact,
        addresses: contact!.addresses.filter(adr => adr.id !== address.id),
      };

      queryClient.setQueryData(
        ['partnership', partnerId, 'contact', contactId],
        contactWithoutDeletedAddress
      );

      reset(contactWithoutDeletedAddress);
    } catch {
      toastError('messages:contactAddressDeleteError.message');
    }
  };

  return (
    <>
      <Card
        onClick={editAddress}
        isSelected={editMode === index}
        data-testid="address-information-card"
      >
        <Grid container>
          <Grid item mobile={12}>
            <Stack direction="row" justifyContent="space-between">
              <Box>
                {address.type && (
                  <Chip
                    state="default"
                    type="tags"
                    variant="titleXS"
                    label={t(normalizeKey('form.type.choices.' + address.type))}
                  />
                )}
              </Box>
              <Box display="flex">
                {canDeleteAddress && (
                  <Box
                    data-testId="deleteAddressIcon"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setEditMode(undefined);
                      setIsDeleteModalOpen(true);
                    }}
                  >
                    <DeleteIcon className="DeleteIcon" />
                  </Box>
                )}
                <Box>
                  <EditIcon />
                </Box>
              </Box>
            </Stack>
          </Grid>
          <Grid item mobile={12}>
            <Grid container mt="1rem">
              {address.company && (
                <Grid item mobile={12}>
                  <Text variant="titleXS">{address.company}</Text>
                </Grid>
              )}
              <Grid item mobile={12}>
                <Text variant="bodyTextM">{address.street}</Text>
              </Grid>
              <Grid item mobile={12}>
                <Stack direction="row" spacing="0.25rem">
                  {address.postalCode && (
                    <Text variant="bodyTextM">{address.postalCode}</Text>
                  )}
                  {address.city && (
                    <Text variant="bodyTextM">{address.city}</Text>
                  )}
                </Stack>
              </Grid>
              <Grid item mobile={12}>
                <Text variant="bodyTextM">
                  {getCountryNameFromCode(address.countryCode)}
                </Text>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
      <DeleteAddressModal
        loading={isPending}
        onCancel={() => setIsDeleteModalOpen(false)}
        onSubmit={onDelete}
        open={isDeleteModalOpen}
        addressId={address.id}
      />
    </>
  );
};
