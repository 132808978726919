// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  AddIcon,
  ButtonV2 as Button,
  Grid,
  Text,
} from '@livingpackets/design-system-react-next';
import { PATHS } from 'configs';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { TextFieldSelect } from './TextFieldSelect';

export const Filters = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('userManagement');
  const { partnerId } = useParams() as { partnerId: string };

  const goToNewMember = () =>
    navigate(
      generatePath(PATHS.USER_MANAGEMENT.CREATE, {
        partnerId,
      })
    );

  return (
    <Grid container spacing="1.25rem">
      <Grid item mobile={12}>
        <Text variant="titleM">{t('pageListSubTitle')}</Text>
      </Grid>
      <Grid item mobile={12}>
        <Grid container spacing="2rem">
          <Grid item flex={1}>
            <TextFieldSelect />
          </Grid>
          <Grid item mobile={12} tablet="auto">
            <Button
              fullWidth
              icon={AddIcon}
              onClick={goToNewMember}
              data-testid="create-member-button"
              sx={{ textWrap: 'nowrap' }}
            >
              {t('list.addNewMemberBtn')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
