// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import {
  Partnership,
  usePartnership,
  useUpdatePartnership,
} from 'features/account';
import useToastMessages from 'hooks/useToastMessages';
import { useParams } from 'react-router-dom';
import { hasChangedValues } from 'utils/filterEditableValues';

import { useCreateAddress } from '../api/createAddress';
import { useContact } from '../api/getContact';
import { useUpdateAddress } from '../api/updateAddress';
import { useUpdateContact } from '../api/updateContact';
import { AddressEntity } from '../entities/AddressEntity';
import { ContactAddressForm, ContactForm as IContactForm } from '../types';

export const useUpdateContactView = ({
  backToList,
}: {
  backToList: () => void;
}) => {
  const { contactId } = useParams() as { contactId: string };

  const { data: contact } = useContact({ contactId });
  const { data: partnership } = usePartnership();

  const { mutateAsync: updateContact } = useUpdateContact();
  const { mutateAsync: createAddress } = useCreateAddress();
  const { mutateAsync: updateAddress } = useUpdateAddress();
  const { mutateAsync: updatePartnership } = useUpdatePartnership({
    showSuccessToast: false,
  });

  const { error: toastError, success: toastSuccess } = useToastMessages();

  const handleTheContact = async ({ initialContact, contactData }: any) => {
    const contactValuesChanged = hasChangedValues({
      initialValues: initialContact,
      values: contactData,
    });

    if (contactValuesChanged) {
      await updateContact(contactData);
    }

    return contactValuesChanged;
  };

  const handleTheAddresses = async ({
    addresses,
    initialAddresses,
    contactData,
  }: {
    addresses: ContactAddressForm[];
    initialAddresses: any;
    contactData: any;
  }): Promise<{
    addressesValuesChanged: boolean;
    newAddress?: AddressEntity;
  }> => {
    const hasChanged = ({ id }: { id?: string }, index: number) =>
      id !== undefined &&
      hasChangedValues({
        initialValues: initialAddresses[index],
        values: addresses.find(addr => addr.id === id),
      });

    const addressesToUpdate = addresses.filter(hasChanged as any);

    const addressToCreate = addresses.filter(({ id }) => id === undefined);

    let addressesValuesChanged = false;

    // Update
    if (addressesToUpdate.length > 0) {
      addressesValuesChanged = true;

      await updateTheAddresses({
        addressesToUpdate,
        initialAddresses,
        contactData,
      });
    }

    // Create
    let newAddress: AddressEntity | undefined;
    if (addressToCreate.length > 0) {
      addressesValuesChanged = true;
      const address = addressToCreate[0];

      newAddress = await createAddress({
        contactId: contact!.id,
        values: {
          type: address.type,
          city: address.city,
          company: address.company,
          countryCode: address.countryCode,
          street: address.street,
          postalCode: address.postalCode,
          firstName: contactData.firstName,
          lastName: contactData.lastName,
          email: contactData.email,
          phoneNumber: contactData.phoneNumber,
        },
      });
    }

    return {
      addressesValuesChanged: addressesValuesChanged || !!newAddress,
      newAddress,
    };
  };

  const updateTheAddresses = async ({
    addressesToUpdate,
    initialAddresses,
    contactData,
  }: {
    addressesToUpdate: ContactAddressForm[];
    initialAddresses: ContactAddressForm[];
    contactData: Omit<IContactForm, 'addresses'>;
  }): Promise<void> => {
    await Promise.all(
      addressesToUpdate.map(({ id, ...address }: any, index: any) => {
        const initialAddress = initialAddresses.find(
          addr => addr.id === id
        ) as ContactAddressForm;

        return updateAddress({
          contactId: contact!.id,
          addressId: id!,
          initialValues: initialAddress,
          values: {
            type: address.type,
            city: address.city,
            company: address.company,
            countryCode: address.countryCode,
            street: address.street,
            postalCode: address.postalCode,
            firstName: contactData.firstName,
            lastName: contactData.lastName,
            email: contactData.email,
            phoneNumber: contactData.phoneNumber,
          },
        });
      })
    );
  };

  const handleDefaultAddresses = async ({
    addresses,
    newAddress,
  }: {
    addresses: ContactAddressForm[];
    newAddress?: AddressEntity;
  }) => {
    const patchedData: Pick<
      Partnership,
      'default_sender_address_id' | 'default_recipient_address_id'
    > = {};

    addresses.forEach(address => {
      // Having an address id means existing address
      if (address.id) {
        if (address.defaultSender) {
          patchedData.default_sender_address_id = address.id;
        } else if (partnership!.default_sender_address_id === address.id) {
          // Do not override a previously set default sender
          if (!patchedData.default_sender_address_id) {
            patchedData.default_sender_address_id = '';
          }
        }

        if (address.defaultRecipient) {
          patchedData.default_recipient_address_id = address.id;
        } else if (partnership!.default_recipient_address_id === address.id) {
          // Do not override a previously set default recipient
          if (!patchedData.default_recipient_address_id) {
            patchedData.default_recipient_address_id = '';
          }
        }
      } else {
        // No address id means new address
        if (address.defaultSender) {
          patchedData.default_sender_address_id = newAddress?.id;
        }
        if (address.defaultRecipient) {
          patchedData.default_recipient_address_id = newAddress?.id;
        }
      }
    });

    const shouldUpdatePartner = Object.keys(patchedData).length > 0;

    if (shouldUpdatePartner) {
      await updatePartnership({
        values: patchedData,
        partnershipId: partnership!.id,
      });
    }
  };

  /**
   * 1. Update the contact if needed
   * 2. Update the address(es) if needed
   * 2.5 Create any new addresses
   * 3. Update the partnership with default sender/recipient (if neeeded)
   */
  const onSubmit =
    (initialValues: IContactForm) => async (data: IContactForm) => {
      try {
        const { addresses: initialAddresses, ...initialContact } =
          initialValues;
        const { addresses, ...contactData } = data;

        // Handle The Contact
        const contactValuesChanged = await handleTheContact({
          initialContact,
          contactData,
        });

        let addressesValuesChanged = false;

        try {
          // Handle The Addresses
          const { addressesValuesChanged: addrChanges, newAddress } =
            await handleTheAddresses({
              addresses,
              initialAddresses,
              contactData,
            });

          addressesValuesChanged = addrChanges;

          // Handle Any New or Removed Default Addresses
          if (addressesValuesChanged) {
            await handleDefaultAddresses({
              addresses,
              newAddress,
            });
          }
        } catch (error) {
          throw error;
        }

        if (contactValuesChanged || addressesValuesChanged) {
          backToList();
          toastSuccess('messages:contactCreateSuccess.message');
        }
      } catch (error) {
        toastError('messages:contactCreateError.message');
      }
    };

  return {
    onSubmit,
  };
};
