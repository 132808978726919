// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

export const infoChip = (theme: any) => {
  const color = theme.palette.custom.semantics.info;

  return {
    // ------------------
    // ----- Filled -----
    // ------------------
    '&.MuiChip-colorInfo': {
      '& .MuiChip-avatarColorInfo': {
        color: color[800],
      },

      '&.MuiChip-filledInfo': {
        backgroundColor: color[50],
        color: color[800],
        '& .MuiChip-icon, & .MuiChip-deleteIcon': {
          color: color[800],
        },

        // Hover clickable
        '&.MuiChip-clickable:hover': {
          backgroundColor: color[200],
        },
        // Hover deletable
        '&.MuiChip-deletable:hover': {
          '& .MuiChip-deleteIcon': {
            backgroundColor: color[200],
          },
        },
        // Active
        '&.MuiChip-clickable:active': {
          backgroundColor: color[50],
        },
        '&.MuiChip-deletable:active': {
          '& .MuiChip-deleteIcon': {
            backgroundColor: color[300],
          },
        },
      },
      // ---- FOCUS ----
      // Focus CLICKABLE
      '&.Mui-focusVisible.MuiChip-clickable.MuiChip-filledInfo': {
        backgroundColor: color[50],
        outline: `0.0625rem solid ${color[800]}`,
      },
      // Focus DELETABLE
      '&.Mui-focusVisible.MuiChip-deletable.MuiChip-filledInfo': {
        '& .MuiChip-deleteIcon': {
          backgroundColor: color[200],
          outline: `0.0625rem solid ${color[800]}`,
        },
      },

      // ---- Disabled ----
      '&.Mui-disabled': {
        opacity: 1,
        backgroundColor: color[100],
        color: color[400],
        '& .MuiChip-icon': {
          color: color[400],
        },
        '& .MuiChip-deleteIcon': {
          color: color[400],
        },
      },

      // ------------------
      // ---- OUTLINED ----
      // ------------------
      '&.MuiChip-outlinedInfo': {
        color: color[800],
        border: `0.0625rem solid ${color[400]}`,
        '& .MuiChip-icon, & .MuiChip-deleteIcon': {
          color: color[800],
        },

        // ---- FOCUS ----
        // Focus CLICKABLE
        '&.Mui-focusVisible.MuiChip-clickable.MuiChip-colorInfo': {
          backgroundColor: color[50],
          border: `0.0625rem solid ${color[400]}`,
          outline: `0.0625rem solid ${color[400]}`,
        },
        // Focus DELETABLE
        '&.Mui-focusVisible.MuiChip-deletable.MuiChip-outlinedInfo': {
          backgroundColor: 'white',
          '& .MuiChip-deleteIcon': {
            backgroundColor: color[200],
            border: `0.0625rem solid ${color[800]}`,
            outline: `0.0625rem solid ${color[800]}`,
          },
        },
        // Hover clickable
        '&.MuiChip-clickable:hover': {
          backgroundColor: color[50],
        },

        // Hover deletable
        '&.MuiChip-deletable:hover': {
          '& .MuiChip-deleteIcon': {
            backgroundColor: color[200],
          },
        },
        // Active
        '&.MuiChip-deletable:active': {
          '& .MuiChip-deleteIcon': {
            backgroundColor: color[300],
          },
        },
        // ---- Disabled ----
        '&.Mui-disabled': {
          opacity: 1,
          backgroundColor: theme.palette.custom.neutral.grey[10],
          color: color[400],
          '& .MuiChip-icon': {
            color: color[400],
          },
          '& .MuiChip-deleteIcon': {
            color: color[400],
          },
        },
      },
    },
  };
};
