// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { Icon, IconProps } from './Icon';

export interface CreateIconOptions {
  path: React.ReactNode;
  viewBox: string;
  displayName: string;
  /** Changes the icon's default size when the size prop is not set.
   * Defaults to 1.5rem */
  defaultSize?: string;
}

export function createIcon({
  path,
  viewBox,
  displayName,
  defaultSize = '1.5rem',
}: CreateIconOptions): React.FC<IconProps> {
  const Component = ({ size = defaultSize, ...rest }: IconProps) => (
    <Icon viewBox={viewBox} size={size} {...rest}>
      {path}
    </Icon>
  );

  Component.displayName = displayName;

  return Component;
}
