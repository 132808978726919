// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { SVGAttributes } from 'react';

import styled from 'styled-components';

export interface IconProps
  extends Omit<SVGAttributes<SVGSVGElement>, 'height' | 'width'> {
  /** Sets the color of the drawn icon.
   * Inherits current text color by default. */
  color?: string;
  size?: string | undefined;
}
export const Icon = styled.svg<IconProps>(
  ({ size = '1.5rem', color }: any) => ({
    height: size,
    width: size,
    color: color,
  })
);
