// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { Grid2 as Grid, Stack, Typography } from 'materialui-next';

export const InfoDetail = ({
  icon,
  label,
  value,
}: {
  icon?: React.ReactNode;
  label: string;
  value: string | number | React.ReactNode;
}) => (
  <Stack direction="row" alignItems="center" gap="0.5rem">
    <Grid container spacing="0.125rem">
      {icon}
      <Typography variant="titleXS" color="custom.neutral.grey.700">
        {label}
      </Typography>
    </Grid>
    <Typography variant="bodyTextS400" color="custom.neutral.grey.900">
      {value}
    </Typography>
  </Stack>
);
