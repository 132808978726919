// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { GenericError } from 'components/errors/GenericError';
import Error404 from 'components/views/lpAccount/Error404';
import { NotFound404Error } from 'core/errors/CustomAPIError';
import { Loading, usePartnership } from 'features/account';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useContact } from '../api/getContact';
import { Detail } from '../components/detail';
import Layout from '../components/Layout';
import { useUpdateContactView } from '../hooks/useUpdateContactView';

type Props = {
  backToList: () => void;
};

export const UpdateContact = ({ backToList }: Props) => {
  const { t } = useTranslation(['contactAddress']);
  const { contactId } = useParams() as { contactId: string };

  const { data: contact, isPending, error } = useContact({ contactId });
  const { data: partnership, isPending: isPartnerPending } = usePartnership();

  const { onSubmit } = useUpdateContactView({
    backToList,
  });

  if (error) {
    if (error instanceof NotFound404Error) {
      return <Error404 />;
    }

    return <GenericError />;
  }

  let children;

  if (isPending || isPartnerPending) {
    children = <Loading />;
  }

  if (contact && partnership) {
    children = (
      <Detail
        contact={contact}
        onSubmit={onSubmit}
        backToList={backToList}
        partnership={partnership}
      />
    );
  }

  return (
    <Layout
      onPrevious={backToList}
      path={t('contactAddress:edit.breadcrumb', {
        contactName: contact ? contact.firstName + ' ' + contact.lastName : '',
      })}
      subtitle={t('contactAddress:create.title')}
      title={
        contact ? (
          contact.firstName + ' ' + contact.lastName
        ) : (
          <span>&nbsp;</span>
        )
      }
    >
      {children}
    </Layout>
  );
};
