// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

/**
 * Custom hook to debounce a value.
 *
 * @param value - The value to debounce.
 * @param delay - The debounce delay in milliseconds.
 * @param bypassValues - Optional array of values that bypasses the debounce.
 * @returns The debounced value.
 */
export const useDebounce = <T>({
  value,
  delay,
  bypassValues,
}: {
  value: T;
  delay: number;
  bypassValues?: T[];
}) => {
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useEffect(() => {
    if (bypassValues?.includes(value)) {
      setDebouncedValue(value);

      return;
    }
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay, bypassValues]);

  return debouncedValue;
};
