// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useMutation } from '@tanstack/react-query';
import { CustomAPIError } from 'core/errors/CustomAPIError';
import useImperativeRequest from 'hooks/useImperativeRequest';
import { MutationConfig, queryClient } from 'lib/react-query';
import { useParams } from 'react-router-dom';

import { IMemberForm } from '../types';
import { getMemberQueryKey } from './getMember';
import { MemberEntity } from '../entities/MemberEntity';

export const updateMember =
  ({
    memberId,
    partnerId,
    makeRequest,
  }: {
    memberId?: string;
    partnerId?: string;
    makeRequest: any;
  }) =>
  async (values: IMemberForm): Promise<MemberEntity> => {
    const { data, error } = await makeRequest({
      path: `api/v1/me/partners/${partnerId}/users/${memberId}`,
      method: 'put',
      body: values,
    });

    if (error) {
      throw CustomAPIError.create(error.status, error?.response?.data);
    }

    return MemberEntity.createFromV1(data);
  };

type UseUpdateMemberOptions = {
  config?: MutationConfig<ReturnType<typeof updateMember>>;
};

export const useUpdateMember = ({ config }: UseUpdateMemberOptions = {}) => {
  const { partnerId, memberId } = useParams() as {
    partnerId: string;
    memberId: string;
  };

  const [, makeRequest] = useImperativeRequest('lpVillage');

  return useMutation({
    ...config,
    onSuccess: data => {
      queryClient.setQueryData(
        getMemberQueryKey({ partnerId, memberId }),
        data
      );
    },
    mutationFn: updateMember({
      partnerId,
      memberId,
      makeRequest,
    }),
  });
};
