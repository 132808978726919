// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { Carriers } from 'configs/carriers';
import { ShipmentLabelTypeEnum } from 'enums/ShipmentLabelTypeEnum';
import {
  ShipmentAnonymousTimelineThresholdTypeEnum,
  ShipmentTimelineEventTypeEnum,
  ShipmentTimelineThresholdTypeEnum,
} from 'enums/ShipmentTimelineEnum';
import {
  CREATE_SHIPMENT_ADDRESS_DEFAULT,
  IBaseAddress,
  IShipmentAddress,
} from 'models/address';
import { ContactAddressType } from 'models/contactAddress';
import {
  IFiguralCreatePartnerParcelResponse,
  IFiguralInsurance,
} from 'models/figuralInsurance';
import { ProductStateModel } from 'models/product';
import { Control, DeepMap } from 'react-hook-form';
import { SpaceProps } from 'styled-system';

export interface ICustomer {
  accountId: string;
  address: IShipmentAddress;
  id: string;
  notificationEmail: string;
  notification_email?: string;
  phone_number?: string;
}

export type TCarrierCodes =
  | 'Invalid'
  | 'CHRONOPOST'
  | 'DHL'
  | 'DHLExpress'
  | 'DPD'
  | 'HERMES'
  | 'LPCarrier'
  | 'FEDEX'
  | 'UPS'
  | 'TNTExpress'
  | 'MIIST'
  | 'COLISSIMO'
  | 'Other';

export interface ICarrierLabel {
  code: TCarrierCodes;
  url: string;
}

export interface IQuote {
  currency: string;
  priceInCents: number;
  carrierService: string;
  carrierCode: TCarrierCodes;
  id: string;
  error?: any;
}

export interface IQuoteModified {
  currency: string;
  priceInCents: number;
  carrierService: string;
  carrierCode: string;
  id: string;
}

export interface ICarrierServices {
  carrierCode: TCarrierCodes;
  services: string[];
}

export interface ICarrierService {
  carrierCode: TCarrierCodes;
  service: string;
}

export type TServiceLevel = 0 | 1 | 2 | 3 | 4;

export interface IServiceLevelQuote {
  currency: string;
  error?: string;
  id: string;
  priceInCents: number;
  serviceLevel: TServiceLevel;
}

export interface ILabelDetailsForm extends SpaceProps {
  errors: DeepMap<any, true>;
  touched: DeepMap<any, true>;
  register: any;
}

export type TContractState =
  | ''
  | 'DRAFT'
  | 'PURCHASED'
  | 'DEPLOYED'
  | 'ARRIVED'
  | 'ARCHIVED'
  | 'ERROR';

export interface ISenderRecipientForm extends SpaceProps {
  isSender?: boolean;
  prefix?: string;
  errors: DeepMap<any, true>;
  touched: DeepMap<any, true>;
  register: any;
  control: Control;
  defaultValues?: IBaseAddress;
  defaultContactEmail?: string;
  setValue: (
    name: string,
    value: string | undefined,
    config?: {} | undefined
  ) => void;
  trigger: (name?: string | string[] | undefined) => Promise<boolean>;
  watch: any;
}

export interface IPackageDetailsForm extends SpaceProps {
  prefix?: string;
  errors: DeepMap<any, true>;
  touched: DeepMap<any, true>;
  register: any;
}

export type DeliveryStatus =
  | 'NONE'
  | 'INVALID'
  | 'PRE_TRANSIT'
  | 'IN_TRANSIT'
  | 'OUT_FOR_DELIVERY'
  | 'AVAILABLE_FOR_PICKUP'
  | 'DELIVERED'
  | 'RETURN_TO_SENDER'
  | 'CANCELLED'
  | 'DESTROYED'
  | 'FAILURE'
  | 'ERROR';

export type ShipmentContractState =
  | 'INVALID'
  | 'DRAFT'
  | 'PURCHASED'
  | 'TRANSFERRED'
  | 'DEPLOYED'
  | 'ARRIVED'
  | 'ERROR'
  | 'CANCELLED'
  | 'ARCHIVED';

export enum ShipmentContractStateEnum {
  INVALID = 'INVALID',
  DRAFT = 'DRAFT',
  PURCHASED = 'PURCHASED',
  TRANSFERRED = 'TRANSFERRED',
  DEPLOYED = 'DEPLOYED',
  ARRIVED = 'ARRIVED',
  CANCELLED = 'CANCELLED',
  ERROR = 'ERROR',
}

export enum DeliveryStatusEnum {
  NONE = 'NONE',
  INVALID = 'INVALID',
  PRE_TRANSIT = 'PRE_TRANSIT',
  IN_TRANSIT = 'IN_TRANSIT',
  OUT_FOR_DELIVERY = 'OUT_FOR_DELIVERY',
  AVAILABLE_FOR_PICKUP = 'AVAILABLE_FOR_PICKUP',
  DELIVERED = 'DELIVERED',
  RETURN_TO_SENDER = 'RETURN_TO_SENDER',
  CANCELLED = 'CANCELLED',
  DESTROYED = 'DESTROYED',
  FAILURE = 'FAILURE',
  ERROR = 'ERROR',
}

export enum ShipmentSearchFieldEnum {
  SHIPMENT_NAME = 'shipmentName',
  LPUI = 'lpui',
  CARRIER_TRACKING_CODE = 'carrierTrackingCode',
  RECIPIENT = 'recipient',
  SENDER = 'sender',
}

export type DeliveryEvent =
  | 'CARRIER_NOTIFICATION'
  | 'DELAYED'
  | 'BOX_LOCKED'
  | 'BOX_UNLOCKED'
  | 'CONFIRMED_TRANSMISSION'
  | 'CONFIRMED_DELIVERY'
  | 'RESET';

export interface IShipment {
  receivedAt: number;
  product: IShipmentBox;
  boxWeightInGrams?: number;
  /**
   * @deprecated The property will change in V3
   */
  carrierLabel: ICarrierLabel;
  label_url: string;
  /**
   * @deprecated The property will change in V3
   */
  carrierTrackingCode?: string;
  carrier_tracking_code: string;
  carrier_code: TCarrierCodes;
  /**
   * @deprecated The property will change in V3
   */
  contractState?: ShipmentContractState;
  contract_state: ShipmentContractState;
  /**
   * @deprecated The property will change in V3
   */
  createdAt?: number;
  created_at: number;
  createdBy: string;
  deliveryStatus: DeliveryStatus;
  deliveryTime: 'ON_TIME' | 'DELAYED';
  shipment_id: string;
  id?: string;
  isArchived: boolean;
  labelWeightInGrams?: number;
  /**
   * @deprecated The property will change in V3
   */
  originalShipment: IShipment | null;
  original_shipment: IShipment | null;
  /**
   * @deprecated The property will change in V3
   */
  originalShipmentId?: string;
  original_shipment_id?: string;
  partnerId?: string;
  quote?: IQuote;
  recipient: ICustomer;
  /**
   * @deprecated The property will change in V3
   */
  reference?: string;
  shipment_name?: string;
  /**
   * @deprecated The property will change in V3
   */
  returnShipment: IShipment | null;
  return_shipment: IShipment | null;
  /**
   * @deprecated The property will change in V3
   */
  returnShipmentId?: string;
  return_shipment_id?: string;
  sender: ICustomer;
  serviceLevel: TServiceLevel;
  serviceLevelQuote: IServiceLevelQuote;
  shipmentType: 'INVALID' | 'C_TO_C' | 'PARTNER' | 'TEST';
  updatedAt: number;
  versionHash: string;
  weight_in_grams?: number;
  weightInGrams?: number;
  productOwnerId?: string;
  costPerShipment?: number;
  deployedAt: number;
  secursusParcelId?: string;
  secursus_parcel_id?: string;
  parcelRequestId: string;
  parcel_request_id: string;
  partnerParcelRequestData?: IFiguralCreatePartnerParcelResponse;
  partnerParcelData?: IFiguralCreatePartnerParcelResponse;
  product_state?: ProductStateModel;
  hasAnonymousTracking?: boolean;
  opening_code?: string;
}

export interface IShipmentGeoPath {
  shipment_id: string;
  lp_ui: string;
  coordinates: IShipmentGeoPathCoordinates[];
}

export interface IShipmentGeoPathCoordinates {
  accuracy: number;
  in_airport_area: boolean;
  latitude: number;
  longitude: number;
  place_id: string;
  timestamp: number;
}

export interface IShipmentProductState {
  battery_level: number;
  humidity_external: number;
  humidity_internal: number;
  pressure: number;
  temperature_external: number;
  temperature_internal: number;
  last_update: number;
  article_code: string;
}

export interface IShipmentAnonymous {
  carrier_code: TCarrierCodes;
  recipient: IShipmentAccount;
  sender: IShipmentAccount;
  shipment_id: string;
  shipment_name: string;
  weight_in_grams: number;
  contract_state: ShipmentContractState;
  carrier_tracking_code: string;
  created_at: number;
  product: IShipmentProduct;
}

interface IShipmentProduct {
  article_code: string;
  article_embedded_code: number;
  lp_ui: string;
}

export interface IShipmentAccount {
  account_id: string;
  address: IShipmentAccountAddress;
  address_id: string;
  notification_email: string;
  phone_number: string;
}

export interface IShipmentAccountAddress {
  city: string;
  company: string;
  country_code: string;
  display_name: string;
  first_name: string;
  house_number: number;
  last_name: string;
  postal_code: string;
  state: string;
  street: string;
  street2: string;
  position: { latitude: number; longitude: number };
}

interface IShipmentAnonymousTimeline {
  events: IShipmentAnonymousTimelineEvent[];
}

export interface IShipmentAnonymousTimelineEvent {
  box_violation: {
    position: IShipmentAnonymousTimelineEventPosition;
    value: {
      cover_open: boolean;
      key_present: boolean;
      light_sensor_on: boolean;
    };
  };
  opened_at: number;
  closed_at?: number;
  event_source: string;
  event_type: ShipmentTimelineEventTypeEnum;
  humidity_internal: {
    min: number;
    max: number;
    position: [IShipmentAnonymousTimelineEventPosition];
    threshold: 0;
    threshold_type: ShipmentAnonymousTimelineThresholdTypeEnum;
    values: IShipmentAnonymousTimelineEventValue[];
  };
  humidity_external: {
    min: number;
    max: number;
    position: [IShipmentAnonymousTimelineEventPosition];
    threshold: 0;
    threshold_type: ShipmentAnonymousTimelineThresholdTypeEnum;
    values: IShipmentAnonymousTimelineEventValue[];
  };
  pressure: {
    min: number;
    max: number;
    position: [IShipmentAnonymousTimelineEventPosition];
    threshold: number;
    threshold_type: ShipmentAnonymousTimelineThresholdTypeEnum;
    values: IShipmentAnonymousTimelineEventValue[];
  };
  shipment: {};
  shock: {
    position: IShipmentAnonymousTimelineEventPosition;
    threshold: number;
    threshold_type: ShipmentAnonymousTimelineThresholdTypeEnum;
    value: number;
  };
  temperature_internal: {
    min: number;
    max: number;
    position: [IShipmentAnonymousTimelineEventPosition];
    threshold: number;
    threshold_type: ShipmentAnonymousTimelineThresholdTypeEnum;
    values: IShipmentAnonymousTimelineEventValue[];
  };
  temperature_external: {
    min: number;
    max: number;
    position: [IShipmentAnonymousTimelineEventPosition];
    threshold: number;
    threshold_type: ShipmentAnonymousTimelineThresholdTypeEnum;
    values: IShipmentAnonymousTimelineEventValue[];
  };
  battery_level: {
    min: number;
    max: number;
    position: [IShipmentAnonymousTimelineEventPosition];
    threshold: number;
    threshold_type: ShipmentAnonymousTimelineThresholdTypeEnum;
    values: IShipmentAnonymousTimelineEventValue[];
  };
}

export interface IShipmentAnonymousTimelineEventValue {
  ts: number;
  value: number;
}

export interface IShipmentAnonymousTimelineEventPosition {
  lat: number;
  lon: number;
  time: number;
}

export interface IShipmentAnonymousData {
  geo_path: IShipmentGeoPath;
  product_state: IShipmentProductState;
  shipment: IShipmentAnonymous;
  timeline: IShipmentAnonymousTimeline;
}

export interface IShipmentGeoPath {
  shipment_id: string;
  lp_ui: string;
  coordinates: IShipmentGeoPathCoordinates[];
}

export interface IShipmentGeoPathCoordinates {
  accuracy: number;
  in_airport_area: boolean;
  latitude: number;
  longitude: number;
  place_id: string;
  timestamp: number;
}

export interface IShipmentProductState {
  battery_level: number;
  humidity: number;
  pressure: number;
  temperature: number;
  last_known_state: number;
  article_code: string;
}

export interface IShipmentAnonymous {
  carrier_code: TCarrierCodes;
  recipient: IShipmentAccount;
  sender: IShipmentAccount;
  shipment_id: string;
  shipment_name: string;
  weight_in_grams: number;
  contract_state: ShipmentContractState;
  carrier_tracking_code: string;
  created_at: number;
  partner_id: string;
}

export interface IShipmentAccount {
  account_id: string;
  address: IShipmentAccountAddress;
  address_id: string;
  notification_email: string;
  phone_number: string;
}

export interface IShipmentAccountAddress {
  city: string;
  company: string;
  country_code: string;
  display_name: string;
  first_name: string;
  house_number: number;
  last_name: string;
  postal_code: string;
  state: string;
  street: string;
  street2: string;
  position: { latitude: number; longitude: number };
}

export interface IShipmentBox {
  id: string;
  bleMacAddress: string;
  thingName?: string;
  lp_ui: string;
  article_code?: string;
  articleEmbeddedCode?: number;
  ownerId?: string;
}

export type TAlert = { timestamp: number } & (
  | {
      type: 'ALERT_HUMIDITY';
      data: {
        humidity: number;
      };
    }
  | {
      type: 'ALERT_SHOCK';
      data: {};
    }
  | {
      type: 'ALERT_PRESSURE';
      data: {
        pressure: number;
      };
    }
  | {
      type: 'ALERT_TEMPERATURE';
      data: {
        temperature: number;
      };
    }
  | {
      type: 'ALERT_BOX_VIOLATION';
      data: {
        switchOn: boolean;
        lightOn: boolean;
      };
    }
  | {
      type: 'ALERT_BATTERY_LEVEL';
      data: {
        level: number;
      };
    }
);

interface ICoordinate {
  latitude: number;
  longitude: number;
  placeId?: string;
  timestamp: number;
}

interface ILastKnownState {
  acceleration: number;
  humidityExternal: number;
  humidityInternal: number;
  pressure: number;
  temperatureExternal: number;
  temperatureInternal: number;
  timestamp: number;
  remainingTime: number;
  batteryLevel: number;
}

export interface ITrackingHistoryResponse {
  alerts: TAlert[];
  lastKnownLocation?: ICoordinate;
  lastKnownState?: ILastKnownState;
  rawPath: ICoordinate[];
  roadPath: ICoordinate[];
  shipmentId: string;
}

export interface ILabel {
  senderAddress: IBaseAddress;
  recipientAddress: IBaseAddress;
  weightInGrams?: number;
  carrierCode: string;
  carrierTrackingCode: string;
  referenceNumber: string;
  notificationEmailSender: string;
  notificationEmailRecipient: string;
  labelImage: string;
  secursusInsurance?: IFiguralInsurance;
}

export type IOcrLabel =
  | {
      senderAddress: ICreateShipmentAddressForm;
      recipientAddress: ICreateShipmentAddressForm;
      weightInGrams?: number;
      carrierTrackingCode: string;
      referenceNumber: string;
      previewUrl?: string;
      parcelRequestId?: string;
      labelType?: ShipmentLabelTypeEnum;
      senderPhoneNumber?: string;
      recipientPhoneNumber?: string;
    } & (
      | { type: 'ocr'; ocrId: string }
      | { type: 'manualCrop'; manualCrop: Blob }
    );

export interface IOcrRequest {
  ocrRequestId: string;
  originalOcrId: string;
  returnOcrId: string;
}

export interface OcrResultSuccess {
  s3PreviewUrl: string;
  s3ExtractedLabelUrl: string;
  status: 'COMPLETED';
}

export type OcrResult =
  | OcrResultSuccess
  | { status: 'PENDING' | 'IDLE' | 'ERROR' };

export interface IShipmentOcr {
  document?: File;
  returnDocument?: File;
  carrierCode?: string;
  returnCarrierCode?: string;
  pageNumber?: number;
  returnPageNumber?: number;
  isThermal?: boolean;
}

export const INITIAL_STATE_SHIPMENT = {
  senderAddress: {
    firstName: '',
    lastName: '',
    company: '',
    street: '',
    city: '',
    postalCode: '',
    country: '',
    notificationEmail: '',
  },
  recipientAddress: {
    firstName: '',
    lastName: '',
    company: '',
    street: '',
    city: '',
    postalCode: '',
    country: '',
    notificationEmail: '',
  },
  weightInGrams: '',
  notificationEmailSender: '',
  notificationEmailRecipient: '',
  referenceNumber: '',
  carrierTrackingCode: '',
  carrierCode: '',
};

export interface IShipmentPurchase {
  senderAddress: {
    firstName: string;
    lastName: string;
    company: string;
    street: string;
    city: string;
    postalCode: string;
    countryCode: string;
    notificationEmail: string;
    id: string;
  };
  recipientAddress: {
    firstName: string;
    lastName: string;
    company: string;
    street: string;
    city: string;
    postalCode: string;
    country: string;
    notificationEmail: string;
  };
  weightInGram?: string;
  weightInKilograms: string;
  notificationEmailSender: string;
  notificationEmailRecipient: string;
  referenceNumber: string;
  carrierTrackingCode: string;
  carrierCode: string;
}

export interface IStateEvent {
  id: string;
  eventName: DeliveryStatus | ShipmentContractState | DeliveryEvent;
  eventType: 'CONTRACT' | 'CONFIRMATION' | 'PAYMENT' | 'DELIVERY_STATUS';
  date: number;
  eventCause: 'SENDER' | 'RECIPIENT' | 'CARRIER' | 'BOX';
  payload: any;
}

export interface IStateEventResponse {
  count: number;
  total: number;
  shipmentId: string;
  events: IStateEvent[] | null;
}

export const INITIAL_STATE_OCR_LABEL: IOcrLabel = {
  senderAddress: CREATE_SHIPMENT_ADDRESS_DEFAULT,
  recipientAddress: CREATE_SHIPMENT_ADDRESS_DEFAULT,
  weightInGrams: undefined,
  carrierTrackingCode: '',
  referenceNumber: '',
  type: 'ocr',
  ocrId: '',
  previewUrl: '',
  labelType: ShipmentLabelTypeEnum.PDF,
  senderPhoneNumber: '',
  recipientPhoneNumber: '',
};

export interface IShipmentSearchForm {
  shipmentData: string;
  date: string;
  startTime: string;
  endTime: string;
}

export const INITIAL_STATE_SHIPMENT_SEARCH_FORM: IShipmentSearchForm = {
  shipmentData: '',
  date: '',
  startTime: '',
  endTime: '',
};

// Shipment Detail

export interface IShipmentNameForm {
  name: string;
}

export const INITIAL_STATE_SHIPMENT_NAME_FORM: IShipmentNameForm = {
  name: '',
};

// Shipment Timeline

export interface IShipmentTimelineResponse {
  count: number;
  items: IShipmentTimelineEntry[];
  total: number;
}

export interface IShipmentTimelineEntry {
  eventID: string;
  closedAt?: number;
  createdAt: number;
  eventSource: string;
  eventType: ShipmentTimelineEventTypeEnum;
  payload:
    | IShipmentTimelineEntryPayloadBoxViolation
    | IShipmentTimelineEntryMonoInfo
    | IShipmentTimelineEntryMultiInfo
    | null;
}

export interface IShipmentTimelineEntryPayloadBoxViolation {
  position: IShipmentTimelineEntryPosition | null;
  value: {
    coverOpen: boolean;
    keyPresent: boolean;
    lightSensorOn: boolean;
  };
}

export interface IShipmentTimelineEntryMonoInfo {
  position: IShipmentTimelineEntryPosition | null;
  threshold: number;
  thresholdType: ShipmentTimelineThresholdTypeEnum;
  value: number;
}

export interface IShipmentTimelineEntryMultiInfo {
  positions: IShipmentTimelineEntryPosition[];
  threshold: number;
  thresholdType: ShipmentTimelineThresholdTypeEnum;
  values: IShipmentTimelineEntryValueItem[];
}

export interface IShipmentTimelineEntryPosition {
  lat: number;
  lon: number;
  ts: number;
}

export interface IShipmentTimelineEntryValueItem {
  ts: number;
  value: number;
}

export interface ICreateShipmentAddressForm {
  type: ContactAddressType;
  firstName: string;
  lastName: string;
  company: string;
  email: string;
  street: string;
  city: string;
  postalCode: string;
  countryCode: string;
}

export type ShipmentParcelData = {
  weightInGrams?: number;
  carrierTrackingCode: string;
  referenceNumber: string;
};

export type ShipmentAddressData = {
  senderAddress: ICreateShipmentAddressForm;
  recipientAddress: ICreateShipmentAddressForm;
};

export type ShipmentAddressAndContactData = {
  senderPhoneNumber?: string;
  recipientPhoneNumber?: string;
  senderAddress: ICreateShipmentAddressForm;
  recipientAddress: ICreateShipmentAddressForm;
};

export type ShipmentData = {
  senderAddress: ICreateShipmentAddressForm;
  previewUrl?: string;
  labelType?: ShipmentLabelTypeEnum;
  carrierTrackingCode: string;
  notificationEmailSender: string;
  type: undefined;
  manualCrop: undefined;
  manualCropId: string | undefined;
  ocrId: undefined | string;
  referenceNumber: string;
  carrierCode: Carriers | undefined;
  weightInGrams?: number;
  parcelRequestId?: string;
  recipientAddress: ICreateShipmentAddressForm;
  notificationEmailRecipient: string;
  senderPhoneNumber?: string;
  recipientPhoneNumber?: string;
};

export type IShipmentToImport = {
  originalShipment: ShipmentData;
  returnShipment: ShipmentData;
};

export type CreateShipmentPartnerImportOcRequest = {
  originalShipment: LabelDataImport;
  returnShipment?: LabelDataImport;
};

export type LabelDataImport = {
  weightInGrams?: number;
  carrierCode?: Carriers;
  carrierTrackingCode: string;
  labelWeightInGrams?: number;
  manualCrop?: string;
  manualCropId?: string;
  notificationEmailRecipient: string;
  notificationEmailSender: string;
  ocrId?: string;
  type?: string;
  parcelRequestId?: string;
  recipientAddress: CreateAddressRequest;
  referenceNumber: string;
  senderAddress: CreateAddressRequest;
  senderPhoneNumber?: string;
  recipientPhoneNumber?: string;
};

export type CreateAddressRequest = {
  city: string;
  company: string;
  countryCode: string;
  displayName?: string;
  firstName: string;
  houseNumber: string;
  lastName: string;
  postalCode: string;
  state?: string;
  street: string;
  street2: string;
};
