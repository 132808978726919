// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback, useContext, useEffect, useState } from 'react';

import {
  ImageCropper,
  ImageCropperProps,
} from '@livingpackets/design-system-react';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  WarningIcon,
  Text,
  Stack,
  styled,
} from '@livingpackets/design-system-react-next';
import ImportShipmentManuallyCroppingAction from 'components/atoms/importShipment/actions/ImportShipmentManuallyCroppingAction';
import ImportShipmentPdfPageSelectionAction from 'components/atoms/importShipment/actions/ImportShipmentPdfPageSelectionAction';
import ImportShipmentPdfSpecificPageSelectedManuallyCroppingAction from 'components/atoms/importShipment/actions/ImportShipmentPdfSpecificPageSelectedManuallyCroppingAction';
import ImportShipmentWaitingForFileAction from 'components/atoms/importShipment/actions/ImportShipmentWaitingForFileAction';
import ImportShipmentConfirmInformationSection from 'components/atoms/importShipment/ImportShipmentConfirmInformationSection';
import ShipmentAddressForm from 'components/molecules/ShipmentAddressForm';
import ShipmentDropZone from 'components/molecules/ShipmentDropZone';
import ShipmentProcessing from 'components/molecules/ShipmentProcessing';
import StepShower from 'components/molecules/StepShower';
import { Carriers } from 'configs/carriers';
import { ImportShipmentStepEnum } from 'enums/ImportShipmentEnum';
import { ShipmentLabelTypeEnum } from 'enums/ShipmentLabelTypeEnum';
import { usePartnership } from 'features/account';
import {
  getPdfNumberPage,
  getPdfSpecificPageImageUrl,
} from 'helpers/pdfHelper';
import { pdfToDataUrl } from 'helpers/pdfToDataUrl';
import { SentryCaptureException } from 'helpers/tracking';
import { useOcr } from 'hooks/useOcr';
import useToastMessages from 'hooks/useToastMessages';
import { CarrierInfo } from 'models/carriers';
import { ContactAddressType } from 'models/contactAddress';
import {
  INITIAL_STATE_OCR_LABEL,
  IOcrLabel,
  OcrResultSuccess,
  ShipmentAddressData,
} from 'models/shipment';
import { useTranslation } from 'react-i18next';
import 'react-image-crop/dist/ReactCrop.css';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

import { ShipmentEditionContext } from './ImportShipmentFlow';
import { LogoWrapper, ShipmentLabelContainer } from './ShipmentLabelContainer';
import ShipmentLabelImage from './ShipmentLabelImage';
import ShippingDetailsForm from './ShippingDetailsForm';

const Wrapper = styled('div')`
  display: flex;
  height: 100%;
`;

const WrapperIcon = styled('div')`
  display: flex;
  justify-content: center;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 12px;
  margin-right: 0.5rem;
  background-color: ${({ theme }) => theme.palette.custom.primary[10]};
`;

const CropperWrapper = styled(Stack)`
  flex-direction: row;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0.45em;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background: ${({ theme }) => theme.palette.custom.neutral.black[3]};
  }

  height: 100%;
`;

interface IPdfSpecificPageSelectedLabelCropper {
  imgSrc: string;
  onComplete: ImageCropperProps['onComplete'];
}

interface PdfPageSelectionProps {
  imgSrcs: string[];
  onPdfPageSelected: (pdfPageNumber: number) => void;
}

const StyledHStack = styled(Stack)`
  flex-direction: row;
  overflow-y: auto;
  max-width: 540px;
  margin-left: auto;
  margin-right: auto;
`;

const ArrowContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.custom.primary[100]};
`;

const ArrowRight = styled(ChevronRightIcon)<{ disabled: boolean }>`
  padding: 0.25rem;
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
  color: ${({ theme, disabled }) =>
    disabled
      ? theme.palette.custom.neutral.black[20]
      : theme.palette.custom.neutral.white.pure};
`;

const ArrowLeft = styled(ChevronLeftIcon)<{ disabled: boolean }>`
  padding: 0.25rem;
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
  color: ${({ theme, disabled }) =>
    disabled
      ? theme.palette.custom.neutral.black[20]
      : theme.palette.custom.neutral.white.pure};
`;

interface LabelCropperProps {
  imgSrcs: string[];
  onComplete: ImageCropperProps['onComplete'];
}

function LabelCropper({ imgSrcs, onComplete }: LabelCropperProps) {
  const [crop, setCrop] = useState<ImageCropperProps['value']>({
    unit: '%',
    x: 10,
    y: 10,
    width: 80,
    height: 80,
  });

  const [step, setStep] = useState<number>(1);
  const totalStep = imgSrcs.length;

  const handlePrev = useCallback(() => {
    if (step === 1) return;
    setStep(prev => prev - 1);
  }, [step]);

  const handleNext = useCallback(() => {
    if (step === totalStep) return;
    setStep(prev => prev + 1);
  }, [step, totalStep]);

  return (
    <Stack height="100%">
      {totalStep === 0 && <ShipmentProcessing />}
      {totalStep !== 0 && (
        <>
          {totalStep > 1 && imgSrcs.length !== 0 && (
            <StepShower step={step} totalStep={imgSrcs.length} />
          )}
          <StyledHStack spacing={1} alignItems="center">
            {totalStep > 1 && (
              <ArrowContainer>
                <ArrowLeft
                  onClick={handlePrev}
                  size="2.25rem"
                  disabled={step === 1}
                />
              </ArrowContainer>
            )}
            <CropperWrapper>
              <ImageCropper
                imgSrc={imgSrcs[step - 1]}
                value={crop}
                onChange={setCrop}
                onComplete={onComplete}
                keepSelection={true}
              />
            </CropperWrapper>
            {totalStep > 1 && (
              <ArrowContainer>
                <ArrowRight
                  onClick={handleNext}
                  size="2.25rem"
                  disabled={step === totalStep}
                />
              </ArrowContainer>
            )}
          </StyledHStack>
        </>
      )}
    </Stack>
  );
}

function PdfSpecificPageSelectedLabelCropper({
  imgSrc,
  onComplete,
}: IPdfSpecificPageSelectedLabelCropper) {
  const [crop, setCrop] = useState<ImageCropperProps['value']>({
    unit: '%',
    x: 10,
    y: 10,
    width: 80,
    height: 80,
  });

  return (
    <Stack height="100%">
      {imgSrc.length !== 0 && (
        <StyledHStack spacing={1} alignItems="center">
          <CropperWrapper>
            <ImageCropper
              imgSrc={imgSrc}
              value={crop}
              onChange={setCrop}
              onComplete={onComplete}
              keepSelection={true}
            />
          </CropperWrapper>
        </StyledHStack>
      )}
    </Stack>
  );
}

function ShipmentPdfPageSelection({
  imgSrcs,
  onPdfPageSelected,
}: PdfPageSelectionProps) {
  const [step, setStep] = useState<number>(1);
  const totalStep = imgSrcs.length;

  useEffect(() => {
    onPdfPageSelected(step);
  }, [step, onPdfPageSelected]);

  const handlePrev = useCallback(() => {
    if (step === 1) return;
    setStep(prev => prev - 1);
  }, [step]);

  const handleNext = useCallback(() => {
    if (step === totalStep) return;
    setStep(prev => prev + 1);
  }, [step, totalStep]);

  return (
    <Stack height="100%">
      {imgSrcs.length === 0 && <ShipmentProcessing />}
      {imgSrcs.length !== 0 && (
        <>
          <StyledHStack
            spacing={1}
            alignItems="center"
            direction="row"
            gap="2.5rem"
          >
            {totalStep > 1 && (
              <ArrowContainer>
                <ArrowLeft
                  onClick={handlePrev}
                  size="2rem"
                  disabled={step === 1}
                />
              </ArrowContainer>
            )}
            <CropperWrapper>
              <img src={imgSrcs[step - 1]} alt={`Step :  ${step - 1}`} />
            </CropperWrapper>
            {totalStep > 1 && (
              <ArrowContainer>
                <ArrowRight
                  onClick={handleNext}
                  size="2rem"
                  disabled={step === totalStep}
                />
              </ArrowContainer>
            )}
          </StyledHStack>
          {totalStep > 1 && imgSrcs.length !== 0 && (
            <StepShower step={step} totalStep={imgSrcs.length} />
          )}
        </>
      )}
    </Stack>
  );
}

export enum LabelCropStateNameEnum {
  waitingForFile = 'waitingForFile',
  autoCropping = 'autoCropping',
  showingAutoCropResult = 'showingAutoCropResult',
  autoCropError = 'autoCropError',
  manuallyCropping = 'manuallyCropping',
  showingManualCropResult = 'showingManualCropResult',
  pdfPageSelection = 'pdfPageSelection',
  pdfSpecificPageSelectedManuallyCropping = 'pdfSpecificPageSelectedManuallyCropping',
  shipmentCreationFormDisplay = 'shipmentCreationFormDisplay',
  processingFile = 'processingFile',
}

export type LabelCropState =
  | { name: LabelCropStateNameEnum.waitingForFile }
  | { name: LabelCropStateNameEnum.autoCropping }
  | { name: LabelCropStateNameEnum.showingAutoCropResult; previewUrl: string }
  | { name: LabelCropStateNameEnum.autoCropError }
  | { name: LabelCropStateNameEnum.manuallyCropping; previewUrl?: string }
  | { name: LabelCropStateNameEnum.showingManualCropResult; previewUrl: string }
  | { name: LabelCropStateNameEnum.pdfPageSelection; selectedFile: File }
  | { name: LabelCropStateNameEnum.processingFile }
  | {
      name: LabelCropStateNameEnum.pdfSpecificPageSelectedManuallyCropping;
      previewUrl?: string;
    }
  | {
      name: LabelCropStateNameEnum.shipmentCreationFormDisplay;
      previewUrl: string;
    };

interface LabelSelectorProps {
  state: LabelCropState;
  onFileSelected: (file: File, isThermal: boolean) => void;
  selectedFile: File | undefined;
  onManualCropStarted: () => void;
  onManualCropComplete: LabelCropperProps['onComplete'];
  onPdfPageSelected: (pdfPageNumber: number) => void;
  pdfSpecificPageSelectedPreviewUrl: string;
  label: IOcrLabel;
}

function LabelSelector({
  state,
  onFileSelected,
  onManualCropStarted,
  onManualCropComplete,
  selectedFile,
  onPdfPageSelected,
  pdfSpecificPageSelectedPreviewUrl,
  label,
}: LabelSelectorProps) {
  const { t } = useTranslation('shipments');
  const [previewUrls, setPreviewUrls] = useState<string[]>([]);

  useEffect(() => {
    if (
      ![
        LabelCropStateNameEnum.manuallyCropping,
        LabelCropStateNameEnum.pdfPageSelection,
      ].includes(state.name) ||
      selectedFile === undefined
    )
      return;
    const reader = new FileReader();
    reader.readAsBinaryString(selectedFile);
    reader.onloadend = () => {
      pdfToDataUrl(reader.result as string).then(setPreviewUrls);
    };
  }, [selectedFile, state.name]);

  switch (state.name) {
    case LabelCropStateNameEnum.waitingForFile:
      return (
        <ShipmentDropZone
          onFileDrop={onFileSelected}
          labelTypeSelectedByDefault={label.labelType}
        />
      );
    case LabelCropStateNameEnum.autoCropping:
      return <ShipmentProcessing />;
    case LabelCropStateNameEnum.processingFile:
      return <ShipmentProcessing />;
    case LabelCropStateNameEnum.showingAutoCropResult:
      return (
        <ShipmentLabelImage
          labelUrl={state.previewUrl}
          onManualCropStarted={onManualCropStarted}
          enableManualCrop={false}
        />
      );
    case LabelCropStateNameEnum.autoCropError:
      return (
        <Stack
          direction="row"
          marginLeft="1rem"
          alignItems="center"
          height="100%"
        >
          <WrapperIcon>
            <WarningIcon />
          </WrapperIcon>
          <Text variant="titleS">{t('ocrErrorCheck')}</Text>
        </Stack>
      );
    case LabelCropStateNameEnum.manuallyCropping: {
      return (
        <LabelCropper imgSrcs={previewUrls} onComplete={onManualCropComplete} />
      );
    }
    case LabelCropStateNameEnum.showingManualCropResult:
      return (
        <ShipmentLabelImage
          enableManualCrop
          labelUrl={state.previewUrl}
          onManualCropStarted={onManualCropStarted}
        />
      );
    case LabelCropStateNameEnum.pdfPageSelection:
      return (
        <ShipmentPdfPageSelection
          imgSrcs={previewUrls}
          onPdfPageSelected={onPdfPageSelected}
        />
      );
    case LabelCropStateNameEnum.pdfSpecificPageSelectedManuallyCropping:
      return (
        <PdfSpecificPageSelectedLabelCropper
          imgSrc={pdfSpecificPageSelectedPreviewUrl}
          onComplete={onManualCropComplete}
        />
      );
    case LabelCropStateNameEnum.shipmentCreationFormDisplay:
      return (
        <ShipmentLabelImage
          labelUrl={state.previewUrl}
          enableManualCrop={false}
          onManualCropStarted={onManualCropStarted}
        />
      );
  }
}

const ImportShipmentConfirmInformationSectionListContainer = styled(Stack)`
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
`;

interface ShipmentLabelFormProps {
  isReturnShipment?: boolean;
  label: IOcrLabel;
  onLabelChange: (ocrLabel: IOcrLabel) => void;
  callbackOnLabelChange?: () => void;
  carrierInfo: CarrierInfo;
  onCarrierChange: () => void;
  onSubmit: (label: IOcrLabel) => void | Promise<void>;
  onLabelReUpload?: () => void;
  currentImportShipmentStep: ImportShipmentStepEnum;
  handleSkipReturnLabel?: () => void;
  onCompleteLabelForm: () => void;
  onLabelSelected?: () => void;
}

const ShipmentLabelForm = ({
  isReturnShipment = false,
  carrierInfo,
  label,
  onLabelChange,
  onCarrierChange,
  onSubmit,
  onLabelReUpload,
  currentImportShipmentStep,
  handleSkipReturnLabel,
  onCompleteLabelForm,
  onLabelSelected,
}: ShipmentLabelFormProps) => {
  const { t } = useTranslation(['shipments', 'general']);

  const { data: partnership } = usePartnership();
  const hasBothDefaultAddress =
    !!partnership?.default_sender_address_id &&
    !!partnership?.default_recipient_address_id;

  const { isEditingCreationShipment } = useContext(ShipmentEditionContext);
  const isDefaultOpen = hasBothDefaultAddress && !isEditingCreationShipment;

  const { smsEnabled, carriers } = useMyPartnersStore(activePartnerSelector);
  const { error: toastError, info } = useToastMessages();

  const [selectedFile, setSelectedFile] = useState<File>();
  const [selectedFileIsThermal, setSelectedFileIsThermal] = useState<boolean>();
  const [selectedFileDataUrl, setSelectedFileDataUrl] = useState<string>();
  const [selectedPdfPageNumber, setSelectedPdfPageNumber] = useState<number>(1);
  const [labelCropState, setLabelCropState] = useState<LabelCropState>(
    !label.previewUrl
      ? {
          name: LabelCropStateNameEnum.waitingForFile,
        }
      : {
          name: LabelCropStateNameEnum.shipmentCreationFormDisplay,
          previewUrl: label.previewUrl,
        }
  );
  const [
    pdfSpecificPageSelectedPreviewUrl,
    setPdfSpecificPageSelectedPreviewUrl,
  ] = useState<string>('');

  const [isLabelPdfMultiPage, setIsLabelPdfMultiPage] =
    useState<boolean>(false);

  const [isLabelBlockOpened, setIsLabelBlockOpened] = useState<boolean>(true);
  const [isShippingAddressesBlockOpened, setIsShippingAddressesBlockOpened] =
    useState<boolean>(isDefaultOpen);

  useEffect(() => {
    setIsShippingAddressesBlockOpened(isDefaultOpen);
  }, [isDefaultOpen]);

  const [isParcelDetailsBlockConfirmed, setIsParcelDetailsBlockConfirmed] =
    useState<boolean>(false);

  const currentLabel = label;

  const { start: startOcr, reset: resetOcrState } = useOcr({
    onComplete: (label: IOcrLabel, ocrResult: OcrResultSuccess) => {
      label.senderAddress.type = label.senderAddress.company
        ? ContactAddressType.professional
        : ContactAddressType.personal;

      label.recipientAddress.type = label.recipientAddress.company
        ? ContactAddressType.professional
        : ContactAddressType.personal;

      label.previewUrl = ocrResult.s3PreviewUrl;

      if (label.weightInGrams === 0) {
        label.weightInGrams = undefined;
      }

      onLabelChange({ ...currentLabel, ...label });

      setLabelCropState({
        name: LabelCropStateNameEnum.showingManualCropResult,
        previewUrl: ocrResult.s3PreviewUrl,
      });
    },
    onStart: ocr => {
      setLabelCropState({ name: LabelCropStateNameEnum.autoCropping });
      onLabelChange({ ...currentLabel, ...{ ocrId: ocr.originalOcrId } });
    },
    onError: () => {
      toastError('messages:autoCropFail.message', { duration: 7000 });

      if (isLabelPdfMultiPage) {
        if (pdfSpecificPageSelectedPreviewUrl) {
          setLabelCropState({
            name: LabelCropStateNameEnum.showingManualCropResult,
            previewUrl: pdfSpecificPageSelectedPreviewUrl,
          });
          label.previewUrl = pdfSpecificPageSelectedPreviewUrl;
        } else if (selectedFile) {
          setLabelCropState({
            name: LabelCropStateNameEnum.pdfSpecificPageSelectedManuallyCropping,
            selectedFile: selectedFile,
          } as LabelCropState);
        }
      } else {
        if (selectedFile && selectedFileDataUrl) {
          setLabelCropState({
            name: LabelCropStateNameEnum.showingManualCropResult,
            previewUrl: selectedFileDataUrl,
          });

          label.previewUrl = selectedFileDataUrl;
        } else {
          handleRedirectToCroppingPage();
        }
      }
    },
  });

  const handleLabelReUpload = useCallback(() => {
    onLabelChange({ ...INITIAL_STATE_OCR_LABEL });
    setLabelCropState({ name: LabelCropStateNameEnum.waitingForFile });
    resetOcrState();
    onLabelReUpload && onLabelReUpload();
  }, [onLabelReUpload, onLabelChange, resetOcrState]);

  const handleFileSelected: (file: File, isThermal: boolean) => void =
    useCallback(
      async (file: File, isThermal: boolean) => {
        setLabelCropState({ name: LabelCropStateNameEnum.processingFile });

        label.labelType = isThermal
          ? ShipmentLabelTypeEnum.THERMAL
          : ShipmentLabelTypeEnum.PDF;

        let pdfNumberPage = 1;

        try {
          pdfNumberPage = (await getPdfNumberPage(
            await file.text()
          )) as unknown as number;
        } catch (reason) {
          SentryCaptureException(reason, null);
        }

        setIsLabelPdfMultiPage(pdfNumberPage !== 1);

        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onloadend = () => {
          getPdfSpecificPageImageUrl(reader.result as string, 1).then(
            setSelectedFileDataUrl
          );
        };

        if (carrierInfo.code !== Carriers.other) {
          if (pdfNumberPage === 1) {
            startOcr(carrierInfo.code, file, isThermal);
          } else {
            setLabelCropState({
              name: LabelCropStateNameEnum.pdfPageSelection,
              selectedFile: selectedFile as File,
            });
          }
        }

        setSelectedFile(file);
        setSelectedFileIsThermal(isThermal);

        if (carrierInfo.code === Carriers.other) {
          info(
            {
              title: t('shipments:manualCrop.toast.title'),
              msg: t('shipments:manualCrop.toast.body'),
            },
            { duration: 6000 }
          );
          setLabelCropState({
            name: LabelCropStateNameEnum.manuallyCropping,
            selectedFile: selectedFile as File,
          } as LabelCropState);
        }
      },
      [label, carrierInfo.code, startOcr, selectedFile, info, t]
    );

  const handlePdfPageSelected = useCallback(async () => {
    if (selectedFile) {
      const reader = new FileReader();
      reader.readAsBinaryString(selectedFile);
      reader.onloadend = () => {
        getPdfSpecificPageImageUrl(
          reader.result as string,
          selectedPdfPageNumber
        ).then(setPdfSpecificPageSelectedPreviewUrl);
      };

      if (carrierInfo.code === Carriers.other) {
        info(
          {
            title: t('shipments:manualCrop.toast.title'),
            msg: t('shipments:manualCrop.toast.body'),
          },
          { duration: 6000 }
        );

        setLabelCropState({
          name: LabelCropStateNameEnum.pdfSpecificPageSelectedManuallyCropping,
          previewUrl: '',
        });

        label.previewUrl = '';
      } else {
        startOcr(
          carrierInfo.code,
          selectedFile,
          selectedFileIsThermal,
          selectedPdfPageNumber
        );
      }
    }
  }, [
    selectedFile,
    carrierInfo.code,
    selectedPdfPageNumber,
    info,
    t,
    label,
    startOcr,
    selectedFileIsThermal,
  ]);

  const handleChangePage = useCallback(() => {
    setLabelCropState({
      name: LabelCropStateNameEnum.pdfPageSelection,
      selectedFile: selectedFile as File,
    });

    if (carrierInfo.code !== Carriers.other) {
      resetOcrState();
    }
  }, [selectedFile, carrierInfo, resetOcrState]);

  const handlePdfPageSelectedCropped = useCallback(
    (previewUrl: any) => {
      resetShipmentFormBlockDisplay();

      setLabelCropState({
        name: LabelCropStateNameEnum.shipmentCreationFormDisplay,
        previewUrl: previewUrl,
      });

      label.previewUrl = previewUrl;
    },
    [label]
  );

  const handleRedirectToCroppingPage = useCallback(() => {
    if (isLabelPdfMultiPage) {
      setLabelCropState({
        name: LabelCropStateNameEnum.pdfSpecificPageSelectedManuallyCropping,
        selectedFile: selectedFile as File,
      } as LabelCropState);
    } else {
      setLabelCropState({
        name: LabelCropStateNameEnum.manuallyCropping,
        selectedFile: selectedFile as File,
      } as LabelCropState);
    }
  }, [isLabelPdfMultiPage, selectedFile]);

  const displayShipmentForm = () =>
    labelCropState.name ===
      LabelCropStateNameEnum.shipmentCreationFormDisplay ||
    labelCropState.name === LabelCropStateNameEnum.showingAutoCropResult;

  const displayConfirmInformationStep = () =>
    displayShipmentForm() ||
    labelCropState.name === LabelCropStateNameEnum.showingManualCropResult;

  const resetShipmentFormBlockDisplay = () => {
    setIsLabelBlockOpened(true);
  };

  return (
    <Wrapper>
      <ShipmentLabelContainer
        carrierInfo={carrierInfo}
        labelCropState={labelCropState.name}
        actions={
          <>
            {labelCropState.name === LabelCropStateNameEnum.waitingForFile && (
              <ImportShipmentWaitingForFileAction
                onCarrierChange={onCarrierChange}
                handleSkipReturnLabel={handleSkipReturnLabel}
                currentImportShipmentStep={currentImportShipmentStep}
                showChangeCarrier={carriers?.length > 1}
              />
            )}
            {labelCropState.name ===
              LabelCropStateNameEnum.pdfPageSelection && (
              <ImportShipmentPdfPageSelectionAction
                onCarrierChange={onCarrierChange}
                handlePdfPageSelected={handlePdfPageSelected}
                showChangeCarrier={carriers?.length > 1}
              />
            )}
            {labelCropState.name ===
              LabelCropStateNameEnum.pdfSpecificPageSelectedManuallyCropping && (
              <ImportShipmentPdfSpecificPageSelectedManuallyCroppingAction
                isLabelPdfMultiPage={isLabelPdfMultiPage}
                handleChangePage={handleChangePage}
                handlePdfPageSelectedCropped={() => {
                  if (labelCropState.previewUrl) {
                    handlePdfPageSelectedCropped(labelCropState.previewUrl);

                    if (onLabelSelected) {
                      onLabelSelected();
                    }
                  }
                }}
                isButtonActionDisabled={labelCropState.previewUrl === undefined}
              />
            )}
            {labelCropState.name ===
              LabelCropStateNameEnum.manuallyCropping && (
              <ImportShipmentManuallyCroppingAction
                handlePdfPageSelectedCropped={() => {
                  if (labelCropState.previewUrl) {
                    handlePdfPageSelectedCropped(labelCropState.previewUrl);

                    if (onLabelSelected) {
                      onLabelSelected();
                    }
                  }
                }}
                isButtonActionDisabled={labelCropState.previewUrl === undefined}
              />
            )}
          </>
        }
        handleLabelReUpload={handleLabelReUpload}
      >
        {displayConfirmInformationStep() && (
          <ImportShipmentConfirmInformationSectionListContainer>
            {/* 
              Label section
             */}
            <ImportShipmentConfirmInformationSection
              dataTestId="import-shipment-label"
              title={t('shipments:importLabel.confirmInformation.label.title')}
              subTitle={t(
                'shipments:importLabel.confirmInformation.label.subTitle'
              )}
              prefix={<LogoWrapper src={carrierInfo.logo} />}
              isOpen={isLabelBlockOpened}
              onClick={() => setIsLabelBlockOpened(!isLabelBlockOpened)}
            >
              <ShippingDetailsForm
                labelCropState={labelCropState}
                handleLabelReUpload={handleLabelReUpload}
                handleRedirectToCroppingPage={handleRedirectToCroppingPage}
                label={label}
                currentLabel={currentLabel}
                setIsParcelDetailsBlockConfirmed={
                  setIsParcelDetailsBlockConfirmed
                }
              />
            </ImportShipmentConfirmInformationSection>

            {/* 
              Shipping addresses section
             */}
            <ShipmentAddressForm
              isShippingAddressesBlockOpened={isShippingAddressesBlockOpened}
              setIsShippingAddressesBlockOpened={
                setIsShippingAddressesBlockOpened
              }
              isParcelDetailsBlockConfirmed={isParcelDetailsBlockConfirmed}
              isReturnShipment={isReturnShipment}
              label={label}
              onSubmit={({
                phoneNumbers,
                shipmentAddressData,
              }: {
                phoneNumbers: {
                  senderPhoneNumber: string;
                  recipientPhoneNumber: string;
                };
                shipmentAddressData: ShipmentAddressData;
              }) => {
                onSubmit({
                  ...label,
                  // Only add phone numbers if sms is enabled
                  ...(smsEnabled && phoneNumbers),
                  ...{
                    senderAddress: shipmentAddressData.senderAddress,
                    recipientAddress: shipmentAddressData.recipientAddress,
                  },
                  ...{
                    weightInGrams: currentLabel.weightInGrams,
                    carrierTrackingCode: currentLabel.carrierTrackingCode,
                    referenceNumber: currentLabel.referenceNumber,
                  },
                });
                onCompleteLabelForm();
              }}
            />
          </ImportShipmentConfirmInformationSectionListContainer>
        )}

        {!displayConfirmInformationStep() && (
          <LabelSelector
            label={label}
            state={labelCropState}
            onFileSelected={handleFileSelected}
            selectedFile={selectedFile}
            onManualCropStarted={() => {
              if (isLabelPdfMultiPage) {
                setLabelCropState({
                  name: LabelCropStateNameEnum.pdfSpecificPageSelectedManuallyCropping,
                  selectedFile: selectedFile as File,
                } as LabelCropState);
              } else {
                setLabelCropState({
                  name: LabelCropStateNameEnum.manuallyCropping,
                  selectedFile: selectedFile as File,
                } as LabelCropState);
              }
            }}
            onManualCropComplete={async crop => {
              const manualCrop = await crop.toBlob();
              const dataUrl = crop.toDataURL();

              if (
                labelCropState.name ===
                LabelCropStateNameEnum.pdfSpecificPageSelectedManuallyCropping
              ) {
                setLabelCropState({
                  name: LabelCropStateNameEnum.pdfSpecificPageSelectedManuallyCropping,
                  previewUrl: dataUrl,
                });
              }

              if (
                labelCropState.name === LabelCropStateNameEnum.manuallyCropping
              ) {
                setLabelCropState({
                  name: LabelCropStateNameEnum.manuallyCropping,
                  previewUrl: dataUrl,
                });
              }

              currentLabel.previewUrl = dataUrl;

              onLabelChange({
                ...currentLabel,
                type: 'manualCrop',
                manualCrop,
              });
            }}
            onPdfPageSelected={(pdfPageNumber: number) => {
              setSelectedPdfPageNumber(pdfPageNumber);
            }}
            pdfSpecificPageSelectedPreviewUrl={
              pdfSpecificPageSelectedPreviewUrl
            }
          />
        )}
      </ShipmentLabelContainer>
    </Wrapper>
  );
};

export default ShipmentLabelForm;
