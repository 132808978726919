// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { Dispatch, SetStateAction } from 'react';

import {
  DeleteIcon,
  Box,
  ButtonV2 as Button,
  ModalV2 as Modal,
  Stack,
  styled,
  Text,
} from '@livingpackets/design-system-react-next';
import NameCard from 'components/atoms/NameCard';
import { useTranslation } from 'react-i18next';

import { MemberEntity } from '../../entities/MemberEntity';

const PartnerMemberWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0.75rem;
  background-color: ${({ theme }) => theme.colors.red[10]};
  border-radius: 0.75rem;
  align-items: center;
`;

const PartnerMemberEntry = ({
  partnerMember,
}: {
  partnerMember: MemberEntity;
}) => (
  <PartnerMemberWrapper>
    <Stack direction="row" alignItems="center" gap="1rem">
      <Box display="flex" alignItems="center">
        <NameCard
          firstName={partnerMember.firstName}
          lastName={partnerMember.lastName}
          email={partnerMember.email}
        />
        <Text variant="titleXS" marginLeft="0.75rem">
          {`${partnerMember.firstName} ${partnerMember.lastName}`}
        </Text>
      </Box>
      <Box display="block" marginRight="auto" marginLeft="auto !important">
        <Text variant="titleXS">{partnerMember.email}</Text>
      </Box>
      <Box>
        <Text variant="titleXS">{partnerMember.role.name}</Text>
      </Box>
    </Stack>
  </PartnerMemberWrapper>
);

export const DeleteMemberModal = ({
  open,
  setOpen,
  onSubmit,
  entry,
  loading,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<MemberEntity | null>>;
  onSubmit: any;
  entry: MemberEntity;
  loading: boolean;
}) => {
  const { t } = useTranslation('userManagement');

  const closeModal = () => setOpen(null);

  return (
    <Modal open={open} setOpen={closeModal} maxWidth="35rem">
      <Text variant="titleM" mb="2.5rem">
        {t('modal.deletePartnerMember.title')}
      </Text>
      {entry && <PartnerMemberEntry partnerMember={entry} />}
      <Text variant="bodyTextS" mt="1.5625rem">
        {t('modal.deletePartnerMember.warning')}
      </Text>
      <Box display="flex" justifyContent="space-between" mt="2rem">
        <Button
          variant="secondary"
          onClick={closeModal}
          data-testid="dismiss-delete-partner-member-button"
        >
          {t('modal.deletePartnerMember.button.dismiss')}
        </Button>
        <Button
          color="critical"
          icon={DeleteIcon}
          onClick={onSubmit}
          disabled={loading}
          data-testid="delete-partner-member-button"
        >
          {t('modal.deletePartnerMember.button.submit')}
        </Button>
      </Box>
    </Modal>
  );
};
