// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback, useEffect, useState } from 'react';

import {
  SearchIcon,
  Text,
  Stack,
  styled,
  NewTextField as TextField,
} from '@livingpackets/design-system-react-next';
import useContactAddress from 'hooks/useContactAddress';
import useFormTemplate from 'hooks/useFormTemplate';
import { debounce } from 'lodash';
import {
  IContact,
  IContactSearchForm,
  INITIAL_STATE_CONTACT_SEARCH_FORM,
} from 'models/contactAddress';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSpring, animated } from 'react-spring';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

const VStackSearchContactNameForm = styled(Stack)`
  width: 100%;
  z-index: 1;

  @media (max-width: ${({ theme }) => theme.mediaBreakpoints.md}) {
    width: 100%;
  }
`;

const ContactSearchListName = styled(Text)`
  background-color: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  padding: 10px;
  transition: 500ms;
  &:hover {
    background-color: ${({ theme }) => theme.palette.custom.primary[10]};
    cursor: pointer;
  }
  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

const InputContainer = styled('div')`
  position: relative;
`;

const InputIconContainer = styled('div')`
  position: absolute;
  right: 12px;
  top: 12px;
`;

const ContactSearchListContainer = styled('div')`
  background-color: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  margin-top: -20px;
  padding-top: 20px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transition: 500ms;
`;

interface ISearchContactNameType {
  onContactSelect: (address: IContact) => void;
  onDismiss: () => void;
  isFormDisplayed?: boolean;
}

const SearchContactNameForm = ({
  onContactSelect,
  onDismiss,
  isFormDisplayed,
}: ISearchContactNameType) => {
  const { t } = useTranslation('contactAddress');
  const activePartner = useMyPartnersStore(activePartnerSelector);

  const [contactListSearchResult, setContactListSearchResult] = useState<
    IContact[]
  >([]);

  const [
    isContactListSearchResultDisplayed,
    setIsContactListSearchResultDisplayed,
  ] = useState<boolean>(false);

  const { getValues, reset, setValue, control } =
    useFormTemplate<IContactSearchForm>({
      defaultValues: INITIAL_STATE_CONTACT_SEARCH_FORM,
    });

  const { searchContact } = useContactAddress(activePartner.id);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const formValues = getValues();
    const contactName = formValues.contactName;

    if (contactName) {
      debouncedSearch(contactName);
    } else {
      setContactListSearchResult([]);
    }
  };

  const debouncedSearch = React.useRef(
    debounce(async (contactName: string) => {
      searchContact(contactName).then(({ success, contactAddressList }) => {
        setContactListSearchResult(
          contactAddressList ? contactAddressList : []
        );

        setIsContactListSearchResultDisplayed(true);
      });
    }, 1300)
  ).current;

  const handleContactSearchSelected = useCallback(
    (contact: IContact) => {
      setValue('contactName', contact.firstName + ' ' + contact.lastName);
      setIsContactListSearchResultDisplayed(false);

      onContactSelect(contact);
    },
    [setValue, onContactSelect]
  );

  const handleDismiss = useCallback(() => {
    reset();
    onDismiss();
    setContactListSearchResult([]);
  }, [reset, onDismiss]);

  useEffect(() => {
    if (!isFormDisplayed) {
      handleDismiss();
    }
  }, [isFormDisplayed, handleDismiss]);

  const props = useSpring({
    to: { height: '0', opacity: 0 },
    from: { height: `${contactListSearchResult.length * 34}px`, opacity: 1 },
    reverse: isContactListSearchResultDisplayed,
  });

  return (
    <VStackSearchContactNameForm>
      <InputContainer>
        <Controller
          name="contactName"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              id="box-search-input"
              label={t('modal.addAddressToContact.form.placeholder')}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              fullWidth
              {...field}
              onChange={e => {
                field.onChange(e);
                handleInputChange(e);
              }}
            />
          )}
        />
        <InputIconContainer>
          <SearchIcon />
        </InputIconContainer>
      </InputContainer>
      {isContactListSearchResultDisplayed &&
        contactListSearchResult.length !== 0 && (
          <ContactSearchListContainer>
            <animated.div style={props}>
              {contactListSearchResult.map((contact: IContact) => (
                <ContactSearchListName
                  variant="bodyTextM"
                  onClick={() => handleContactSearchSelected(contact)}
                  key={contact.id}
                >
                  {contact.firstName + ' ' + contact.lastName}
                </ContactSearchListName>
              ))}
            </animated.div>
          </ContactSearchListContainer>
        )}
    </VStackSearchContactNameForm>
  );
};

export default SearchContactNameForm;
