// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { CalendarIcon } from '@livingpackets/design-system-react-next';
import { formatISO } from 'date-fns';
import {
  Box,
  Divider,
  Grid2 as Grid,
  Stack,
  Typography,
} from 'materialui-next';
import { Trans, useTranslation } from 'react-i18next';
import { FilterSelectCalendar } from 'theme/components/FilterSelect/FilterCalendarSelect';
import {
  FilterOption,
  FilterSelect,
} from 'theme/components/FilterSelect/FilterSelect';

type Props = {
  changeValue: (callback: () => void) => void;
  alertType: string[];
  setAlertType: (value: string[]) => void;
  ongoingFrom: string;
  setOngoingFrom: (value: string) => void;
  ongoingTo: string;
  setOngoingTo: (value: string) => void;
  totalAlerts: number;
};

export const Filters = ({
  changeValue,
  alertType,
  setAlertType,
  ongoingFrom,
  setOngoingFrom,
  ongoingTo,
  setOngoingTo,
  totalAlerts,
}: Props) => {
  const { t } = useTranslation('alerts');

  const options: FilterOption[] = [
    {
      label: t('alertTypes.alert_box_violation'),
      value: 'alert_box_violation',
    },
    {
      label: t('alertTypes.alert_box_code'),
      value: 'alert_box_code',
    },
  ];

  const dateSetter =
    ({
      setter,
      endOfTheDay = false,
    }: {
      setter: (value: string) => void;
      endOfTheDay?: boolean;
    }) =>
    (date: Date | '') => {
      changeValue(() =>
        setter(
          date
            ? formatISO(endOfTheDay ? date.setHours(23, 59, 59, 999) : date)
            : ''
        )
      );
    };

  return (
    <Grid container spacing="0.625rem">
      <Grid size={12} container alignItems="center" spacing="2rem">
        {/* Filters */}
        <Grid>
          <FilterSelect
            dataTestId="filter-alert-type"
            label={t('filters.type')}
            options={options}
            // Clear any unwanted value from the alertType URL value
            value={alertType.filter((type: string) =>
              // Ensure value exist inside options array
              options.some(option => {
                if (option.options) {
                  return (
                    option.value === type ||
                    option.options.some(
                      (subOption: { value: string }) => subOption.value === type
                    )
                  );
                }

                return option.value === type;
              })
            )}
            onChange={(newValue: any) =>
              changeValue(() => setAlertType(newValue))
            }
          />
        </Grid>
        <Grid container spacing="0.25rem" alignItems="center">
          <Grid>
            <Typography variant="titleS" color="custom.neutral.grey.500">
              {t('filters.datePrefixLabel')}
            </Typography>
          </Grid>
          <Grid container spacing="0.375rem" alignItems="center">
            <Grid>
              <FilterSelectCalendar
                hideLabelWhenValue
                dataTestId="filter-ongoing-from"
                label={t('filters.from')}
                startIcon={CalendarIcon}
                value={ongoingFrom ? new Date(ongoingFrom) : null}
                maxDate={ongoingTo ? new Date(ongoingTo) : undefined}
                onChange={dateSetter({ setter: setOngoingFrom })}
              />
            </Grid>
            <Grid>
              <Typography variant="titleS" color="custom.neutral.grey.500">
                {t('filters.gapFiller')}
              </Typography>
            </Grid>
            <Grid>
              <FilterSelectCalendar
                hideLabelWhenValue
                dataTestId="filter-ongoing-to"
                label={t('filters.to')}
                startIcon={CalendarIcon}
                minDate={ongoingFrom ? new Date(ongoingFrom) : undefined}
                value={ongoingTo ? new Date(ongoingTo) : null}
                onChange={dateSetter({
                  setter: setOngoingTo,
                  endOfTheDay: true,
                })}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* Total */}
      <Grid size={12}>
        <Stack direction="row" alignItems="center" gap="0.5rem">
          <Box flexGrow={1}>
            <Divider />
          </Box>
          <Typography
            variant="bodyTextM400"
            color="custom.neutral.grey.500"
            data-testid="total-alerts"
          >
            <Trans
              t={t}
              i18nKey="filters.total"
              values={{ count: totalAlerts }}
              components={[
                <Typography
                  key={0}
                  variant="titleS"
                  color="custom.neutral.grey.600"
                />,
              ]}
            />
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};
