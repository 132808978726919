// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { Dispatch, SetStateAction } from 'react';

import { Table, TableContainer } from '@livingpackets/design-system-react-next';

import { MembersRows } from './MembersRows';
import { TableHeader } from './TableHeader';
import { MemberEntity } from '../../entities/MemberEntity';
import { Pagination } from '../../routes/Members';

export const PAGE_SIZE = 6;
export const ROW_HEIGHT = 80;
export const NB_CELLS = 7;

export const MembersTable = ({
  members,
  refetchMembers,
  isPending,
  pagination,
  setPagination,
  total,
}: {
  members: MemberEntity[];
  refetchMembers: () => void;
  isPending: boolean;
  pagination: Pagination;
  setPagination: Dispatch<SetStateAction<Pagination>>;
  total: number;
}) => (
  <TableContainer>
    <Table
      data-testid="members-table"
      sx={{
        minWidth: '40.625rem',
        tableLayout: 'fixed',
        border: 'none',
        borderSpacing: '0rem 0.5rem',
        borderCollapse: 'separate',
      }}
    >
      <TableHeader
        total={total}
        isPending={isPending}
        pagination={pagination}
        setPagination={setPagination}
      />
      <MembersRows
        members={members}
        refetchMembers={refetchMembers}
        isPending={isPending}
      />
    </Table>
  </TableContainer>
);
