// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  EditIcon,
  Grid,
  Text,
  Stack,
} from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

import Card from './Card';
import { ContactEntity } from '../../entities/ContactEntity';

type Props = {
  contact: ContactEntity;
  editContact: () => void;
};

export const InformationsCard = ({ contact, editContact }: Props) => {
  const { t } = useTranslation(['contactAddress', 'forms']);

  return (
    <Grid container gap="1rem">
      <Grid item mobile={12}>
        <Stack gap=".5rem">
          <Text variant="titleL">
            {t('contactAddress:create.block.basicInformation.title')}
          </Text>
          <Text variant="titleXS" color="custom.neutral.black.50">
            {t('contactAddress:create.block.basicInformation.subTitle')}
          </Text>
        </Stack>
      </Grid>
      <Grid item mobile={12} data-testid="contactBasicInformationCard">
        <Card onClick={editContact}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            flexWrap="nowrap"
            spacing="1rem"
          >
            <Grid item>
              <Grid container direction="column" gap=".75rem">
                <Grid item>
                  <Text variant="titleM" data-testid="shipment-name">
                    {contact.firstName} {contact.lastName}
                  </Text>
                </Grid>
                <Grid item columnGap="5rem" rowGap=".75rem">
                  <Grid container>
                    <Grid item>
                      <Text variant="bodyTextS">{contact.email}</Text>
                    </Grid>
                    <Grid item>
                      <Text variant="bodyTextS">{contact.phoneNumber}</Text>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <EditIcon />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};
