// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useState } from 'react';

import {
  ChevronDownIcon,
  ChevronUpIcon,
} from '@livingpackets/design-system-react-next';
import { useTheme } from 'materialui-next';

import { CustomSelect } from './CustomSelect';
import { FilterCheckboxesChildrenElement } from './FilterCheckboxesChildrenElement';
import { FilterChipsChildrenElement } from './FilterChipsChildrenElement';
import { RenderValue } from './RenderValue';

export const FilterSelectModes = {
  checkboxes: 'checkboxes',
  chips: 'chips',
  calendar: 'calendar',
} as const;

export type AlertTypesModeKeys =
  (typeof FilterSelectModes)[keyof typeof FilterSelectModes];

export type FilterOption = {
  // Default options
  label: string;
  value: string;

  // For AlertTypesMode.checkboxes
  labelPrefix?: React.ReactNode;
  options?: FilterOption[];

  // For AlertTypesMode.chips
  color?: string;
};

type Props = {
  dataTestId?: string;
  label: string;
  startIcon?: React.ElementType;
  showEndIcon?: boolean;
  maxDisplayed?: number;
  options?: FilterOption[];
  mode?: AlertTypesModeKeys;
  value: string[];
  onChange: (value: string[]) => void;
};

export const FilterSelect = ({
  dataTestId,
  label,
  startIcon,
  showEndIcon = true,
  maxDisplayed = 2,
  options,
  mode = FilterSelectModes.checkboxes,
  value,
  onChange,
}: Props) => {
  const theme = useTheme();

  const [open, setOpen] = useState(false);

  const optionsByValue = options?.reduce(
    (acc: { [key: string]: FilterOption }, option: FilterOption) => {
      acc[option.value] = option;

      if (option.options) {
        option.options.forEach(subOption => {
          acc[subOption.value] = subOption;
        });
      }

      return acc;
    },
    {}
  );

  const remove = (newValue: string) => {
    onChange(value.filter(item => item !== newValue));
  };

  /**
   * Remove chip on click
   * @param event
   * @description
   * - If the click is on any part of the chip (container, label or the delete icon), we remove the chip
   */
  const onMouseDown = (event: any) => {
    const chip = event.target.classList.contains('MuiChip-root')
      ? event.target
      : event.target.closest('.MuiChip-root');

    const valueToRemove = chip?.getAttribute('data-selected-value');

    if (valueToRemove) {
      setOpen(false);

      remove(valueToRemove);
    }
  };

  return (
    <CustomSelect
      multiple
      variant="standard"
      data-testid={dataTestId}
      open={open}
      onMouseDown={onMouseDown}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      value={value}
      displayEmpty
      IconComponent={() => <></>}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        sx: {
          '& .MuiPopover-paper.MuiMenu-paper': {
            mt: '.375rem',
            padding: '.375rem',
            width: 'fit-content !important',
            minWidth: '11.25rem !important',
            maxWidth: '15.75rem',
            maxHeight: '17.5rem',
            boxShadow: '0rem .5rem 1.25rem 0rem rgba(0, 11, 7, 0.10)',
            '& .MuiList-root': {
              py: 0,
            },
            borderRadius: '.5rem',
          },
        },
      }}
      renderValue={selectedValues => (
        <RenderValue
          mode={mode}
          label={label}
          value={selectedValues}
          maxDisplayed={maxDisplayed}
          remove={remove}
          optionsByValue={optionsByValue}
          theme={theme}
          startIcon={startIcon}
          showEndIcon={showEndIcon}
          arrowIcon={open ? ChevronUpIcon : ChevronDownIcon}
        />
      )}
    >
      {mode === FilterSelectModes.checkboxes && (
        <FilterCheckboxesChildrenElement
          options={options}
          currentValue={value}
          onChange={onChange}
        />
      )}
      {mode === FilterSelectModes.chips && (
        <FilterChipsChildrenElement
          options={options}
          currentValue={value}
          onChange={onChange}
        />
      )}
    </CustomSelect>
  );
};
