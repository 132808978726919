// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useState } from 'react';

import {
  Skeleton,
  TableCell,
  TableRow,
} from '@livingpackets/design-system-react-next';
import { LpVillageScopes, PATHS } from 'configs';
import useHasUserScopes from 'hooks/useHasUserScopes';
import useToastMessages from 'hooks/useToastMessages';
import { useFormContext } from 'react-hook-form';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { DeleteMemberModal } from './DeleteMemberModal';
import { EmptyResult } from './EmptyResult';
import { MemberRow } from './MemberRow';
import { NB_CELLS, PAGE_SIZE, ROW_HEIGHT } from './MembersTable';
import { useDeleteMember } from '../../api/deleteMember';
import { MemberEntity } from '../../entities/MemberEntity';

const computeTableHeight = (count: number) => (ROW_HEIGHT + 8) * count - 8;

export const MembersRows = ({ members, refetchMembers, isPending }: any) => {
  const navigate = useNavigate();
  const { partnerId } = useParams() as { partnerId: string };

  const { mutateAsync: deleteMember, isPending: isDeletePending } =
    useDeleteMember();

  const { setValue, watch } = useFormContext();

  const { error: toastError, success: toastSuccess } = useToastMessages();

  const canUpdate = useHasUserScopes([LpVillageScopes.updatePartnerUser]);
  const canDelete = useHasUserScopes([LpVillageScopes.deletePartnerUser]);

  const [openDeleteModal, setOpenDeleteModal] = useState<MemberEntity | null>(
    null
  );

  const emptyRows = PAGE_SIZE - members.length;

  const onUpdate = (memberId: string) => () => {
    navigate(
      generatePath(PATHS.USER_MANAGEMENT.EDIT, {
        partnerId,
        memberId,
      })
    );
  };

  const onDelete = async () => {
    try {
      await deleteMember({ memberId: openDeleteModal!.accountId });

      toastSuccess('messages:partnerMemberDeleteSuccess.message');

      refetchMembers();
    } catch (error) {
      toastError('messages:partnerMemberDeleteError.message');
    } finally {
      setOpenDeleteModal(null);
    }
  };

  if (members.length === 0) {
    if (isPending) {
      return Array(PAGE_SIZE)
        .fill(null)
        .map((_, index) => (
          <TableRow key={index} data-testid="loading-cell">
            <TableCell
              colSpan={NB_CELLS}
              sx={{ border: 0, height: ROW_HEIGHT, padding: 0 }}
            >
              <Skeleton
                sx={{ borderRadius: '0.625rem', bgcolor: 'white' }}
                variant="rectangular"
                width="100%"
                height={80}
              />
            </TableCell>
          </TableRow>
        ));
    }

    return (
      <TableRow
        style={{
          height: computeTableHeight(emptyRows),
        }}
      >
        <TableCell
          colSpan={NB_CELLS}
          sx={{ border: 0, height: ROW_HEIGHT, padding: 0 }}
        >
          <EmptyResult
            hasFilters={watch('value') !== ''}
            clearSearch={() => setValue('value', '')}
          />
        </TableCell>
      </TableRow>
    );
  }

  return (
    <>
      {members.map((member: MemberEntity) => (
        <MemberRow
          key={member.accountId}
          member={member}
          canUpdate={canUpdate}
          canDelete={canDelete}
          onUpdate={onUpdate(member.accountId)}
          onDelete={() => setOpenDeleteModal(member)}
        />
      ))}
      {emptyRows > 0 && (
        <TableRow
          style={{
            height: computeTableHeight(emptyRows),
          }}
        >
          <TableCell
            colSpan={NB_CELLS}
            sx={{ border: 0, height: ROW_HEIGHT }}
          />
        </TableRow>
      )}
      <DeleteMemberModal
        open={!!openDeleteModal}
        setOpen={setOpenDeleteModal}
        onSubmit={onDelete}
        entry={openDeleteModal!}
        loading={isDeletePending}
      />
    </>
  );
};
