// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { ThemeProvider } from 'materialui-next';
import { Navigate, Route, Routes } from 'react-router-dom';
import { theme } from 'theme/theme';

import { Alerts } from './Alerts';

// /partner/:partnerId/alerts

/**
 * Base URL: /partner/:partnerId/alerts/*
 */
export const ALERTS_ROOT = '/alerts';

export const AlertsRoutes = () => (
  <ThemeProvider theme={theme}>
    <Routes>
      <Route path="" index element={<Alerts />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  </ThemeProvider>
);
