// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { GenericError } from 'components/errors/GenericError';
import { Grid2 as Grid, Typography } from 'materialui-next';
import { useTranslation } from 'react-i18next';

import { AlertsList } from '../components/AlertsList';
import { Filters } from '../components/Filters';
import { useAlertsView } from '../hooks/useAlertsView';

export const Alerts = () => {
  const { t } = useTranslation('alerts');

  const {
    queryParams: {
      alertType,
      setAlertType,
      ongoingFrom,
      setOngoingFrom,
      ongoingTo,
      setOngoingTo,
    },
    changeValue,
    query,
    isPending,
    alerts,
    totalAlerts,
    lastAlertElementRef,
  } = useAlertsView();

  if (query.error) {
    return <GenericError />;
  }

  return (
    <Grid container spacing="2rem" mb="6.25rem">
      <Grid size={12}>
        <Grid container>
          <Grid size={12}>
            <Typography
              component="h1"
              variant="titleXXXL"
              color="custom.neutral.grey.900"
              marginTop="0.3125rem"
            >
              {t('pageListTitle')}
            </Typography>
          </Grid>
          <Grid size={12}>
            <Typography
              component="p"
              variant="titleM"
              color="custom.neutral.grey.900"
              marginTop="0.3125rem"
            >
              {t('pageListSubtitle')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid size={12}>
        <Grid
          container
          pb="1.5rem"
          borderRadius="0.75rem"
          bgcolor="custom.neutral.grey.0"
        >
          <Grid
            size={12}
            p="1.5rem"
            pb="0"
            bgcolor="custom.neutral.grey.0"
            position="sticky"
            top="-1rem"
            zIndex={2}
            sx={{
              borderTopLeftRadius: '0.75rem',
              borderTopRightRadius: '0.75rem',
            }}
          >
            <Filters
              alertType={alertType}
              setAlertType={setAlertType}
              ongoingFrom={ongoingFrom}
              setOngoingFrom={setOngoingFrom}
              ongoingTo={ongoingTo}
              setOngoingTo={setOngoingTo}
              changeValue={changeValue}
              totalAlerts={totalAlerts}
            />
          </Grid>
          <Grid size={12}>
            <AlertsList
              alerts={alerts}
              isPending={isPending}
              ref={lastAlertElementRef}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
