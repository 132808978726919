// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useState } from 'react';

import {
  Box,
  DeleteIcon,
  DotsIcon,
  EditIcon,
  Stack,
  styled,
  TableCell,
  TableRow,
  Text,
} from '@livingpackets/design-system-react-next';

import { Avatar } from './Avatar';
import { ROW_HEIGHT } from './MembersTable';
import { MemberEntity } from '../../entities/MemberEntity';

const CenterCell = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
}));

const IconButton = styled(Box)(() => ({
  cursor: 'pointer',
}));

export const MemberRow = ({
  member,
  canUpdate,
  canDelete,
  onUpdate,
  onDelete,
}: {
  member: MemberEntity;
  canUpdate: boolean;
  canDelete: boolean;
  onUpdate: () => void;
  onDelete: () => void;
}) => {
  const initials = `${member.firstName.charAt(0)}${member.lastName.charAt(0)}`;

  const [isHovered, setIsHovered] = useState(false);

  let renderActions;

  if (canUpdate || canDelete) {
    renderActions = isHovered ? (
      <Stack direction="row" justifyContent="center" alignItems="center">
        {canUpdate && (
          <IconButton
            data-testid={`update-row-${member.accountId}`}
            onClick={onUpdate}
          >
            <EditIcon />
          </IconButton>
        )}
        {canDelete && (
          <IconButton
            data-testid={`delete-row-${member.accountId}`}
            onClick={onDelete}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </Stack>
    ) : (
      <DotsIcon />
    );
  }

  return (
    <StyledRow
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      data-testid={`member-row-${member.accountId}`}
    >
      <TableCell>
        {initials && (
          <CenterCell>
            <Avatar initials={initials} />
          </CenterCell>
        )}
      </TableCell>
      <TableCell>
        <Box
          height="3.25rem"
          width="0.0625rem"
          bgcolor="custom.neutral.black.10"
        />
      </TableCell>
      <TableCell>
        <Text variant="titleXS">{member.lastName}</Text>
      </TableCell>
      <TableCell>
        <Text variant="titleXS">{member.firstName}</Text>
      </TableCell>
      <TableCell>
        <Text variant="titleXS">{member.email}</Text>
      </TableCell>
      <TableCell>
        <Text variant="titleXS">{member.role.name}</Text>
      </TableCell>
      <TableCell>{renderActions}</TableCell>
    </StyledRow>
  );
};

const StyledRow = styled(TableRow)(({ theme }) => ({
  background: 'white',
  height: ROW_HEIGHT,
  '&:hover': {
    background: theme.palette.custom.primary[5],
  },

  '& > td': {
    border: 0,
    padding: '0 1rem',
  },
  '& > td:first-of-type': {
    borderTopLeftRadius: '.75rem',
    borderBottomLeftRadius: '.75rem',
    paddingRight: '0rem',
  },
  '& > td:nth-of-type(2)': {
    paddingRight: '0rem',
  },
  '& > td:last-child': {
    borderTopRightRadius: '.75rem',
    borderBottomRightRadius: '.75rem',
  },
}));
