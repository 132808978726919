// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { Theme } from 'materialui-next';

export const defaultChip = (theme: Theme) => ({
  // ------------------
  // ----- Filled -----
  // ------------------
  '&.MuiChip-colorDefault': {
    '& .MuiChip-avatarColorDefault': {
      color: theme.palette.custom.neutral.grey[700],
    },

    '&.MuiChip-filledDefault': {
      backgroundColor: theme.palette.custom.neutral.grey[25],
      color: theme.palette.custom.neutral.grey[700],
      '& .MuiChip-icon, & .MuiChip-deleteIcon': {
        color: theme.palette.custom.neutral.grey[800],
      },

      // Hover clickable
      '&.MuiChip-clickable:hover': {
        backgroundColor: theme.palette.custom.neutral.grey[100], // ✅
      },
      // Hover deletable
      '&.MuiChip-deletable:hover': {
        '& .MuiChip-deleteIcon': {
          backgroundColor: theme.palette.custom.neutral.grey[50],
        },
      },
      // Active
      '&.MuiChip-clickable:active': {
        backgroundColor: theme.palette.custom.neutral.grey[25],
        boxShadow: 'none',
      },
      '&.MuiChip-deletable:active': {
        '& .MuiChip-deleteIcon': {
          backgroundColor: theme.palette.custom.neutral.grey[100],
        },
      },
    },
    // ---- FOCUS ----
    // Focus CLICKABLE
    '&.Mui-focusVisible.MuiChip-clickable.MuiChip-filledDefault': {
      outline: `0.0625rem solid ${theme.palette.custom.neutral.grey[700]}`,
    },
    // Focus DELETABLE
    '&.Mui-focusVisible.MuiChip-deletable.MuiChip-filledDefault': {
      '& .MuiChip-deleteIcon': {
        backgroundColor: theme.palette.custom.neutral.grey[50],
        outline: `0.0625rem solid ${theme.palette.custom.neutral.grey[700]}`,
      },
    },

    // ---- Disabled ----
    '&.Mui-disabled': {
      // TODO: Demander si l'opocity doit rester normal (0.38) ou je dois la mettre à 1 => Mais à 1 ça fait pas vraiment disabled
      opacity: 1,
      backgroundColor: theme.palette.custom.neutral.grey[25],
      color: theme.palette.custom.neutral.grey[400],
      '& .MuiChip-icon': {
        color: theme.palette.custom.neutral.grey[400],
      },
      '& .MuiChip-deleteIcon': {
        color: theme.palette.custom.neutral.grey[300],
      },
    },

    // ------------------
    // ---- OUTLINED ----
    // ------------------
    '&.MuiChip-outlinedDefault': {
      backgroundColor: theme.palette.custom.neutral.grey[10],
      color: theme.palette.custom.neutral.grey[700],
      border: `0.0625rem solid ${theme.palette.custom.neutral.grey[400]}`,
      '& .MuiChip-icon, & .MuiChip-deleteIcon': {
        color: theme.palette.custom.neutral.grey[800],
      },

      // ---- FOCUS ----
      // Focus CLICKABLE
      '&.Mui-focusVisible.MuiChip-clickable.MuiChip-colorDefault': {
        border: `0.0625rem solid ${theme.palette.custom.neutral.grey[700]}`,
        outline: `0.0625rem solid ${theme.palette.custom.neutral.grey[700]}`,
      },
      // Focus DELETABLE
      '&.Mui-focusVisible.MuiChip-deletable.MuiChip-outlinedDefault': {
        '& .MuiChip-deleteIcon': {
          backgroundColor: theme.palette.custom.neutral.grey[50],
          border: `0.0625rem solid ${theme.palette.custom.neutral.grey[700]}`,
          outline: `0.0625rem solid ${theme.palette.custom.neutral.grey[700]}`,
        },
      },
      // Hover clickable
      '&.MuiChip-clickable:hover': {
        backgroundColor: theme.palette.custom.neutral.grey[25],
      },

      // Hover deletable
      '&.MuiChip-deletable:hover': {
        '& .MuiChip-deleteIcon': {
          backgroundColor: theme.palette.custom.neutral.grey[50],
        },
      },
      // ---- Disabled ----
      '&.Mui-disabled': {
        opacity: 1,
        backgroundColor: theme.palette.custom.neutral.grey[10],
        color: theme.palette.custom.neutral.grey[200],
        '& .MuiChip-icon': {
          color: theme.palette.custom.neutral.grey[200],
        },
        '& .MuiChip-deleteIcon': {
          color: theme.palette.custom.neutral.grey[300],
        },
      },
    },
  },
});
