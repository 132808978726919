// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { Link, LinkProps as MuiLinkProps, Typography } from '@mui/material';
import styled from 'styled-components';

import { OpenActionIcon } from '../icons/components/OpenIcon';

type Size = 'medium' | 'small';
type Color = 'primary' | 'inherit';

interface LinkProps extends MuiLinkProps {
  color?: Color;
  size?: Size;
  disabled?: boolean;
  iconLeading?: boolean;
  iconTrailing?: boolean;
  children?: string;
  to: string;
  state?: any;
}

const StyledOpenIcon = styled(OpenActionIcon)<{
  color: Color;
  disabled: boolean;
  size: Size;
}>`
  color: ${({ color, disabled }) =>
    disabled ? '#809E98' : color === 'primary' ? '#0F7A54' : 'inherit'};
  margin-bottom: ${({ size }) => (size === 'small' ? '.0625rem' : '.1875rem')};
  width: ${({ size }) => (size === 'small' ? '.75rem' : '.875rem')};
  height: ${({ size }) => (size === 'small' ? '.75rem' : '.875rem')};
`;

const StyledLink = styled(Link)<{
  color: Color;
  size: Size;
  disabled: boolean;
}>`
  // Common styles for the link
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  text-underline-offset: 0.1875rem;
  font-size: ${({ color, size }) =>
    color === 'primary' && size === 'small'
      ? '.625rem'
      : color === 'primary' && size === 'medium'
      ? '.75rem'
      : 'inherit'};
  line-height: ${({ color, size }) =>
    color === 'primary' && size === 'small'
      ? '.875rem'
      : color === 'primary' && size === 'medium'
      ? '1.05rem'
      : 'inherit'};
  color: ${({ color, disabled }) =>
    disabled ? '#809E98' : color === 'primary' ? '#0F7A54' : 'inherit'};
  text-decoration-color: ${({ color, disabled }) =>
    disabled ? '#809E98' : color === 'primary' ? '#0F7A54' : 'inherit'};

  // Styles for underline always
  &.MuiLink-underlineAlways {
    font-weight: 400;
    text-decoration-thickness: 0.0625rem;
    &:hover {
      text-decoration-thickness: 0.125rem;
      color: ${({ color }) => (color === 'primary' ? '#0a6344' : 'inherit')};
      text-decoration-color: ${({ color }) =>
        color === 'primary' ? '#0a6344' : 'inherit'};
    }
    &:visited {
      color: #0a6344;
      text-decoration-color: #0a6344;
    }
  }

  // Styles for underline on hover
  &.MuiLink-underlineHover {
    font-weight: 500;
    &:hover {
      text-decoration-thickness: 0.0625rem;
      color: ${({ color }) => (color === 'primary' ? '#0a6344' : 'inherit')};
      text-decoration-color: ${({ color }) =>
        color === 'primary' ? '#0a6344' : 'inherit'};
    }
    &:visited {
      color: #0a6344;
      text-decoration-color: #0a6344;
    }
  }

  // Styles for no underline
  &.MuiLink-underlineNone {
    font-weight: 500;
    &:hover {
      color: ${({ color }) => (color === 'primary' ? '#0a6344' : 'inherit')};
    }
    &:visited {
      color: #0a6344;
    }
  }

  // Focus styles
  &:focus {
    outline: ${({ disabled }) =>
      disabled ? 'none' : '.0625rem solid #00bf6f'};
    text-decoration: none;
    font-weight: 500;
    color: ${({ color, disabled }) =>
      disabled ? '#809E98' : color === 'primary' ? '#0a6344' : 'inherit'};
    text-decoration-color: ${({ color, disabled }) =>
      disabled ? '#809E98' : color === 'primary' ? '#0a6344' : 'inherit'};
  }
`;

export const Hyperlink = ({
  color = 'primary',
  size = 'medium',
  disabled = false,
  iconTrailing = false,
  iconLeading,
  children,
  ...rest
}: LinkProps) => (
  <StyledLink
    {...rest}
    size={size}
    color={color}
    disabled={disabled}
    display="inline-flex"
    alignItems="center"
    gap=".125rem"
    width="fit-content"
    tabIndex={disabled ? -1 : 0}
  >
    {iconLeading && (
      <StyledOpenIcon color={color} disabled={disabled} size={size} />
    )}
    <Typography
      sx={{
        fontSize: 'inherit',
        lineHeight: 'inherit',
        color: 'currentColor',
        textDecoration: 'inherit',
        fontWeight: 'inherit',
      }}
    >
      {children}
    </Typography>
    {iconTrailing && (
      <StyledOpenIcon color={color} disabled={disabled} size={size} />
    )}
  </StyledLink>
);
