// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { useTheme } from 'materialui-next';

import { CheckboxItem } from './CheckboxItem';
import { CheckboxItemWrapper } from './CheckboxItemWrapper';
import { FilterSelectModes, FilterOption } from './FilterSelect';

export const FilterChipsChildrenElement = ({
  options,
  currentValue,
  onChange,
}: {
  options?: FilterOption[];
  currentValue: string[];
  onChange: (value: string[]) => void;
}) => {
  const theme = useTheme();

  return options?.map(option => {
    const checked = currentValue.indexOf(option.value) > -1;

    return (
      <CheckboxItemWrapper
        disableRipple
        theme={theme}
        key={option.value}
        dataTestid={`checkbox-item-${option.value}`}
        value={option.value}
        checked={checked}
        onClick={(event: any) => {
          event.stopPropagation();
          event.preventDefault();

          if (currentValue.indexOf(option.value) > -1) {
            onChange(currentValue.filter(item => item !== option.value));

            return;
          }

          onChange([...currentValue, option.value]);
        }}
      >
        <CheckboxItem
          theme={theme}
          checked={checked}
          option={option}
          mode={FilterSelectModes.chips}
        />
      </CheckboxItemWrapper>
    );
  });
};
