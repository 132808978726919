// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { SentryCaptureException } from 'helpers/tracking';
import { TCarrierCodes } from 'models/shipment';

import { AlertContactEntity } from './AlertContactEntity';

export class AlertShipmentEntity {
  static createFromAPI(shipment: any): AlertShipmentEntity {
    try {
      // Validate data to ensure it matches the AlertShipmentEntity type
      if (!shipment.id || typeof shipment.id !== 'string') {
        throw new Error('Invalid data: id must be a string');
      }

      return new AlertShipmentEntity(shipment);
    } catch (error: any) {
      SentryCaptureException(error, null);

      throw new Error(`Failed to create AlertShipmentEntity: ${error.message}`);
    }
  }

  id: string;
  name: string;
  carrierCode: TCarrierCodes;
  carrierTrackingCode: string;
  originalShipmentId: string;
  sender: AlertContactEntity;
  recipient: AlertContactEntity;

  constructor(data: any) {
    this.id = data.id;
    this.name = data.shipment_name;
    this.carrierCode = data.carrier_code;
    this.carrierTrackingCode = data.carrier_tracking_code;
    this.originalShipmentId = data.original_shipment_id;
    this.sender = AlertContactEntity.createFromAPI(data.sender);
    this.recipient = AlertContactEntity.createFromAPI(data.recipient);
  }
}
