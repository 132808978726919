// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useEffect, useState } from 'react';

import {
  Grid,
  Pagination as PaginationComponent,
  Text,
} from '@livingpackets/design-system-react-next';
import { GenericError } from 'components/errors/GenericError';
import { useDebounce } from 'hooks/helpers/useDebounce';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useGetMembers } from '../api/getMembers';
import { Filters } from '../components/members/Filters';
import { MembersTable, PAGE_SIZE } from '../components/members/MembersTable';

type FormData = { key: string; value: string };

export type Pagination = {
  'list_request.page_size': number;
  'list_request.page_offset': number;
  'list_request.order_by': string;
};

const getFilterFromValues = (values: FormData) => {
  if (values.key && values.value) {
    return `(${values.key}%>'${values.value}')`;
  }

  return '';
};

export const Members = () => {
  const { t } = useTranslation('userManagement');

  const methods = useForm<FormData>({
    defaultValues: {
      key: 'last_name',
      value: '',
    },
  });

  const { watch } = methods;
  const formValues = watch();

  const debouncefilters = useDebounce<string>({
    value: getFilterFromValues(formValues),
    delay: 500,
    bypassValues: [''],
  });

  // State for pagination and ordering
  const [pagination, setPagination] = useState<Pagination>({
    'list_request.page_size': PAGE_SIZE,
    'list_request.page_offset': 0,
    'list_request.order_by': 'updated_at DESC',
  });

  // Reset pagination to first page when filter changes
  useEffect(() => {
    setPagination({
      ...pagination,
      'list_request.page_size': PAGE_SIZE,
      'list_request.page_offset': 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncefilters, setPagination]);

  const [total, setTotal] = useState<number>();

  const {
    data,
    isPending,
    error,
    refetch: refetchMembers,
  } = useGetMembers({
    filter: debouncefilters,
    pagination,
  });

  // Prepare next page
  useGetMembers({
    filter: debouncefilters,
    pagination: {
      ...pagination,
      'list_request.page_offset':
        pagination['list_request.page_offset'] +
        pagination['list_request.page_size'],
    },
  });

  const { members = [] } = data || {};

  // Set a [total] to be displayed even while loading time
  useEffect(() => {
    if (data?.total) {
      setTotal(parseInt(data?.total));
    }
  }, [data, setTotal]);

  if (error) {
    return <GenericError />;
  }

  const totalMembers = total || parseInt(data?.total || '0');

  const children = (
    <Grid container spacing="2rem">
      <Grid item mobile={12}>
        <MembersTable
          members={members}
          refetchMembers={refetchMembers}
          isPending={isPending}
          pagination={pagination}
          setPagination={setPagination}
          total={totalMembers}
        />
      </Grid>
      <Grid item mobile={12}>
        <Grid container justifyContent="center">
          <PaginationComponent
            totalData={totalMembers}
            dataPerPage={pagination['list_request.page_size']}
            page={
              pagination['list_request.page_offset'] /
                pagination['list_request.page_size'] +
              1
            }
            onChange={page => {
              setPagination({
                ...pagination,
                'list_request.page_offset':
                  pagination['list_request.page_size'] * (page - 1),
              });
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <FormProvider {...methods}>
      <Grid container spacing="2rem" width={{ mobile: 'auto', laptop: '100%' }}>
        <Grid item mobile={12}>
          <Text
            variant="titleXXL"
            color="custom.neutral.black.100"
            marginTop="0.3125rem"
          >
            {t('pageListTitle')}
          </Text>
        </Grid>
        <Grid item mobile={12}>
          <Filters />
        </Grid>
        <Grid item mobile={12}>
          {children}
        </Grid>
      </Grid>
    </FormProvider>
  );
};
