// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useQuery } from '@tanstack/react-query';
import { CustomAPIError } from 'core/errors/CustomAPIError';
import useImperativeRequest from 'hooks/useImperativeRequest';
import { ExtractFnReturnType, QueryConfig } from 'lib/react-query';
import { map } from 'lodash/fp';
import { createSerializer, parseAsArrayOf, parseAsString } from 'nuqs';
import { useParams } from 'react-router-dom';

import { AlertEntity } from '../entities/AlertEntity';
import { AlertShipmentEntity } from '../entities/AlertShipmentEntity';

const searchParams = {
  // Pagination
  'list_request.page_size': parseAsString,
  'list_request.page_offset': parseAsString,
  // Filters
  'filter.ongoing_from': parseAsString,
  'filter.ongoing_to': parseAsString,
  'filter.alert_type': parseAsArrayOf(parseAsString),
};

// Create a serializer function by passing the description of the search params to accept
const serialize = createSerializer(searchParams);

const convertToAlertEntity = (shipments: { [key: string]: any }) =>
  map((entry: any) => {
    const shipment = shipments[entry.event.shipment_id];
    const originalShipment = shipments[shipment.original_shipment_id];

    return AlertEntity.createFromAPI({
      ...entry,
      shipment,
      originalShipment,
    });
  });

export type FiltersType = {
  alertType?: string[];
  ongoingFrom?: string | '';
  ongoingTo?: string | '';
};

export type Pagination = {
  pageSize: number;
  pageOffset: number;
};

export type MembersResponse = {
  total: number;
  alerts: AlertEntity[];
  shipments: { [key: string]: AlertShipmentEntity };
  offset: number;
};

export const getAlerts = async ({
  partnerId,
  filters,
  pagination,
  makeRequest,
}: {
  partnerId: string;
  filters?: FiltersType;
  pagination?: Pagination;
  makeRequest: any;
}): Promise<MembersResponse> => {
  const queryParams: Record<string, string | number | string[] | undefined> = {
    // Pagination
    ...(pagination && {
      'list_request.page_size': pagination.pageSize,
      'list_request.page_offset': pagination.pageOffset,
    }),
    // Filters
    ...(filters?.alertType &&
      filters?.alertType.length > 0 && {
        'filter.alert_type': filters?.alertType,
      }),
    ...(filters?.ongoingFrom && {
      'filter.ongoing_from': filters?.ongoingFrom,
    }),
    ...(filters?.ongoingTo && { 'filter.ongoing_to': filters?.ongoingTo }),
  };

  const encodedQueryParams = serialize(queryParams);

  const { data, error } = await makeRequest({
    path: `me/partners/${partnerId}/alerts/ongoing${encodedQueryParams}`,
  });

  if (error) {
    throw CustomAPIError.create(error.status, error?.response?.data);
  }

  return {
    total: data.total,
    alerts: convertToAlertEntity(data.shipments)(data.entries),
    shipments: data.shipments,
    offset: pagination?.pageOffset || 0,
  };
};

type QueryFnType = typeof getAlerts;

type UseMembersOptions = {
  config?: QueryConfig<QueryFnType>;
  filters?: FiltersType;
  pagination?: Pagination;
};

export const useAlerts = ({
  config,
  filters,
  pagination,
}: UseMembersOptions = {}) => {
  const { partnerId } = useParams() as {
    partnerId: string;
  };

  const [, makeRequest] = useImperativeRequest('deviceV3');

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    // Always fetch the data from the server (No cache wanted)
    staleTime: 0,
    gcTime: 0,
    networkMode: 'always',
    queryKey: ['partnership', partnerId, 'alerts', filters, pagination],
    queryFn: () => getAlerts({ partnerId, filters, pagination, makeRequest }),
  });
};
