// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useMemo } from 'react';

import {
  AddIcon,
  ButtonV2 as Button,
  Grid,
  Stack,
  Text,
} from '@livingpackets/design-system-react-next';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { contactAddressSchema } from 'schemas/contactAddressSchema';

import { ItemAddress } from './ItemAddress';
import { MAX_ADDRESSES_BY_CONTACT } from '../../constants';
import { ContactForm, INITIAL_STATE_CONTACT_ADDRESS_FORM } from '../../types';

export const AddressesForm = () => {
  const { t } = useTranslation(['contactAddress', 'forms']);

  const { control, watch } = useFormContext<ContactForm>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'addresses',
    keyName: 'rhfId',
  });

  const firstAddress = watch('addresses.0');

  const isFirstAddressValid = useMemo(
    () => contactAddressSchema().isValidSync(firstAddress),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      firstAddress.city,
      firstAddress.company,
      firstAddress.countryCode,
      firstAddress.postalCode,
      firstAddress.street,
      firstAddress.type,
    ]
  );

  return (
    <Grid container spacing="1rem">
      <Grid
        item
        container
        mobile={12}
        justifyContent="space-between"
        gap=".5rem"
      >
        <Stack gap=".5rem">
          <Text variant="titleL">
            {t('contactAddress:create.block.address.title')}
          </Text>
          <Text variant="titleXS" color="custom.neutral.black.50">
            {t('contactAddress:create.block.address.subTitle')}
          </Text>
        </Stack>
        {isFirstAddressValid && fields.length < MAX_ADDRESSES_BY_CONTACT && (
          <Button
            data-testid="addAddressBtn"
            icon={AddIcon}
            onClick={() => append(INITIAL_STATE_CONTACT_ADDRESS_FORM)}
            disabled={fields.length >= 2}
          />
        )}
      </Grid>
      <Grid item mobile={12}>
        {fields.map((field, index) => (
          <ItemAddress
            key={field.id}
            index={index}
            canRemoveAddress={index > 0}
            remove={remove}
          />
        ))}
      </Grid>
    </Grid>
  );
};
