// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { MapIcon } from '@livingpackets/design-system-react-next';
import { Checkbox, Typography } from 'materialui-next';

import { CheckedRadio } from './CheckedRadio';
import { FilterSelectModes } from './FilterSelect';
import { UncheckedRadio } from './UncheckedRadio';
import Chip from '../Chip';

export const CheckboxItem = ({
  theme,
  checked,
  option: { label, color, labelPrefix },
  mode = FilterSelectModes.checkboxes,
}: any) => {
  let displayCheckbox = (
    <Checkbox
      disableRipple
      icon={<UncheckedRadio />}
      checkedIcon={<CheckedRadio />}
      sx={{
        padding: '0.125rem',
        width: '1rem',
        height: '1rem',
        color: theme.palette.custom.neutral.grey[50],
        '&.Mui-checked': {
          color: theme.palette.primary[300],
          border: 'none',
        },
        '& .MuiSvgIcon-root': {
          fontSize: '1rem',
        },
      }}
      checked={checked}
    />
  );

  const hideCheckbox = mode === FilterSelectModes.chips && !checked;
  if (hideCheckbox) {
    displayCheckbox = <></>;
  }

  return (
    <>
      {displayCheckbox}
      {labelPrefix}
      {mode === FilterSelectModes.checkboxes && (
        <Typography variant="bodyTextS400" color="custom.neutral.grey.900">
          {label}
        </Typography>
      )}
      {mode === FilterSelectModes.chips && (
        <Chip withDot avatar={<MapIcon />} color={color} label={label} />
      )}
    </>
  );
};
