// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useMutation } from '@tanstack/react-query';
import { CustomAPIError } from 'core/errors/CustomAPIError';
import useImperativeRequest from 'hooks/useImperativeRequest';
import { MutationConfig } from 'lib/react-query';
import { useParams } from 'react-router-dom';
import { filterEditableValues } from 'utils/filterEditableValues';

import { AddressEntity } from '../entities/AddressEntity';
import { ADDRESS_FORM_KEYS, ContactAddressForm } from '../types';

const createAddress =
  ({ partnerId, makeRequest }: { partnerId: string; makeRequest: any }) =>
  async ({
    contactId,
    values,
  }: {
    contactId: string;
    values: Partial<
      Omit<ContactAddressForm, 'defaultSender' | 'defaultRecipient'>
    >;
  }): Promise<AddressEntity> => {
    const filteredValues = filterEditableValues({
      values,
      fields: ADDRESS_FORM_KEYS,
    });

    const { data, error } = await makeRequest({
      path: `api/v1/me/partners/${partnerId}/contacts/${contactId}/addresses`,
      method: 'post',
      body: filteredValues,
    });

    if (!data || error) {
      throw CustomAPIError.create(error.status, error?.response?.data);
    }

    return AddressEntity.create(data);
  };

type UseCreateAddressOptions = {
  partnershipId?: string;
  config?: MutationConfig<ReturnType<typeof createAddress>>;
};

export const useCreateAddress = ({
  partnershipId,
  config,
}: UseCreateAddressOptions = {}) => {
  const { partnerId = partnershipId } = useParams();

  const [, makeRequest] = useImperativeRequest('lpVillage');

  return useMutation({
    ...config,
    mutationFn: createAddress({ partnerId: partnerId!, makeRequest }),
  });
};
