// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { ChevronDownIcon } from '@livingpackets/design-system-react-next';
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
  Grid2 as Grid,
  Typography,
  AccordionProps,
  styled,
} from 'materialui-next';

import { AlertDetails } from './AlertDetails';
import { AlertSummary } from './AlertSummary';
import { AlertEntity } from '../../entities/AlertEntity';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters square {...props} />
))(({ theme }) => ({
  border: '0',
  boxShadow: '0rem .25rem 1rem 0rem rgba(0, 0, 0, 0.08)',
  borderRadius: '.625rem',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
}));

export const DateItem = React.forwardRef<
  HTMLDivElement,
  { date: string; alerts: AlertEntity[]; index: number }
>(({ date, alerts, index }, ref) => (
  <Grid size={12} container>
    <Grid
      size={12}
      px="1.5rem"
      pt="1.5rem"
      pb="1rem"
      bgcolor="custom.neutral.grey.0"
      position="sticky"
      top="4.0625rem"
      zIndex={1}
    >
      <Typography
        variant="titleM"
        color="custom.neutral.grey.600"
        sx={{
          textTransform: 'capitalize',
        }}
      >
        {date}
      </Typography>
    </Grid>
    <Grid size={12} container spacing="0.5rem" px="1.5rem">
      {alerts.map((alert: AlertEntity, index: number) => (
        <Grid key={alert.id} size={12}>
          <Accordion>
            <AccordionSummary
              data-testid={`alert-summary-${alert.id}`}
              sx={{
                '&.Mui-focusVisible': {
                  bgcolor: 'custom.neutral.grey.50',
                },
              }}
              expandIcon={<ChevronDownIcon />}
              ref={alerts.length === index + 1 ? ref : null}
            >
              <AlertSummary alert={alert} />
            </AccordionSummary>
            <AccordionDetails>
              <AlertDetails alert={alert} />
            </AccordionDetails>
          </Accordion>
        </Grid>
      ))}
    </Grid>
  </Grid>
));

DateItem.displayName = 'DateItem';
