// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { Stack, Text } from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

import { SmileIcon } from './SmileIcon';

const HREF_MAILTO =
  'mailto:feedback@livingpackets.harvestrapp.com?subject=LivingPacket%20Feedback';

export const Feedback = () => {
  const { t } = useTranslation('general');

  return (
    <Stack
      component="a"
      href={HREF_MAILTO}
      direction="row"
      alignItems="center"
      gap=".425rem"
      sx={{
        position: 'fixed',
        top: '45%',
        right: '0',
        backgroundColor: '#0F7A54',
        padding: '.25rem .625rem',
        borderRadius: '.25rem .25rem 0 0',
        boxShadow: '0 .25rem 1rem 0 rgba(0, 0, 0, 0.08)',
        width: 'fit-content',
        height: '1.625rem',
        transform: 'translateY(-50%) rotate(-90deg)',
        transformOrigin: 'bottom right',
        '&:hover': {
          cursor: 'pointer',
        },
      }}
    >
      <Text
        component="span"
        color="custom.neutral.white.pure"
        sx={{
          transform: 'rotate(90deg)',
        }}
      >
        <SmileIcon />
      </Text>
      <Text variant="bodyTextM" color="custom.neutral.white.pure">
        {t('feedback')}
      </Text>
    </Stack>
  );
};
