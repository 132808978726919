// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { SentryCaptureException } from 'helpers/tracking';

export class AlertAddressEntity {
  static createFromAPI(address: any): AlertAddressEntity {
    try {
      // Validate data to ensure it matches the AlertAddressEntity type
      if (!address.id || typeof address.id !== 'string') {
        throw new Error('Invalid data: id must be a string');
      }

      return new AlertAddressEntity(address);
    } catch (error: any) {
      SentryCaptureException(error, null);

      throw new Error(`Failed to create AlertAddressEntity: ${error.message}`);
    }
  }

  id: string;
  company: string;
  firstName: string;
  lastName: string;

  constructor(data: any) {
    this.id = data.id;
    this.company = data.company;
    this.firstName = data.first_name;
    this.lastName = data.last_name;
  }

  // Function that return the company if present or concatenate firdst and last name otherwise
  get displayName(): string {
    return this.company || `${this.firstName} ${this.lastName}`;
  }
}
