// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import * as React from 'react';

export const SmileIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} fill="none">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.403 3.597a4.812 4.812 0 1 0-6.806 6.806 4.812 4.812 0 0 0 6.806-6.806Zm.619-.619a5.688 5.688 0 1 1-8.044 8.044 5.688 5.688 0 0 1 8.044-8.044Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.267 8.258Zm0 0 .001.001a2.508 2.508 0 0 0 .442.308 2.686 2.686 0 0 0 3.022-.308l.002-.002a.438.438 0 0 1 .617.62l-.31-.309.31.31H9.35l-.002.002-.003.004-.012.01a1.708 1.708 0 0 1-.161.138 3.561 3.561 0 0 1-4.47-.105 1.675 1.675 0 0 1-.036-.033l-.01-.01-.004-.004-.002-.001v-.001l.308-.31-.31.31a.437.437 0 0 1 .618-.62Z"
      clipRule="evenodd"
    />
  </svg>
);
