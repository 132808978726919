// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  HomeIcon,
  ShipmentsIcon,
  UserIcon,
  BoxesIcon,
  ContactIcon,
  AccountInformationIcon,
  NotificationIcon,
} from '@livingpackets/design-system-react-next';
import Error403 from 'components/views/lpAccount/Error403';
import Error404 from 'components/views/lpAccount/Error404';
import PartnerBuyAndRent from 'components/views/lpVillage/PartnerBuyAndRent';
import PartnerHome from 'components/views/lpVillage/PartnerHome';
import PartnerShipments from 'components/views/lpVillage/PartnerShipments';
import {
  LpVillageScopes,
  LpVillageServices,
  PATHS,
  ROUTE_PATHS,
} from 'configs';
import { PARTNER_ACCOUNT_ROOT, PartnerAccount } from 'features/account';
import { ALERTS_ROOT, AlertsRoutes } from 'features/alerts';
import { ADDRESSES_BOOK_ROOT, AddressesBookRoutes } from 'features/contacts';
import { MEMBERS_ROOT, MembersRoutes } from 'features/members';
import { PRODUCTS_ROOT, ProductsRoutes } from 'features/products';
import useHasUserScopes from 'hooks/useHasUserScopes';
import usePartnerRouting from 'hooks/usePartnerRouting';
import { IRouteEntry } from 'models/routing';
import {
  Navigate,
  Route,
  RouteProps,
  Routes,
  useParams,
} from 'react-router-dom';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

export const PARTNER_PREFIX_ROOT = '/partner';
const PARTNER_ROOT = `${PARTNER_PREFIX_ROOT}/:partnerId`;

export const PARTNER_ROUTES: IRouteEntry[] = [
  {
    labelI18nKey: 'subnav.home',
    icon: HomeIcon,
    path: PATHS.DASHBOARD,
    routePath: ROUTE_PATHS.PARTNER.CHILDREN.DASHBOARD.ROOT,
    component: <PartnerHome />,
    testId: 'side-nav-partner-routes-home',
  },
  {
    labelI18nKey: 'subnav.shipments',
    icon: ShipmentsIcon,
    path: PATHS.SHIPMENT.LIST,
    routePath: ROUTE_PATHS.PARTNER.CHILDREN.SHIPMENTS.ROOT,
    component: <PartnerShipments />,
    testId: 'side-nav-partner-routes-shipments',
  },
  {
    labelI18nKey: 'subnav.alerts',
    icon: NotificationIcon,
    path: `${PARTNER_ROOT}${ALERTS_ROOT}`,
    routePath: `${ALERTS_ROOT}/*`,
    component: <AlertsRoutes />,
    testId: 'side-nav-partner-routes-alerts',
  },
  {
    labelI18nKey: 'subnav.userManagement',
    icon: UserIcon,
    path: `${PARTNER_ROOT}${MEMBERS_ROOT}`,
    routePath: `${MEMBERS_ROOT}/*`,
    component: <MembersRoutes />,
    requiredScopes: [LpVillageScopes.readPartnerUser],
    testId: 'side-nav-partner-routes-userManagement',
  },
  {
    labelI18nKey: 'subnav.contactAddressManagement',
    icon: ContactIcon,
    path: `${PARTNER_ROOT}${ADDRESSES_BOOK_ROOT}`,
    routePath: `${ADDRESSES_BOOK_ROOT}/*`,
    component: <AddressesBookRoutes />,
    testId: 'side-nav-partner-routes-contactAddressManagement',
  },
  {
    labelI18nKey: 'subnav.products',
    icon: BoxesIcon,
    path: `${PARTNER_ROOT}${PRODUCTS_ROOT}`,
    routePath: `${PRODUCTS_ROOT}/*`,
    component: <ProductsRoutes />,
    testId: 'side-nav-partner-routes-products',
  },
  {
    labelI18nKey: 'subnav.boxContracts',
    icon: ContactIcon,
    path: PATHS.BOX_CONTRACTS.LIST,
    routePath: ROUTE_PATHS.PARTNER.CHILDREN.BOX_CONTRACTS.ROOT,
    component: <PartnerBuyAndRent />,
    requiredScopes: [LpVillageScopes.readBoxContract],
    requiredServices: [LpVillageServices.buyAndRent],
    testId: 'side-nav-partner-routes-boxContracts',
  },
  {
    labelI18nKey: 'subnav.account',
    icon: AccountInformationIcon,
    path: `${PARTNER_ROOT}${PARTNER_ACCOUNT_ROOT}`,
    routePath: `${PARTNER_ACCOUNT_ROOT}/*`,
    component: <PartnerAccount />,
    requiredScopes: [
      LpVillageScopes.readPartner,
      LpVillageScopes.readPartnerAddress,
    ],
    testId: 'side-nav-partner-routes-account',
  },
];

const PartnerRoute = ({
  requiredScopes,
  component,
}: RouteProps & { requiredScopes?: LpVillageScopes[]; component: any }) => {
  const hasRequiredScopes = useHasUserScopes(requiredScopes || []);

  return hasRequiredScopes ? component : <Error403 />;
};

const PartnerRouter = () => {
  const redirect = usePartnerRouting();
  const activePartner = useMyPartnersStore(activePartnerSelector);
  const { partnerId } = useParams<{ partnerId: string }>();

  if (redirect) {
    return <Navigate replace to="/partner/" />;
  }

  if (activePartner?.id !== partnerId) {
    return null;
  }

  return (
    <Routes>
      <Route index element={<Navigate to="dashboard" />} />
      {PARTNER_ROUTES.map(route => (
        <Route
          key={route.path}
          index={route.index}
          path={!route.index ? route.routePath : undefined}
          element={
            <PartnerRoute
              component={route.component}
              requiredScopes={route.requiredScopes}
            />
          }
        />
      ))}
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

export default PartnerRouter;
