// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { useTheme } from 'materialui-next';

import { CheckboxItem } from './CheckboxItem';
import { CheckboxItemWrapper } from './CheckboxItemWrapper';
import { FilterOption } from './FilterSelect';

export const FilterCheckboxesChildrenElement = ({
  options,
  currentValue,
  onChange,
}: {
  options?: FilterOption[];
  currentValue: string[];
  onChange: (value: string[]) => void;
}) => {
  const theme = useTheme();

  return options?.map(option => {
    const { options } = option;

    // Handle any children options
    if (options) {
      const parentChecked =
        currentValue.indexOf(option.value) > -1 ||
        options.every(subOption => currentValue.indexOf(subOption.value) > -1);

      return [
        // Parent
        <CheckboxItemWrapper
          disableRipple
          key={option.value}
          dataTestid={`checkbox-item-${option.value}`}
          value={option.value}
          checked={parentChecked}
          theme={theme}
          onClick={() => {
            const childrenValues = options.map(subOption => subOption.value);

            const allChildrenChecked = options.every(
              subOption => currentValue.indexOf(subOption.value) > -1
            );
            if (allChildrenChecked) {
              // Remove all children values
              onChange(
                currentValue.filter(item => childrenValues.indexOf(item) === -1)
              );

              return;
            }

            // Add all children values
            onChange([
              ...currentValue,
              ...options.map(subOption => subOption.value),
            ]);
          }}
        >
          <CheckboxItem theme={theme} checked={parentChecked} option={option} />
        </CheckboxItemWrapper>,
        // Children options
        options.map(subOption => {
          const checked =
            currentValue.indexOf(subOption.value) > -1 ||
            currentValue.indexOf(option.value) > -1;

          return (
            <CheckboxItemWrapper
              disableRipple
              subItem
              key={subOption.value}
              dataTestid={`checkbox-item-${option.value}`}
              value={subOption.value}
              theme={theme}
              onClick={(event: any) => {
                event.stopPropagation();
                event.preventDefault();

                if (checked) {
                  onChange(
                    currentValue.filter(item => item !== subOption.value)
                  );

                  return;
                }

                onChange([...currentValue, subOption.value]);
              }}
              checked={checked}
            >
              <CheckboxItem
                theme={theme}
                checked={checked}
                option={subOption}
              />
            </CheckboxItemWrapper>
          );
        }),
      ];
    }

    const checked = currentValue.indexOf(option.value) > -1;

    return (
      <CheckboxItemWrapper
        disableRipple
        theme={theme}
        key={option.value}
        dataTestid={`checkbox-item-${option.value}`}
        value={option.value}
        checked={checked}
        onClick={(event: any) => {
          event.stopPropagation();
          event.preventDefault();

          if (currentValue.indexOf(option.value) > -1) {
            onChange(currentValue.filter(item => item !== option.value));

            return;
          }

          onChange([...currentValue, option.value]);
        }}
      >
        <CheckboxItem theme={theme} checked={checked} option={option} />
      </CheckboxItemWrapper>
    );
  });
};
