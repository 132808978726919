// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useContext, useState } from 'react';

import {
  ChevronDownIcon,
  ChevronUpIcon,
} from '@livingpackets/design-system-react-next';
import { DateCalendar } from '@mui/x-date-pickers';
import {
  MuiPickersAdapterContext,
  MuiPickersAdapterContextNullableValue,
} from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { format } from 'date-fns';
import { Grid2 as Grid, Typography, useTheme } from 'materialui-next';

import { CustomSelect } from './CustomSelect';
import { FilterSelectModes } from './FilterSelect';
import { RenderValue } from './RenderValue';

type Props = {
  dataTestId?: string;
  label: string;
  hideLabelWhenValue?: boolean;
  startIcon?: React.ElementType;
  showEndIcon?: boolean;
  maxDisplayed?: number;
  value: Date | null;
  onChange: (value: any) => void;
  minDate?: Date;
  maxDate?: Date;
};

export const FilterSelectCalendar = ({
  dataTestId,
  label,
  hideLabelWhenValue = false,
  startIcon,
  showEndIcon = true,
  value,
  onChange,
  minDate = new Date('2021-01-01'),
  maxDate = new Date(),
}: Props) => {
  const theme = useTheme();

  const ctx = useContext<MuiPickersAdapterContextNullableValue<any> | null>(
    MuiPickersAdapterContext
  );

  const [open, setOpen] = useState(false);

  /**
   * Clear date on click
   * @param event
   */
  const onMouseDown = (event: any) => {
    const chip = event.target.classList.contains('MuiChip-root')
      ? event.target
      : event.target.closest('.MuiChip-root');

    if (chip) {
      setOpen(false);

      onChange('');
    }
  };

  return (
    <CustomSelect
      data-testid={dataTestId}
      variant="standard"
      open={open}
      onMouseDown={onMouseDown}
      onOpen={(event: any) => {
        setOpen(true);
      }}
      onClose={(event: any) => {
        // Only close if click on backdrop
        if (event.target.classList.contains('MuiBackdrop-root')) {
          setOpen(false);
        }
      }}
      value={value || ''}
      displayEmpty
      IconComponent={() => <></>}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        sx: {
          '& .MuiPopover-paper.MuiMenu-paper': {
            mt: '0.375rem',
            padding: '0.375rem',
            boxShadow: '0 0.5rem 1.25rem 0 rgba(0, 11, 7, 0.10)',
            '& .MuiList-root': {
              py: 0,
            },
            borderRadius: '0.5rem',
          },
        },
      }}
      renderValue={(date: any) => (
        <RenderValue
          label={label}
          hideLabelWhenValue={hideLabelWhenValue}
          mode={FilterSelectModes.calendar}
          value={date}
          theme={theme}
          remove={() => onChange('')}
          startIcon={startIcon}
          showEndIcon={showEndIcon}
          arrowIcon={open ? ChevronUpIcon : ChevronDownIcon}
        />
      )}
    >
      <Grid
        container
        width="min-content"
        sx={{ fontFamily: theme.typography.fontFamily }}
      >
        {value && (
          <Grid
            size={12}
            height="2.5rem"
            alignContent="center"
            sx={{
              borderBottom: `0.0625rem solid ${theme.palette.custom.neutral.grey[50]}`,
            }}
          >
            <Typography
              variant="bodyTextS400"
              sx={{ opacity: 0.8 }}
              color="custom.neutral.grey.600"
            >
              {format(value as Date, 'P', {
                locale: ctx?.utils?.locale,
              })}
            </Typography>
          </Grid>
        )}
        <DateCalendar
          autoFocus
          disableFuture
          reduceAnimations
          maxDate={maxDate}
          minDate={minDate}
          value={value}
          onChange={onChange}
          slotProps={{
            day: {
              disableRipple: true,
            },
            switchViewButton: {
              disableRipple: true,
            },
            nextIconButton: {
              disableRipple: true,
            },
            previousIconButton: {
              disableRipple: true,
            },
          }}
          sx={{
            margin: 0,
            '& .MuiPickersDay-root': {
              color: theme.palette.custom.neutral.grey[900],
              fontSize: '0.75rem',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '0.875rem',
            },
            '& .MuiPickersDay-today': {
              borderColor: theme.palette.custom.neutral.grey[600],
            },
            '& .MuiPickersDay-root.Mui-selected, .MuiPickersDay-root.Mui-focusVisible.Mui-selected':
              {
                bgcolor: theme.palette.custom.neutral.grey[600],
                color: theme.palette.custom.neutral.grey[0],
                fontWeight: 600,
              },
            '& .MuiDayCalendar-weekDayLabel, .MuiPickersCalendarHeader-label': {
              color: theme.palette.custom.neutral.grey[600],
              fontWeight: 500,
              textTransform: 'capitalize',
            },
            // Month selector
            '& .MuiPickersYear-yearButton.Mui-selected': {
              bgcolor: theme.palette.custom.neutral.grey[600],
              color: theme.palette.custom.neutral.grey[0],
            },
          }}
        />
      </Grid>
    </CustomSelect>
  );
};
