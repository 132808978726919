// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useContext } from 'react';

import {
  ArrowLeftIcon,
  ArrowRightIcon,
  BoxOpenIcon,
  BulletEmptyIcon,
  FallIcon,
  LockOnIcon2,
} from '@livingpackets/design-system-react-next';
import { MuiPickersAdapterContext } from '@mui/x-date-pickers';
import { MuiPickersAdapterContextNullableValue } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { PARTNER_PREFIX_ROOT } from 'components/containers/PartnerRouter';
import { format, formatDistanceToNow, formatDistance } from 'date-fns';
import {
  Box,
  Chip,
  Grid2 as Grid,
  Stack,
  Typography,
  useTheme,
} from 'materialui-next';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';
import { Hyperlink } from 'theme/components/Hyperlink';

import { AlertEntity } from '../../entities/AlertEntity';
import { AlertTypes } from '../../types';

type Gender = 'female' | 'male';

export const AlertSummary = ({ alert }: { alert: AlertEntity }) => {
  const { t } = useTranslation('alerts');
  const theme = useTheme();

  const ctx = useContext<MuiPickersAdapterContextNullableValue<any> | null>(
    MuiPickersAdapterContext
  );

  const currentPartner = useMyPartnersStore(activePartnerSelector);
  const shipmentLink = `${PARTNER_PREFIX_ROOT}/${currentPartner.id}/shipments/${alert.shipmentId}/detail`;

  let Icon: React.ElementType = () => null;
  // Required for translation context
  let gender: Gender = 'female';
  let boxViolationTime;

  if (alert.type === AlertTypes.Shock) {
    Icon = FallIcon;
  } else if (alert.type === AlertTypes.BoxViolation) {
    Icon = BoxOpenIcon;

    if (alert.closedAt === null) {
      boxViolationTime = (
        <Chip
          color="error"
          label={t('items.common.summary.box_violation_active', {
            duration: formatDistanceToNow(new Date(alert.openedAt), {
              locale: ctx?.utils?.locale,
              includeSeconds: true,
            }),
          })}
        />
      );
    } else {
      boxViolationTime = (
        <Typography
          variant="titleXS"
          color="custom.neutral.grey.400"
          whiteSpace="nowrap"
        >
          {t('items.common.summary.box_violation_ended', {
            duration: formatDistance(
              new Date(alert.openedAt),
              new Date(alert.closedAt),
              {
                locale: ctx?.utils?.locale,
                includeSeconds: true,
              }
            ),
          })}
        </Typography>
      );
    }
  } else if (alert.type === AlertTypes.BoxCode) {
    gender = 'male';
    Icon = LockOnIcon2;
  }

  return (
    <Stack
      direction="row"
      width="100%"
      alignItems="center"
      justifyContent="space-between"
      sx={{ marginRight: '1rem' }}
      gap={1}
    >
      <Stack direction="row" gap={1}>
        <Box
          sx={{
            minWidth: '2rem',
            width: '2rem',
            height: '2rem',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'custom.semantics.error.50',
          }}
          color="custom.semantics.error.500"
        >
          <Icon size="1.25rem" />
        </Box>
        <Stack>
          <Stack direction="row">
            <Typography variant="bodyTextM400" color="custom.neutral.grey.900">
              <Trans
                t={t}
                i18nKey="items.common.summary.title"
                context={gender}
                values={{
                  alertType: t(`alertTypes.${alert.type}`),
                  shipmentName: alert.shipment.name,
                  shipmentDestination:
                    alert.shipment.recipient.address.displayName,
                }}
                components={[
                  <Typography
                    key={0}
                    variant="titleS"
                    color="custom.semantics.error.500"
                  />,
                  <Hyperlink
                    component={Link}
                    key={1}
                    data-testid={`link-to-shipment-${alert.shipmentId}`}
                    color="inherit"
                    underline="always"
                    // Prevent from opening accordion when opening in new tab in background using keyboard shortcut
                    onClick={e => e.stopPropagation()}
                    to={shipmentLink}
                    state={{ from: 'alerts' }}
                  />,
                ]}
              />
            </Typography>
          </Stack>
          <Grid container spacing="0.25rem" alignItems="center">
            <Stack direction="row">
              <Typography
                variant="bodyTextM400"
                color="custom.neutral.grey.600"
                display="flex"
                alignContent="center"
                gap="0.25rem"
              >
                {alert.originalShipment ? (
                  <>
                    <ArrowLeftIcon size="1rem" />
                    {t('items.common.summary.return')}
                  </>
                ) : (
                  <>
                    <ArrowRightIcon size="1rem" />
                    {t('items.common.summary.initial')}
                  </>
                )}
              </Typography>
            </Stack>
            <Box
              display="flex"
              justifyContent="center"
              width="20px"
              height="20px"
              pt="0.325rem"
            >
              <BulletEmptyIcon
                size="8px"
                color={theme.palette.custom.neutral.grey[300]}
              />
            </Box>
            <Typography variant="bodyTextS400" color="custom.neutral.grey.900">
              {alert.shipment.carrierTrackingCode}
            </Typography>
          </Grid>
        </Stack>
      </Stack>
      <Stack alignItems="flex-end" gap="0.25rem">
        <Typography
          variant="titleXS"
          color="custom.neutral.grey.400"
          sx={{ whiteSpace: 'nowrap' }}
        >
          {format(new Date(alert.openedAt), 'pp', {
            locale: ctx?.utils?.locale,
          })}
        </Typography>
        {boxViolationTime}
      </Stack>
    </Stack>
  );
};
