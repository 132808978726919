// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

export const AlertTypes = {
  Shock: 'alert_shock',
  BoxViolation: 'alert_box_violation',
  BoxCode: 'alert_box_code',
} as const;

export type AlertTypesKeys = (typeof AlertTypes)[keyof typeof AlertTypes];

// Some generic types
export type SimplePayload = {
  position: Position | null;
  threshold: number;
  thresholdType: ThresholdType;
  value: number;
};

export type ThresholdPayload = {
  positions: Position[];
  threshold: number;
  thresholdType: ThresholdType;
  values: ValueItem[];
  min: number;
  max: number;
};

// Specific types
export type BoxViolationPayload = {
  position: Position | null;
  value: {
    coverOpen: boolean;
    keyPresent: boolean;
    lightSensorOn: boolean;
  };
};

export type BoxCodePayload = {
  position: Position | null;
  value: number | null;
};

export type ShockPayload = SimplePayload;

export type Position = {
  lat: number;
  lon: number;
  ts: number;
};

export type ValueItem = {
  ts: number;
  value: number;
};

export enum ThresholdType {
  MAX = 'max',
  MIN = 'min',
}
