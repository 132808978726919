// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { NewCard as Card } from '@livingpackets/design-system-react-next';
import Header from 'components/layout/Header';

type Props = {
  children: React.ReactNode;
  onPrevious: () => void;
  path: string;
  subtitle: string;
  title: string | React.ReactNode;
};

const Layout = ({ children, onPrevious, path, subtitle, title }: Props) => (
  <Card
    headerProps={{
      variant: 'black',
      padding: '1.5rem',
      children: (
        <Header
          callback={onPrevious}
          path={path}
          subtitle={subtitle}
          title={title}
        />
      ),
    }}
    bodyProps={{
      children,
    }}
  />
);

export default Layout;
