// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { Theme } from 'materialui-next';

import { defaultChip } from './defaultChip';
import { errorChip } from './errorChip';
import { infoChip } from './infoChip';
import { primaryChip } from './primaryChip';
import { successChip } from './successChip';
import { warningChip } from './warningChip';

export const MuiChip = {
  MuiChip: {
    defaultProps: {
      // The props to change the default for.
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
    styleOverrides: {
      root: ({ theme }: { theme: Theme }) => ({
        /**
         * ---------------------------------
         * ------- Common Properties -------
         * ---------------------------------
         */
        gap: '0.25rem',
        padding: '0.375rem 0.5rem',

        // Label colors and typography
        '& .MuiChip-labelMedium': {
          padding: 0,
          ...theme.typography.labelM500,
        },
        '& .MuiChip-labelSmall': {
          padding: 0,
          ...theme.typography.labelS500,
        },
        '& .MuiChip-avatar': {
          marginLeft: '0',
          marginRight: '0',
        },

        borderRadius: '0.25rem', // ✅
        width: 'fit-content',
        // Heights ✅
        '&.MuiChip-sizeMedium': {
          height: '1.5rem',
        },
        '&.MuiChip-sizeSmall': {
          height: '1.125rem',
        },
        // Leading Icon ✅
        '& .MuiChip-icon': {
          marginLeft: 0,
          marginRight: 0,
        },
        '& .MuiChip-icon.MuiChip-iconMedium': {
          width: '0.75rem',
          height: '0.75rem',
        },
        '& .MuiChip-icon.MuiChip-iconSmall': {
          width: '0.625rem',
          height: '0.625rem',
        },
        // Delete Icon
        // ✅ taille bonne mais pas bon SVG dedans
        '& .MuiChip-deleteIcon': {
          borderRadius: '0.125rem',
          margin: 0,
          transition: `background-color ${theme.transitions.duration.standard}ms`,
        },
        '& .MuiChip-deleteIcon.MuiChip-deleteIconMedium': {
          width: '0.75rem',
          height: '0.75rem',
        },
        '& .MuiChip-deleteIcon.MuiChip-deleteIconSmall': {
          width: '0.625rem',
          height: '0.625rem',
        },
        '&.MuiChip-clickable:active': {
          boxShadow: 'none',
        },

        /**
         * ---------------------------------
         * ------- Custom Properties -------
         * ---------------------------------
         */
        ...defaultChip(theme),
        ...primaryChip(theme),
        ...errorChip(theme),
        ...infoChip(theme),
        ...warningChip(theme),
        ...successChip(theme),

        /**
         * ------- End of custom colors -------
         */
      }),
    },
  },
};
