// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useState } from 'react';

import {
  Checkbox,
  ModalV2 as Modal,
  Grid,
} from '@livingpackets/design-system-react-next';
import { ConfirmDefaultAddressModal } from 'components/molecules/Form/ConfirmDefaultAddressModal';
import { usePartnership } from 'features/account';
import { ContactForm } from 'features/contacts';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const NEW_DEFAULT_ADDRESS = 'NEW_DEFAULT_ADDRESS';

type Props = {
  addressId?: string;
  index: number;
  getName: any;
};

export const DefaultAddresses = ({ addressId, index, getName }: Props) => {
  const { t } = useTranslation('contactAddress');

  const { control, getValues, setValue } = useFormContext<ContactForm>();

  const addresses = getValues('addresses');
  const { data: partnership } = usePartnership();

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedType, setSelectedType] = useState<string | undefined>();

  const clearOtherDefaultAddress = (type?: string) => {
    addresses.forEach((address, localIndex) => {
      // Only while updating an existing address
      if (addressId && address.id === addressId) {
        return;
      }

      if (index === localIndex) {
        return;
      }

      const name: any = `addresses.${localIndex}.${type}`;

      if (getValues(name)) {
        setValue(name, false);
      }
    });
  };

  /**
   * Dismiss the modal and uncheck any previous default address if it was under the same contact
   *
   * @returns {void}
   */
  const onConfirm = () => {
    setShowConfirmationModal(false);

    clearOtherDefaultAddress(selectedType);
  };
  const onCancel = () => {
    setShowConfirmationModal(false);
    setValue(getName(selectedType), false);
  };

  const onChange = (
    name: string,
    hasPartnerDefault: boolean,
    onRHFChange: (...event: any[]) => void,
    event: any
  ) => {
    onRHFChange(event);

    setSelectedType(name);

    if (hasPartnerDefault && event.target.checked) {
      setShowConfirmationModal(true);
    } else {
      clearOtherDefaultAddress(name);
    }
  };

  return (
    <Grid container gap="1rem">
      <Grid item>
        <Controller
          control={control}
          name={getName('defaultSender')}
          render={({ field: { onChange: onRHFChange, ...rest } }) => (
            <Checkbox
              data-testid="defaultSenderCheckbox"
              label={t('defaultAddress.sender.checkboxLabel')}
              checked={getValues(getName('defaultSender'))}
              onChange={event => {
                onChange(
                  'defaultSender',
                  !!partnership?.default_sender_address_id,
                  onRHFChange,
                  event
                );
              }}
              {...rest}
            />
          )}
        />
      </Grid>
      <Grid item>
        <Controller
          control={control}
          name={getName('defaultRecipient')}
          render={({ field: { onChange: onRHFChange, ...rest } }) => (
            <Checkbox
              data-testid="defaultRecipientCheckbox"
              label={t('defaultAddress.recipient.checkboxLabel')}
              checked={getValues(getName('defaultRecipient'))}
              onChange={event =>
                onChange(
                  'defaultRecipient',
                  !!partnership?.default_recipient_address_id,
                  onRHFChange,
                  event
                )
              }
              {...rest}
            />
          )}
        />
      </Grid>
      <Modal
        open={showConfirmationModal}
        setOpen={setShowConfirmationModal}
        onDismiss={onCancel}
      >
        <ConfirmDefaultAddressModal
          type={selectedType}
          onCancel={onCancel}
          onConfirm={onConfirm}
        />
      </Modal>
    </Grid>
  );
};
