// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

export enum ContactAddressType {
  professional = 'professional',
  personal = 'personal',
}

export interface ContactAddressForm {
  id?: string;
  type: ContactAddressType;
  company?: string;
  city: string;
  street: string;
  postalCode: string;
  countryCode: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
  defaultSender: boolean;
  defaultRecipient: boolean;
}

export const INITIAL_STATE_CONTACT_ADDRESS_FORM: ContactAddressForm = {
  type: ContactAddressType.professional,
  company: '',
  street: '',
  city: '',
  postalCode: '',
  countryCode: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  defaultSender: false,
  defaultRecipient: false,
};

export interface ContactForm {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  addresses: ContactAddressForm[];
}

export const INITIAL_STATE_CONTACT_FORM: ContactForm = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  addresses: [INITIAL_STATE_CONTACT_ADDRESS_FORM],
};

export const CONTACT_FORM_KEYS = [
  'firstName',
  'lastName',
  'email',
  'phoneNumber',
  'addresses[].id',
  'addresses[].type',
  'addresses[].company',
  'addresses[].street',
  'addresses[].city',
  'addresses[].postalCode',
  'addresses[].countryCode',
];

export const ADDRESS_FORM_KEYS = [
  'type',
  'company',
  'street',
  'city',
  'postalCode',
  'countryCode',
  'firstName',
  'lastName',
  'email',
  'phoneNumber',
];
