// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { Box } from '@livingpackets/design-system-react-next';

import Languages from './footer/Languages';

interface FooterProps {
  showKoalendar?: boolean;
}

const Footer = ({ showKoalendar = false }: FooterProps) => (
  <Box
    sx={{ padding: '0.625rem' }}
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
  >
    <Languages />
  </Box>
);

export default Footer;
