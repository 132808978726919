// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

export const typography = {
  fontFamily: 'TT-Norms-Pro',
  /**
   * TITLE VARIANTS
   */
  titleXXXL: {
    fontFamily: 'TT-Norms-Pro',
    fontWeight: 500,
    fontSize: '2.5rem',
    lineHeight: '120%',
  },
  titleXXL: {
    fontFamily: 'TT-Norms-Pro',
    fontWeight: 500,
    fontSize: '2.25rem',
    lineHeight: '120%',
  },
  titleXL: {
    fontFamily: 'TT-Norms-Pro',
    fontWeight: 500,
    fontSize: '1.75rem',
    lineHeight: '130%',
  },
  titleL: {
    fontFamily: 'TT-Norms-Pro',
    fontWeight: 500,
    fontSize: '1.125rem',
    lineHeight: '135%',
  },
  titleM: {
    fontFamily: 'TT-Norms-Pro',
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '125%',
  },
  titleS: {
    fontFamily: 'TT-Norms-Pro',
    fontWeight: 500,
    fontSize: '.875rem',
    lineHeight: '125%',
  },
  titleXS: {
    fontFamily: 'TT-Norms-Pro',
    fontWeight: 500,
    fontSize: '.75rem',
    lineHeight: '120%',
  },
  titleXXS: {
    fontFamily: 'TT-Norms-Pro',
    fontWeight: 500,
    fontSize: '.625rem',
    lineHeight: '140%',
  },
  titleXXSUppercase: {
    fontFamily: 'TT-Norms-Pro',
    fontWeight: 500,
    fontSize: '.625rem',
    lineHeight: '120%',
    letterSpacing: '0.3%',
    textTransform: 'uppercase' as React.CSSProperties['textTransform'],
  },
  titleXXXSUppercase: {
    fontFamily: 'TT-Norms-Pro',
    fontWeight: 500,
    fontSize: '.5rem',
    lineHeight: '120%',
    letterSpacing: '0.4%',
    textTransform: 'uppercase' as React.CSSProperties['textTransform'],
  },
  /**
   * BODY TEXT VARIANTS
   */
  bodyTextL400: {
    fontFamily: 'TT-Norms-Pro',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '135%',
  },
  bodyTextL500: {
    fontFamily: 'TT-Norms-Pro',
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '125%',
  },
  bodyTextM400: {
    fontFamily: 'TT-Norms-Pro',
    fontWeight: 400,
    fontSize: '.875rem',
    lineHeight: '125%',
  },
  bodyTextM500: {
    fontFamily: 'TT-Norms-Pro',
    fontWeight: 500,
    fontSize: '.875rem',
    lineHeight: '125%',
  },
  bodyTextS400: {
    fontFamily: 'TT-Norms-Pro',
    fontWeight: 400,
    fontSize: '.75rem',
    lineHeight: '140%',
  },
  bodyTextS500: {
    fontFamily: 'TT-Norms-Pro',
    fontWeight: 500,
    fontSize: '.75rem',
    lineHeight: '140%',
  },
  bodyTextXS400: {
    fontFamily: 'TT-Norms-Pro',
    fontWeight: 400,
    fontSize: '.625rem',
    lineHeight: '140%',
  },
  bodyTextXS500: {
    fontFamily: 'TT-Norms-Pro',
    fontWeight: 500,
    fontSize: '.625rem',
    lineHeight: '140%',
  },
  /**
   * LABEL VARIANTS
   */
  labelM400: {
    fontFamily: 'TT-Norms-Pro',
    fontWeight: 400,
    fontSize: '.75rem',
    lineHeight: '125%',
  },
  labelM500: {
    fontFamily: 'TT-Norms-Pro',
    fontWeight: 500,
    fontSize: '.75rem',
    lineHeight: '125%',
  },
  labelM500Uppercase: {
    fontFamily: 'TT-Norms-Pro',
    fontWeight: 500,
    fontSize: '.75rem',
    lineHeight: '125%',
    letterSpacing: '0.4%',
    textTransform: 'uppercase' as React.CSSProperties['textTransform'],
  },
  labelS500: {
    fontFamily: 'TT-Norms-Pro',
    fontWeight: 500,
    fontSize: '.625rem',
    lineHeight: '100%',
  },
  // Disabled native variants
  h1: undefined,
  h2: undefined,
  h3: undefined,
  h4: undefined,
  h5: undefined,
  h6: undefined,
  subtitle1: undefined,
  subtitle2: undefined,
  body1: undefined,
  body2: undefined,
  button: undefined,
  caption: undefined,
  overline: undefined,
};
