// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { Suspense, useEffect } from 'react';

import { Box, Layout } from '@livingpackets/design-system-react-next';
import { Feedback } from 'features/feedback';
import useIsMobile from 'hooks/useIsMobile';
import useAppState, {
  anonymousTokenSelector,
  userSelector,
} from 'stores/appState/useAppState';
import useLayoutStore, { setOpenNavSelector } from 'stores/useLayoutStore';

import MobileHeader from './components/MobileHeader';
import NavigationMenu from './components/sidebar/NavigationMenu';

const AppLayout = ({ children }: { children: React.ReactNode }) => {
  const setOpenNav = useLayoutStore(setOpenNavSelector);

  const { isLaptop } = useIsMobile();

  useEffect(() => {
    if (isLaptop) {
      setOpenNav(true);
    } else {
      // Always close the nav on mobile on window resize
      setOpenNav(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const appUser = useAppState(userSelector);
  const anonymousToken = useAppState(anonymousTokenSelector);

  if (!appUser && !anonymousToken) {
    return <>{children}</>;
  }

  return (
    <Layout navBar={<NavigationMenu />} mobileHeader={<MobileHeader />}>
      <Feedback />
      <Box pb="1rem">
        <Suspense>{children}</Suspense>
      </Box>
    </Layout>
  );
};

export default AppLayout;
