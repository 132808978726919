// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useQuery } from '@tanstack/react-query';
import { CustomAPIError } from 'core/errors/CustomAPIError';
import useImperativeRequest from 'hooks/useImperativeRequest';
import { ExtractFnReturnType, QueryConfig } from 'lib/react-query';
import { useParams } from 'react-router-dom';
import { QueryParams } from 'types/queryParams';
import { objectToURLSearchParams } from 'utils/urlParams';

import { MemberEntity } from '../entities/MemberEntity';
import { MemberAPIV3 } from './models/MemberAPIV3';

export type MembersResponse = {
  total: string;
  members: MemberEntity[];
};

export const getMembers = async ({
  partnerId,
  filter,
  pagination,
  makeRequest,
}: {
  partnerId: string;
  filter?: string;
  pagination?: any;
  makeRequest: any;
}): Promise<MembersResponse> => {
  const queryParams: QueryParams = {
    ...pagination,
    'list_request.filter': filter,
  };

  const { data, error } = await makeRequest({
    path: `me/partners/${partnerId}/accounts?${objectToURLSearchParams(
      queryParams as Record<string, string>
    )}`,
  });

  if (error) {
    throw CustomAPIError.create(error.status, error?.response?.data);
  }

  return {
    total: data.total,
    members: data.partner_accounts.map((member: MemberAPIV3) =>
      MemberEntity.createFromV3(member)
    ),
  };
};

type QueryFnType = typeof getMembers;

type UseMembersOptions = {
  config?: QueryConfig<QueryFnType>;
  filter?: any;
  pagination?: any;
};

export const useGetMembers = ({
  config,
  filter,
  pagination,
}: UseMembersOptions = {}) => {
  const { partnerId } = useParams() as {
    partnerId: string;
  };

  const [, makeRequest] = useImperativeRequest('lpVillageV3');

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['partnership', partnerId, 'members', filter, pagination],
    queryFn: () => getMembers({ partnerId, filter, pagination, makeRequest }),
  });
};
