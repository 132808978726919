// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useEffect, useMemo, useState } from 'react';

import { ThemeProvider } from '@livingpackets/design-system-react';
import { ThemeProvider as ThemeProviderNext } from '@livingpackets/design-system-react-next';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { QueryClientProvider } from '@tanstack/react-query';
import AppState from 'components/containers/AppState';
import GlobalStyle from 'components/containers/GlobalStyle';
import AppLayout from 'components/containers/layout/AppLayout';
import MainRouter from 'components/containers/MainRouter';
import MessageHubIntl from 'components/containers/MessageHubIntl';
import { MobileTrap } from 'components/containers/MobileTrap';
import PrivateRoute from 'components/containers/PrivateRoute';
import HelmetTitle from 'components/molecules/HelmetTitle';
import RedirectToLanding from 'components/RedirectToLanding';
import DeepLinkShipmentDetailRoute from 'components/route/deepLink/DeepLinkShipmentDetailRoute';
import DeepLinkShipmentListRoute from 'components/route/deepLink/DeepLinkShipmentListRoute';
import AccountCreatedSuccess from 'components/views/authentication/AccountCreatedSuccess';
import AccountTypeChoice from 'components/views/authentication/AccountTypeChoice';
import AddPartnershipInformationPage from 'components/views/authentication/AddPartnershipInformationPage';
import FinalizeAccount from 'components/views/authentication/FinalizeAccount';
import InvitationCheck from 'components/views/authentication/InvitationCheck';
import LandingPage from 'components/views/authentication/LandingPage';
import RetrieveDeliveryKeyPage from 'components/views/authentication/RetrieveDeliveryKeyPage';
import VerifyEmail from 'components/views/authentication/VerifyEmail';
import { DownloadMobileApps } from 'components/views/DownloadMobileApps';
import Error403 from 'components/views/lpAccount/Error403';
import Error404 from 'components/views/lpAccount/Error404';
import PartnerInsuranceActivationSuccess from 'components/views/lpVillage/PartnerInsuranceActivationSuccess';
import { PATHS, REACT_APP_IS_DEEP_LINK_ACTIVATED, ROUTE_PATHS } from 'configs';
import { de, enUS, fr } from 'date-fns/locale';
import { UserBusinessTypeEnum } from 'enums/UserBusinessTypeEnum';
import { Onboarding } from 'features/onboarding';
import { useUpdateOnboardingWebDone } from 'features/onboarding';
import {
  ShipmentAnonymous,
  SHIPMENT_ANONYMOUS_ROOT,
  SHIPMENT_ANONYMOUS_SHORT_LINK,
} from 'features/shipments';
import { captureError, ErrorTypes } from 'helpers/errorTracing';
import { queryClient } from 'lib/react-query';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes } from 'react-router-dom';
import useAppState, {
  errorSelector,
  userSelector,
} from 'stores/appState/useAppState';
import { useErrorBoundary } from 'use-error-boundary';

import { useGetMeContributions } from './hooks/useGetMeContributions';

const locales = { 'en-US': enUS, 'fr-FR': fr, 'de-DE': de };
const languagesToLocales: { [key: string]: LocaleKey } = {
  en: 'en-US',
  fr: 'fr-FR',
  de: 'de-DE',
};

type LocaleKey = keyof typeof locales;

const DashboardV2App = () => {
  const appError = useAppState(errorSelector);
  const appUser = useAppState(userSelector);
  const { ErrorBoundary, didCatch, error } = useErrorBoundary();

  const { i18n } = useTranslation();

  // Update locale based on i18n language (to be improved later)
  const [locale, setLocale] = useState<LocaleKey>(
    Intl.DateTimeFormat().resolvedOptions().locale as LocaleKey
  );

  useEffect(() => {
    setLocale(languagesToLocales[i18n.language!] as LocaleKey);
  }, [i18n.language]);

  useEffect(() => {
    appError && captureError(appError, { type: ErrorTypes.appError });
    didCatch && captureError(error, { type: ErrorTypes.appError });
    appError && captureError(new Error('appError'));
    didCatch && captureError(new Error('didCatch'));
  }, [didCatch, appError, error]);

  const contribution = useGetMeContributions();

  if (appUser) {
    contribution({}).then();
  }

  const { updateOnboardingWebDone } = useUpdateOnboardingWebDone();

  // Only show onboarding for BUSINESS users
  const showOnboarding = useMemo(
    () =>
      appUser?.onBoardingWebDone === false &&
      appUser?.businessType === UserBusinessTypeEnum.BUSINESS,
    [appUser]
  );

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProviderNext>
        <ThemeProvider>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={locales[locale]}
          >
            <GlobalStyle />
            <ErrorBoundary>
              <HelmetTitle />
              <AppState>
                <MessageHubIntl />
                <Routes>
                  {/* Routing Anonymous */}
                  <Route
                    path={ROUTE_PATHS.AUTHENTICATION.LANDING.ROOT}
                    element={
                      <MobileTrap>
                        <LandingPage />
                      </MobileTrap>
                    }
                  />
                  <Route
                    path={PATHS.AUTHENTICATION.RETRIEVE_DELIVERY_KEY}
                    element={
                      <MobileTrap>
                        <RetrieveDeliveryKeyPage />
                      </MobileTrap>
                    }
                  />
                  <Route
                    path={PATHS.DOWNLOAD_MOBILE_APPS}
                    element={<DownloadMobileApps />}
                  />

                  {/* ROUTES ANONYMOUS SHIPMENTS */}

                  <Route
                    path={SHIPMENT_ANONYMOUS_ROOT}
                    element={<ShipmentAnonymous />}
                  />
                  <Route
                    path={SHIPMENT_ANONYMOUS_SHORT_LINK}
                    element={<ShipmentAnonymous />}
                  />

                  {REACT_APP_IS_DEEP_LINK_ACTIVATED && (
                    <Route
                      path={PATHS.DEEP_LINK.SHIPMENT_LIST}
                      element={<DeepLinkShipmentListRoute />}
                    />
                  )}
                  {REACT_APP_IS_DEEP_LINK_ACTIVATED && (
                    <Route path={ROUTE_PATHS.DEEP_LINK.SHIPMENT.ROOT}>
                      <Route
                        path={ROUTE_PATHS.DEEP_LINK.SHIPMENT.CHILDREN.ID.ROOT}
                      >
                        <Route
                          index
                          element={<DeepLinkShipmentDetailRoute />}
                        />
                        <Route
                          path={
                            ROUTE_PATHS.DEEP_LINK.SHIPMENT.CHILDREN.ID.TIMELINE
                              .ROOT
                          }
                          element={<DeepLinkShipmentDetailRoute />}
                        />
                      </Route>
                    </Route>
                  )}
                  <Route path={PATHS.PAGE_403} element={<Error403 />} />
                  <Route path={PATHS.PAGE_404} element={<Error404 />} />

                  {!appUser && (
                    <Route
                      index
                      element={
                        <RedirectToLanding to={{ pathname: PATHS.LANDING }} />
                      }
                    />
                  )}

                  {/* Routing Authenticated */}

                  <Route
                    path={PATHS.VERIFY_EMAIL}
                    element={
                      !appUser ? (
                        <PrivateRoute component={<VerifyEmail />} />
                      ) : !appUser.emailVerified ? (
                        <PrivateRoute component={<VerifyEmail />} />
                      ) : (
                        <Navigate to={PATHS.ROOT} />
                      )
                    }
                  />
                  <Route
                    path={PATHS.AUTHENTICATION.INVITATION_CHECK}
                    element={<PrivateRoute component={<InvitationCheck />} />}
                  />
                  <Route
                    path={PATHS.AUTHENTICATION.FINALIZE_ACCOUNT}
                    element={<PrivateRoute component={<FinalizeAccount />} />}
                  />
                  <Route
                    path={PATHS.AUTHENTICATION.ACCOUNT_TYPE_CHOICE}
                    element={<PrivateRoute component={<AccountTypeChoice />} />}
                  />
                  <Route
                    path={PATHS.AUTHENTICATION.ADD_PARTNERSHIP_INFORMATION}
                    element={
                      <PrivateRoute
                        component={<AddPartnershipInformationPage />}
                      />
                    }
                  />
                  <Route
                    path={PATHS.AUTHENTICATION.ACCOUNT_CREATED_SUCCESS}
                    element={
                      <PrivateRoute component={<AccountCreatedSuccess />} />
                    }
                  />
                  <Route
                    path={PATHS.SETTINGS.INSURANCE_ACTIVATION_SUCCESS}
                    element={
                      <PrivateRoute
                        component={<PartnerInsuranceActivationSuccess />}
                      />
                    }
                  />

                  <Route
                    path="*"
                    element={
                      <MobileTrap>
                        {showOnboarding && (
                          <Onboarding
                            onClose={() => updateOnboardingWebDone(true)}
                          />
                        )}
                        <AppLayout>
                          <MainRouter />
                        </AppLayout>
                      </MobileTrap>
                    }
                  />
                </Routes>
              </AppState>
            </ErrorBoundary>
          </LocalizationProvider>
        </ThemeProvider>
      </ThemeProviderNext>
    </QueryClientProvider>
  );
};

export default DashboardV2App;
