// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { SentryCaptureException } from 'helpers/tracking';

import {
  AlertTypes,
  AlertTypesKeys,
  BoxCodePayload,
  BoxViolationPayload,
  ShockPayload,
} from '../types';
import { AlertShipmentEntity } from './AlertShipmentEntity';

export class AlertEntity {
  static createFromAPI(alert: any): AlertEntity {
    try {
      // Validate data to ensure it matches the AlertAPI type
      if (!alert.event || typeof alert.event !== 'object') {
        throw new Error('Invalid data: data must be an object');
      }

      return new AlertEntity(alert);
    } catch (error: any) {
      SentryCaptureException(error, null);

      throw new Error(`Failed to create AlertEntity: ${error.message}`);
    }
  }

  id: string;
  type: AlertTypesKeys;
  productId: string;
  lpui: string;
  shipmentId: string;
  openedAt: string; // ISO 8601
  closedAt: string; // ISO 8601
  payload: BoxViolationPayload | BoxCodePayload | ShockPayload;
  shipment: AlertShipmentEntity;
  // Only present for return shipments
  originalShipment?: AlertShipmentEntity;

  constructor(data: any) {
    const { event } = data;

    this.id = event.id;
    this.type = data.event_type;
    this.productId = event.product_id;
    this.lpui = data.lp_ui;
    this.shipmentId = event.shipment_id;
    this.openedAt = event.opened_at;
    this.closedAt = event.closed_at;

    this.shipment = AlertShipmentEntity.createFromAPI(data.shipment);
    this.originalShipment = data.originalShipment
      ? AlertShipmentEntity.createFromAPI(data.originalShipment)
      : undefined;

    switch (data.event_type) {
      case AlertTypes.BoxViolation:
        this.payload = {
          position: event.box_violation.position,
          value: {
            coverOpen: event.box_violation.value.cover_open,
            keyPresent: event.box_violation.value.key_present,
            lightSensorOn: event.box_violation.value.light_sensor_on,
          },
        };
        break;

      case AlertTypes.BoxCode:
        this.payload = {
          position: event.box_code.position,
          value: event.box_code.value,
        };
        break;

      case AlertTypes.Shock:
        this.payload = {
          position: event.shock.position,
          threshold: event.shock.threshold,
          thresholdType: event.shock.threshold_type,
          value: event.shock.value,
        };
        break;

      default:
        throw new Error(`Unknown alert type: ${data.event_type as string}`);
    }
  }
}
