// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { Dispatch, SetStateAction, useEffect } from 'react';

import {
  AddIcon,
  Box,
  ButtonV2,
  Grid,
  Stack,
  Text,
} from '@livingpackets/design-system-react-next';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AddressCard } from './AddressCard';
import { MAX_ADDRESSES_BY_CONTACT } from '../../constants';
import { AddressEntity } from '../../entities/AddressEntity';
import { ContactForm, INITIAL_STATE_CONTACT_ADDRESS_FORM } from '../../types';
import { ItemAddress } from '../form/ItemAddress';

type Props = {
  addresses: AddressEntity[];
  editMode: undefined | number;
  setEditMode: Dispatch<SetStateAction<undefined | number>>;
};

export const Addresses = ({ addresses, editMode, setEditMode }: Props) => {
  const { t } = useTranslation(['contactAddress', 'forms']);

  const {
    control,
    formState: { defaultValues },
  } = useFormContext<ContactForm>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'addresses',
    keyName: 'rhfId',
  });

  const editAddress = (index: number) => setEditMode(index);

  const isMaxAddressReached = fields.length >= MAX_ADDRESSES_BY_CONTACT;
  const isNewAddressInEdition =
    fields.length > 1 && editMode === fields.length - 1;
  const showAddButton = !isNewAddressInEdition && !isMaxAddressReached;

  const addAddress = () => {
    // Only append a new address if we do not have appended one yet
    if (fields.length === defaultValues!.addresses!.length) {
      append(INITIAL_STATE_CONTACT_ADDRESS_FORM);
      setEditMode(fields.length);
    } else {
      setEditMode(fields.length - 1);
    }
  };

  // Remove the newly added address if the user edits an existing one
  useEffect(() => {
    if (
      editMode !== fields.length - 1 &&
      fields.find(field => field.id === undefined)
    ) {
      remove(fields.length - 1);
    }
  }, [editMode, fields, remove]);

  return (
    <Grid container direction="row" spacing="1rem">
      <Grid item mobile={12}>
        <Grid container spacing="1rem" justifyContent="space-between">
          <Grid item>
            <Stack gap=".5rem" height="3rem">
              <Text variant="titleL">
                {t('contactAddress:create.block.address.title')}
              </Text>
              <Text variant="titleXS" color="custom.neutral.black.50">
                {t('contactAddress:create.block.address.subTitle')}
              </Text>
            </Stack>
          </Grid>
          <Grid item>
            {showAddButton && (
              <ButtonV2
                icon={AddIcon}
                onClick={addAddress}
                data-testId="addAddressBtn"
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item mobile={12}>
        <Grid container spacing="1rem">
          {addresses.map((address, index) => (
            <Grid
              item
              key={address.id}
              mobile={12}
              tablet={6}
              laptop={4}
              data-testId="addressInformationCard"
            >
              <AddressCard
                index={index}
                address={address}
                editAddress={() => editAddress(index)}
                canDeleteAddress={addresses.length > 1}
                setEditMode={setEditMode}
                editMode={editMode}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid
        item
        mobile={12}
        mt="2rem"
        display={editMode === undefined ? 'none' : 'initial'}
      >
        {fields.map((address, index) => (
          <Box
            key={address.id}
            display={index === editMode ? 'initial' : 'none'}
          >
            <ItemAddress
              index={index}
              addressId={address.id}
              canRemoveAddress={
                index === fields.length - 1 &&
                fields.length > defaultValues!.addresses!.length
              }
              remove={remove}
            />
          </Box>
        ))}
      </Grid>
    </Grid>
  );
};
