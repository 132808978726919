// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { SentryCaptureException } from 'helpers/tracking';

import { AddressEntity } from './AddressEntity';
import { ContactAPI } from '../api/models/ContactAPI';

export class ContactEntity {
  static create(data: ContactAPI): ContactEntity {
    try {
      // Validate data to ensure it matches the ProductAPI type
      if (!data || typeof data !== 'object') {
        throw new Error('Invalid data: data must be an object');
      }

      return new ContactEntity(data);
    } catch (error: any) {
      SentryCaptureException(error, null);

      throw new Error(`Failed to create ContactEntity: ${error.message}`);
    }
  }

  id: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  partnerId: string;
  addresses: AddressEntity[];
  createdAt: number;
  updatedAt: number;

  constructor(data: ContactAPI) {
    this.id = data.id;
    this.email = data.email;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.phoneNumber = data.phoneNumber;
    this.partnerId = data.partnerId;
    this.addresses = data.addresses || [];
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }
}
