// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import {
  ChevronDownIcon,
  ChevronUpIcon,
  Stack,
  styled,
  TableCell,
  TableHead,
  TableRow,
  Text,
  useTheme,
} from '@livingpackets/design-system-react-next';
import StatusLabel from 'components/atoms/StatusLabel';
import { useTranslation } from 'react-i18next';

import { Pagination } from '../../routes/Members';

const StyledHeaderRow = styled(TableRow)(({ theme }) => ({
  '& > th': {
    border: 0,
  },
}));

export const TableHeader = ({
  total,
  isPending,
  pagination,
  setPagination,
}: {
  total: number;
  isPending: boolean;
  pagination: Pagination;
  setPagination: Dispatch<SetStateAction<Pagination>>;
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['userManagement', 'general']);

  const [initialSortBy, initialOrder] =
    pagination['list_request.order_by'].split(' ');

  const [sortBy, setSortBy] = useState(initialSortBy);
  const [order, setOrder] = useState(initialOrder);

  // Reset pagination when sort changes
  useEffect(() => {
    setPagination({
      ...pagination,
      'list_request.order_by': `${sortBy} ${order}`,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, order]);

  return (
    <TableHead>
      <StyledHeaderRow>
        {/* Avatar */}
        <TableCell sx={{ width: '3rem' }} />
        {/* Vertical divider */}
        <TableCell sx={{ width: '0.0625rem' }} />
        <TableCell sx={{ width: '9.375rem' }}>
          <OrderableHeaderCell
            field="last_name"
            sortBy={sortBy}
            setSortBy={setSortBy}
            order={order}
            setOrder={setOrder}
            theme={theme}
          >
            <Text variant="titleXS">
              {t('userManagement:list.header.lastName')}
            </Text>
          </OrderableHeaderCell>
        </TableCell>
        <TableCell sx={{ width: '9.375rem' }}>
          <OrderableHeaderCell
            data-testid="header-first_name"
            field="first_name"
            sortBy={sortBy}
            setSortBy={setSortBy}
            order={order}
            setOrder={setOrder}
            theme={theme}
          >
            <Text variant="titleXS">
              {t('userManagement:list.header.firstName')}
            </Text>
          </OrderableHeaderCell>
        </TableCell>
        <TableCell sx={{ width: '18rem' }}>
          <OrderableHeaderCell
            data-testid="header-email"
            field="email"
            sortBy={sortBy}
            setSortBy={setSortBy}
            order={order}
            setOrder={setOrder}
            theme={theme}
          >
            <Text variant="titleXS">
              {t('userManagement:list.header.email')}
            </Text>
          </OrderableHeaderCell>
        </TableCell>
        <TableCell sx={{ width: '7rem' }}>
          <OrderableHeaderCell
            data-testid="header-role"
            field="role"
            sortBy={sortBy}
            setSortBy={setSortBy}
            order={order}
            setOrder={setOrder}
            theme={theme}
          >
            <Text variant="titleXS">
              {t('userManagement:list.header.role')}
            </Text>
          </OrderableHeaderCell>
        </TableCell>
        <TableCell sx={{ width: '5rem' }}>
          <StatusLabel status="primary">
            {t('general:list.total', {
              number: isPending ? 0 : total,
            })}
          </StatusLabel>
        </TableCell>
      </StyledHeaderRow>
    </TableHead>
  );
};

const OrderableHeaderCell = ({
  field,
  children,
  sortBy,
  setSortBy,
  order,
  setOrder,
  theme,
}: any) => {
  const isSorted = sortBy === field;
  const isAsc = order === 'ASC';
  const isDesc = order === 'DESC';

  const handleSort = () => {
    if (isSorted) {
      setOrder(isAsc ? 'DESC' : 'ASC');
    } else {
      setSortBy(field);
      setOrder('DESC');
    }
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap="0.5rem"
      onClick={handleSort}
      data-testid={`header-${field}`}
    >
      {children}
      {isSorted && isAsc && (
        <ChevronUpIcon
          size="1.5rem"
          color={theme.palette.custom.primary[100]}
        />
      )}
      {isSorted && isDesc && (
        <ChevronDownIcon
          size="1.5rem"
          color={theme.palette.custom.primary[100]}
        />
      )}
    </Stack>
  );
};
