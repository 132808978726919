// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useMutation } from '@tanstack/react-query';
import useImperativeRequest from 'hooks/useImperativeRequest';
import useToastMessages from 'hooks/useToastMessages';
import { MutationConfig, queryClient } from 'lib/react-query';
import { filterValues } from 'utils/filterEditableValues';

import { Partnership } from '../../types/partnership';

export type FormValues = {
  description?: string;
  internal?: boolean;
  partner_id_card: {
    country_code?: string;
    vat_number?: string;
    company_id?: string;
    email?: string;
    website?: string;
  };
  phone_number?: string;
};

export const formKeys = [
  'description',
  'internal',
  'partner_id_card.country_code',
  'partner_id_card.vat_number',
  'partner_id_card.company_id',
  'partner_id_card.email',
  'partner_id_card.website',
  'phone_number',
];

const updatePartnership =
  ({
    initialValues,
    makeRequest,
  }: {
    initialValues?: Partial<Partnership>;
    makeRequest: any;
  }) =>
  async ({
    values,
    partnershipId,
  }: {
    values: Partial<Partnership>;
    partnershipId: string;
  }): Promise<Partnership> => {
    let filteredValues: Partial<Partnership> = values;
    if (initialValues) {
      filteredValues = filterValues<Partial<Partnership>>({
        initialValues,
        values,
        formKeys,
      });
    }

    const { data, error } = await makeRequest({
      path: `me/partners/${partnershipId}`,
      method: 'patch',
      body: filteredValues,
    });

    if (!data || error) {
      throw new Error(error);
    }

    return data;
  };

type UseUpdatePartnershipOptions = {
  initialValues?: Partial<Partnership>;
  showSuccessToast?: boolean;
  showErrorsToast?: boolean;
  config?: MutationConfig<ReturnType<typeof updatePartnership>>;
};

export const useUpdatePartnership = ({
  initialValues,
  showSuccessToast = true,
  showErrorsToast = true,
  config,
}: UseUpdatePartnershipOptions = {}) => {
  const { error: toastError } = useToastMessages();
  const [, makeRequest] = useImperativeRequest('lpVillageV3');

  return useMutation({
    onError: () => {
      if (showErrorsToast) toastError('messages:updateProfileError.message');
    },
    onSuccess: data => {
      queryClient.setQueryData(['partnership', data.id], data);
    },
    ...config,
    mutationFn: updatePartnership({
      initialValues,
      makeRequest,
    }),
  });
};
