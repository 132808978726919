// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { SentryCaptureException } from 'helpers/tracking';

import { AlertAddressEntity } from './AlertAddressEntity';

export class AlertContactEntity {
  static createFromAPI(contact: any): AlertContactEntity {
    try {
      // Validate data to ensure it matches the AlertContactEntity type
      if (!contact.id || typeof contact.id !== 'string') {
        throw new Error('Invalid data: id must be a string');
      }

      return new AlertContactEntity(contact);
    } catch (error: any) {
      SentryCaptureException(error, null);

      throw new Error(`Failed to create AlertContactEntity: ${error.message}`);
    }
  }

  id: string;
  address: AlertAddressEntity;

  constructor(data: any) {
    this.id = data.id;
    this.address = AlertAddressEntity.createFromAPI(data.address);
  }
}
