// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback } from 'react';

import { PATHS } from 'configs';
import {
  generatePath,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

import { CreateMember } from './CreateMember';
import { Members } from './Members';
import { UpdateMember } from './UpdateMember';

// http://localhost:3000/partner/:partnerId/management

/**
 * Base URL: /partner/:partnerId/management/*
 */
export const MEMBERS_ROOT = '/management';

export const MembersRoutes = () => {
  const navigate = useNavigate();

  const activePartner = useMyPartnersStore(activePartnerSelector);

  const backToMembersList = useCallback(() => {
    navigate(
      generatePath(PATHS.USER_MANAGEMENT.LIST, {
        partnerId: activePartner.id,
      })
    );
  }, [navigate, activePartner]);

  return (
    <Routes>
      <Route path="" index element={<Members />} />
      <Route
        path="create"
        element={<CreateMember backToMembersList={backToMembersList} />}
      />
      <Route
        path=":memberId/edit"
        element={<UpdateMember backToMembersList={backToMembersList} />}
      />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
};
