// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useCallback, useEffect, useRef, useState } from 'react';

import { parseAsArrayOf, parseAsString, useQueryState } from 'nuqs';

import { FiltersType, Pagination, useAlerts } from '../api/getAlerts';
import { AlertEntity } from '../entities/AlertEntity';

const PAGE_SIZE = 20;

/**
 * Custom hook to manage the view state for alerts.
 *
 * This hook handles query parameters, pagination, local states for alerts, shipments, and total alerts.
 * It also manages infinite scrolling by observing the last alert element in the list.
 *
 * @returns {object} An object containing:
 * - `queryParams`: An object with query parameters and their setters.
 *   - `alertType`: The type of alerts.
 *   - `setAlertType`: Setter for alert type.
 *   - `ongoingFrom`: The ongoing from date.
 *   - `setOngoingFrom`: Setter for ongoing from date.
 *   - `ongoingTo`: The ongoing to date.
 *   - `setOngoingTo`: Setter for ongoing to date.
 * - `changeValue`: A function to change a value and reset pagination and alerts.
 * - `query`: The query object returned by the `useAlerts` hook.
 * - `alerts`: The full list of alerts to be displayed.
 * - `shipments`: The full list of shipments related to the alerts.
 * - `totalAlerts`: The total number of alerts.
 * - `lastAlertElementRef`: A callback ref function for infinite scrolling.
 */
export const useAlertsView = () => {
  const observer = useRef<IntersectionObserver | null>(null);

  /**
   * Query Params
   */
  const [alertType, setAlertType] = useQueryState<any>(
    'type',
    parseAsArrayOf(parseAsString).withDefault([])
  );
  const [ongoingFrom, setOngoingFrom] = useQueryState<string | ''>(
    'from',
    parseAsString.withDefault('')
  );
  const [ongoingTo, setOngoingTo] = useQueryState<string | ''>(
    'to',
    parseAsString.withDefault('')
  );

  const filters: FiltersType = { alertType, ongoingFrom, ongoingTo };

  /**
   * Pagination
   */
  const [pagination, setPagination] = useState<Pagination>({
    pageSize: PAGE_SIZE,
    pageOffset: 0,
  });

  /**
   * Local states used to gather (merge) all data from backend.
   * Due to infinite scrolling, we need a state that sums all alerts (and shipments) comming from the backend.
   *
   * `alerts` - The full list of alerts to be displayed.
   * `total` - The total number of alerts.
   */
  const [isPending, setIsPending] = useState<boolean>(true);
  const [alerts, setAlerts] = useState<AlertEntity[]>([]);
  const [total, setTotal] = useState<number>();

  const query = useAlerts({
    config: {
      refetchOnWindowFocus: false,
    },
    filters,
    pagination,
  });

  const { data } = query;

  /**
   *
   * @param setter any nuqs state setter
   */
  const changeValue = (setter: any) => {
    setIsPending(true);
    setAlerts([]);

    // Reset pagination
    setPagination({
      pageSize: PAGE_SIZE,
      pageOffset: 0,
    });

    setter();
  };

  useEffect(() => {
    if (data?.alerts) {
      if (data.offset === 0) {
        setAlerts(data.alerts);
      } else {
        setAlerts(previousAlerts => [...previousAlerts, ...data.alerts]);
      }

      setIsPending(false);
    }

    if (data?.total) {
      setTotal(data?.total);
    }
  }, [data, setAlerts, setTotal, setIsPending]);

  /**
   * Infinite scrolling
   * A callback ref function that is used to observe the last date alert element in the list.
   * When the last alert element becomes visible in the viewport, it triggers pagination
   * to load more alerts if there are more alerts available.
   *
   * @param {any} node - The DOM node to be observed.
   * @returns {void}
   */
  const lastAlertElementRef = useCallback(
    (node: any) => {
      if (query.isPending) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          if (total && alerts.length < total) {
            setIsPending(true);
            setPagination(prevPagination => ({
              ...prevPagination,
              pageOffset: prevPagination.pageOffset + PAGE_SIZE,
            }));
          }
        }
      });

      if (node) observer.current.observe(node);
    },
    [query.isPending, total, alerts.length]
  );

  // Keep previous total while loading next request (infinite scrolling or new filters)
  const totalAlerts = query.isFetching
    ? total || data?.total || 0
    : data?.total || 0;

  return {
    queryParams: {
      alertType,
      setAlertType,
      ongoingFrom,
      setOngoingFrom,
      ongoingTo,
      setOngoingTo,
    },
    changeValue,
    query,
    isPending,
    alerts,
    totalAlerts,
    lastAlertElementRef,
  };
};
