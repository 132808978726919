// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  Box,
  ButtonV2 as Button,
} from '@livingpackets/design-system-react-next';
import {
  ArrowIcon,
  EmptyState,
  Text,
} from '@livingpackets/design-system-react-next';
import { PATHS } from 'configs';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

export const EmptyResult = ({
  hasFilters,
  clearSearch,
}: {
  hasFilters: boolean;
  clearSearch: () => void;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['userManagement']);
  const { partnerId } = useParams() as { partnerId: string };

  const goToNewMember = () =>
    navigate(
      generatePath(PATHS.USER_MANAGEMENT.CREATE, {
        partnerId,
      })
    );

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      {hasFilters ? (
        <EmptyState type="Contact">
          <Text variant="titleM" color="custom.neutral.black.50">
            {t('userManagement:list.noResult.emptyMessage')}
          </Text>
          <Text variant="bodyTextL" color="custom.neutral.black.50">
            {t('userManagement:list.noResult.emptySubMessage')}
          </Text>
          <Button
            icon={ArrowIcon}
            sx={{ marginTop: '1.625rem' }}
            data-testid="partner-account-clear-search-button"
            onClick={clearSearch}
          >
            {t('userManagement:list.noResult.cta')}
          </Button>
        </EmptyState>
      ) : (
        <EmptyState type="Contact">
          <Text variant="titleM" color="custom.neutral.black.50">
            {t('userManagement:list.emptyState.emptyMessage')}
          </Text>
          <Text variant="bodyTextL" color="custom.neutral.black.50">
            {t('userManagement:list.emptyState.emptySubMessage')}
          </Text>
          <Button
            icon={ArrowIcon}
            sx={{ marginTop: '1.625rem' }}
            data-testid="empty-state-create-member-button"
            onClick={goToNewMember}
          >
            {t('userManagement:list.emptyState.cta')}
          </Button>
        </EmptyState>
      )}
    </Box>
  );
};
