// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useContext } from 'react';

import {
  ArrowDownIcon,
  TimeIcon,
  ArrowRightIcon,
} from '@livingpackets/design-system-react-next';
import { MuiPickersAdapterContext } from '@mui/x-date-pickers';
import { MuiPickersAdapterContextNullableValue } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { format } from 'date-fns';
import { normalizeKey } from 'helpers/i18n';
import { Grid2 as Grid, Stack, Chip, useTheme, Divider } from 'materialui-next';
import { useTranslation } from 'react-i18next';
import { formatLpui } from 'utils/product';

import { InfoDetail } from './InfoDetail';
import { Timeline } from './Timeline';
import { AlertEntity } from '../../entities/AlertEntity';
import { AlertTypes } from '../../types';

export const AlertDetails = ({ alert }: { alert: AlertEntity }) => {
  const theme = useTheme();

  const isFailInfoDisplay = AlertTypes.Shock === alert.type;
  const isTimeInfoDisplay = [
    AlertTypes.BoxViolation,
    AlertTypes.BoxCode,
  ].includes(alert.type as any);
  const isTimeFullInfoDisplay = AlertTypes.BoxViolation === alert.type;
  const ctx = useContext<MuiPickersAdapterContextNullableValue<any> | null>(
    MuiPickersAdapterContext
  );

  const { t } = useTranslation('alerts');

  return (
    <>
      <Divider />
      <Stack gap="1.5rem" pl="2.5rem" py="1.5rem" justifyContent="space-around">
        <Timeline
          shipment={alert.shipment}
          originalShipment={alert.originalShipment}
        />
        <Grid
          container
          rowSpacing="0.5rem"
          columnSpacing="2rem"
          p="0.75rem"
          bgcolor="custom.neutral.grey.10"
          borderRadius=".5rem"
        >
          <Grid>
            <Stack gap="0.5rem">
              {isFailInfoDisplay && (
                <>
                  <InfoDetail
                    icon={
                      <ArrowDownIcon
                        size="0.75rem"
                        color={theme.palette.custom.neutral.grey[700]}
                      />
                    }
                    label={t('items.detail.alertFallLabel')}
                    value={
                      typeof alert?.payload?.value === 'number'
                        ? `${alert.payload.value * 100} cm`
                        : ''
                    }
                  />
                  <InfoDetail
                    icon={
                      <ArrowDownIcon
                        size="0.75rem"
                        color={theme.palette.custom.neutral.grey[700]}
                      />
                    }
                    label={t('items.detail.alertFallMaxheightLabel')}
                    value={
                      'threshold' in alert.payload
                        ? `${alert.payload.threshold * 100} cm`
                        : ''
                    }
                  />
                </>
              )}
              {isTimeInfoDisplay && (
                <Stack direction="row" gap="0.5rem" alignItems="center">
                  <InfoDetail
                    icon={
                      <TimeIcon
                        color={theme.palette.custom.neutral.grey[700]}
                        size="0.875rem"
                      />
                    }
                    label={t('items.detail.alertTimeStartLabel')}
                    value={format(new Date(alert.openedAt), 'p', {
                      locale: ctx?.utils?.locale,
                    })}
                  />
                  {isTimeFullInfoDisplay && (
                    <>
                      <ArrowRightIcon
                        size="0.75rem"
                        color={theme.palette.custom.neutral.grey[300]}
                      />
                      <InfoDetail
                        icon={
                          <TimeIcon
                            color={theme.palette.custom.neutral.grey[700]}
                            size="0.875rem"
                          />
                        }
                        label={t('items.detail.alertTimeEndLabel')}
                        value={
                          alert.closedAt === null ? (
                            <Chip
                              color="error"
                              label={t('items.detail.box_violation_active')}
                            />
                          ) : (
                            format(new Date(alert.closedAt), 'p', {
                              locale: ctx?.utils?.locale,
                            })
                          )
                        }
                      />
                    </>
                  )}
                </Stack>
              )}
            </Stack>
          </Grid>
          <Grid>
            <Stack gap="0.5rem">
              <InfoDetail
                label={t('items.detail.lpuiLabel')}
                value={formatLpui(alert.lpui)}
              />
              <InfoDetail
                label={t('items.detail.followUpNumberLabel')}
                value={`${alert.shipment.carrierTrackingCode} ${
                  alert.shipment.carrierCode
                    ? '(' +
                      t(
                        normalizeKey(
                          `shipments:${alert.shipment.carrierCode.toLowerCase()}`
                        )
                      ) +
                      ')'
                    : ''
                }`}
              />
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};
