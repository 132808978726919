// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  Partnership,
  usePartnership,
  useUpdatePartnership,
} from 'features/account';
import useToastMessages from 'hooks/useToastMessages';
import { useTranslation } from 'react-i18next';

import { useCreateAddress } from '../api/createAddress';
import { useCreateContact } from '../api/createContact';
import { ContactForm } from '../components/form';
import Layout from '../components/Layout';
import { ContactForm as IContactForm } from '../types';

type Props = {
  backToList: () => void;
};

export const CreateContact = ({ backToList }: Props) => {
  const { t } = useTranslation(['contactAddress']);

  const { data: partnership } = usePartnership();

  const { mutateAsync: createContact } = useCreateContact();
  const { mutateAsync: createAddress } = useCreateAddress();
  const { mutateAsync: updatePartnership } = useUpdatePartnership({
    showSuccessToast: false,
  });

  const { error: toastError, success: toastSuccess } = useToastMessages();

  /**
   * 1. Create the contact
   * 2. Create the address(es)
   * 3. Update the partnership with default sender/recipient (if checked)
   */
  const onSubmit = async (data: IContactForm) => {
    try {
      // Create contact
      const newContact = await createContact({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phoneNumber: data.phoneNumber,
      });

      const patchedData: Pick<
        Partnership,
        'default_sender_address_id' | 'default_recipient_address_id'
      > = {};

      // Create address
      for (const address of data.addresses) {
        const newAddress = await createAddress({
          contactId: newContact.id,
          values: {
            type: address.type,
            city: address.city,
            company: address.company,
            countryCode: address.countryCode,
            street: address.street,
            postalCode: address.postalCode,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            phoneNumber: data.phoneNumber,
          },
        });

        if (address.defaultSender) {
          patchedData.default_sender_address_id = newAddress.id;
        }

        if (address.defaultRecipient) {
          patchedData.default_recipient_address_id = newAddress.id;
        }
      }

      const shouldUpdate = Object.keys(patchedData).length > 0;

      if (shouldUpdate) {
        await updatePartnership({
          values: patchedData,
          partnershipId: partnership!.id,
        });
      }

      backToList();
      toastSuccess('messages:contactCreateSuccess.message');
    } catch (error) {
      toastError('messages:contactCreateError.message');
    }
  };

  return (
    <Layout
      onPrevious={backToList}
      path={t('contactAddress:create.breadcrumb')}
      subtitle={t('contactAddress:create.title')}
      title={t('contactAddress:create.subTitle')}
    >
      <ContactForm onSubmit={onSubmit} backToList={backToList} />
    </Layout>
  );
};
