// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useQuery } from '@tanstack/react-query';
import { CustomAPIError } from 'core/errors/CustomAPIError';
import useImperativeRequest from 'hooks/useImperativeRequest';
import { ExtractFnReturnType, QueryConfig } from 'lib/react-query';
import { useParams } from 'react-router-dom';

import { ContactEntity } from '../entities/ContactEntity';

export const getContactQueryKey = ({
  partnerId,
  contactId,
}: {
  partnerId: string;
  contactId: string;
}) => ['partnership', partnerId, 'contact', contactId];

export const getContact = async ({
  contactId,
  partnershipId,
  makeRequest,
}: {
  contactId: string;
  partnershipId?: string;
  makeRequest: any;
}): Promise<ContactEntity> => {
  const { data, error } = await makeRequest({
    path: `api/v1/me/partners/${partnershipId}/contacts/${contactId}`,
  });

  if (error) {
    throw CustomAPIError.create(error.status, error?.response?.data);
  }

  return ContactEntity.create(data);
};

type QueryFnType = typeof getContact;

type UseAddressOptions = {
  contactId: string;
  partnershipId?: string;
  config?: QueryConfig<QueryFnType>;
};

export const useContact = ({
  contactId,
  partnershipId,
  config,
}: UseAddressOptions) => {
  const { partnerId } = useParams();

  let id = partnershipId;
  if (!id) {
    id = partnerId;
  }

  const [, makeRequest] = useImperativeRequest('lpVillage');

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: getContactQueryKey({ partnerId: id!, contactId }),
    queryFn: () => getContact({ contactId, partnershipId: id, makeRequest }),
  });
};
