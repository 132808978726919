// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Grid,
  ButtonV2 as Button,
} from '@livingpackets/design-system-react-next';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { contactSchema } from 'schemas/contactAddressSchema';

import { AddressesForm } from './AddressesForm';
import { InformationsForm } from './InformationsForm';
import {
  ContactForm as IContactForm,
  INITIAL_STATE_CONTACT_FORM,
} from '../../types';

type Props = {
  defaultValues?: IContactForm;
  onSubmit: (data: IContactForm) => void;
  backToList: () => void;
};

export const ContactForm = ({ defaultValues, onSubmit, backToList }: Props) => {
  const { t } = useTranslation(['contactAddress', 'general']);

  const methods = useForm<IContactForm>({
    defaultValues: defaultValues || INITIAL_STATE_CONTACT_FORM,
    resolver: yupResolver(contactSchema()),
    mode: 'onTouched',
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  return (
    <FormProvider {...methods}>
      <Grid container direction="column" spacing="3rem">
        <Grid item mobile={12}>
          <InformationsForm />
        </Grid>
        <Grid item container mobile={12} spacing="1rem">
          <AddressesForm />
        </Grid>
        <Grid
          item
          container
          mobile={12}
          justifyContent="space-between"
          gap=".5rem"
        >
          <Button
            data-testid="dismissBtn"
            variant="secondary"
            onClick={backToList}
          >
            {t('contactAddress:form.dismiss.label')}
          </Button>
          <Button
            data-testid="submitBtn"
            variant="primary"
            disabled={!isValid}
            onClick={handleSubmit(onSubmit)}
          >
            {t('contactAddress:form.submit.label')}
          </Button>
        </Grid>
      </Grid>
    </FormProvider>
  );
};
