// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { SentryCaptureException } from 'helpers/tracking';

import {
  AlertsStatsAPI,
  AlertStatsItemAPI,
} from '../api/models/AlertsStatsAPI';

export class AlertsStatsEntity {
  static createFromAPI(data: AlertsStatsAPI): AlertsStatsEntity {
    try {
      // Validate data to ensure it matches the MemberAPI type
      if (!data || typeof data !== 'object') {
        throw new Error('Invalid data: data must be an object');
      }

      return new AlertsStatsEntity(data);
    } catch (error: any) {
      SentryCaptureException(error, null);

      throw new Error(`Failed to create MemberEntity: ${error.message}`);
    }
  }

  alert_box_violation: AlertStatsItemEntity;
  alert_box_code: AlertStatsItemEntity;

  constructor(data: AlertsStatsAPI) {
    this.alert_box_violation = new AlertStatsItemEntity(
      data.alert_box_violation
    );
    this.alert_box_code = new AlertStatsItemEntity(data.alert_box_code);
  }
}

export class AlertStatsItemEntity {
  nbAlerts: number | null;
  nbShipments: number | null;

  constructor(data: AlertStatsItemAPI | null) {
    this.nbAlerts = data?.nb_alerts || 0;
    this.nbShipments = data?.nb_shipments || 0;
  }
}
