// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useMutation } from '@tanstack/react-query';
import { CustomAPIError } from 'core/errors/CustomAPIError';
import useImperativeRequest from 'hooks/useImperativeRequest';
import { MutationConfig, queryClient } from 'lib/react-query';
import { useParams } from 'react-router-dom';
import {
  filterEditableValues,
  hasChangedValues,
} from 'utils/filterEditableValues';

import { AddressEntity } from '../entities/AddressEntity';
import { ADDRESS_FORM_KEYS, ContactAddressForm } from '../types';
import { getAddressQueryKey } from './getAddress';
import { getContactQueryKey } from './getContact';
import { ContactEntity } from '../entities/ContactEntity';

export const updateAddress =
  ({ partnerId, makeRequest }: { partnerId: string; makeRequest: any }) =>
  async ({
    contactId,
    addressId,
    initialValues,
    values,
  }: {
    contactId: string;
    addressId: string;
    initialValues: ContactAddressForm;
    values: Partial<
      Omit<ContactAddressForm, 'defaultSender' | 'defaultRecipient'>
    >;
  }): Promise<{
    contactId: string;
    address: AddressEntity;
  } | void> => {
    const filteredInitialValues = filterEditableValues({
      values: initialValues,
      fields: ADDRESS_FORM_KEYS,
    });
    const filteredValues = filterEditableValues({
      values,
      fields: ADDRESS_FORM_KEYS,
    });

    let data;

    const shouldUpdateAddress = hasChangedValues({
      initialValues: filteredInitialValues,
      values: filteredValues,
    });

    if (shouldUpdateAddress) {
      const { data: updatedAddress, error } = await makeRequest({
        path: `api/v1/me/partners/${partnerId}/contacts/${contactId}/addresses/${addressId}`,
        method: 'put',
        body: filteredValues,
      });

      data = updatedAddress;

      if (error) {
        throw CustomAPIError.create(error.status, error?.response?.data);
      }
    }

    if (data) {
      return {
        contactId,
        address: AddressEntity.create(data),
      };
    }
  };

type UseUpdateAddressOptions = {
  config?: MutationConfig<ReturnType<typeof updateAddress>>;
};

export const useUpdateAddress = ({ config }: UseUpdateAddressOptions = {}) => {
  const { partnerId } = useParams() as { partnerId: string };

  const [, makeRequest] = useImperativeRequest('lpVillage');

  return useMutation({
    ...config,
    onSuccess: data => {
      if (!data) return;

      const { address, contactId } = data;

      // Update the adress cache
      queryClient.setQueryData(
        getAddressQueryKey({ partnerId, addressId: address.id }),
        address
      );

      const computedNewContact = (
        oldContact: ContactEntity
      ): ContactEntity => ({
        ...oldContact,
        addresses: oldContact.addresses.map(oldAddr => {
          if (oldAddr.id === address.id) {
            return address;
          }

          return oldAddr;
        }),
      });

      // Update the corresponding contact related address
      queryClient.setQueryData(
        getContactQueryKey({ partnerId, contactId }),
        computedNewContact
      );
    },
    mutationFn: updateAddress({
      partnerId,
      makeRequest,
    }),
  });
};
