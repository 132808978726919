// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { isArray } from 'lodash/fp';

export const formatQueryParamsFilter = (params?: { [key: string]: any }) => {
  if (!params) return '';
  const criterias: any = [];

  const localParams = Object.keys(params).map(key => ({
    [key]: params[key],
  }));

  localParams.forEach((arg: any) => {
    const key = Object.keys(arg);
    let criteria: any = '';

    if (typeof arg[key[0]] === 'string' && arg[key[0]].length > 0) {
      criteria = `${key[0]}='${arg[key[0]]}'`;
    } else if (['boolean', 'number'].includes(typeof arg[key[0]])) {
      criteria = `${key[0]}=${arg[key[0]]}`;
    } else if (isArray(arg[key[0]])) {
      if (arg[key[0]] && arg[key[0]].length > 0) {
        criteria = arg[key[0]]
          .filter((v: any) => v !== '')
          .map((v: any) => [key[0], `'${v}'`].join('='))
          .join(' OR ');
      }
    }

    if (criteria) criterias.push(`(${criteria})`);
  });

  return criterias.join(' AND ');
};

export const objectToURLSearchParams = (
  queryParams:
    | string
    | string[][]
    | Record<string, string>
    | URLSearchParams
    | undefined
) => new URLSearchParams(queryParams).toString();
