// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { Box, styled } from '@livingpackets/design-system-react-next';

export const InputContainer = styled('div')`
  position: relative;
  width: 100%;
`;

export const SearchFieldIconContainer = styled(Box)`
  transition: 200ms;
  margin-left: 0;
  height: 1.5625rem;
  width: 1.5625rem;
`;

export const SearchFieldSelector = styled(Box, {
  shouldForwardProp: prop => prop !== 'isHighlighted',
})<{
  isHighlighted: boolean;
}>`
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
  border-width: 0.0625rem;
  border-style: solid none solid solid;
  border-color: ${({ theme, isHighlighted }) =>
    isHighlighted
      ? theme.palette.custom.primary[100]
      : theme.palette.custom.neutral.white.pure};
  background-color: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  height: 3.125rem;
  width: 11.75rem;
  min-width: 11.75rem;
  padding-left: 0.75rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  padding-right: 0.3125rem;
  &:before {
    content: '';
    position: absolute;
    right: 0;
    height: 2rem;
    width: 0.0625rem;
    background-color: ${({ theme }) => theme.palette.custom.neutral.black[20]};
    margin-top: auto;
    margin-bottom: auto;
    top: 50%;
    bottom: 50%;
  }
`;

export const PartnerAccountSearchFieldListContainer = styled(Box)`
  border-bottom-left-radius: 0.875rem;
  border-bottom-right-radius: 0.875rem;
  border-width: 0.0625rem;
  border-style: solid none solid solid;
  border-color: ${({ theme }) => theme.palette.custom.neutral.black[10]};
  background-color: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  position: absolute;
  left: 0;
  top: 3.125rem;
  width: 100%;
  padding-top: 0.75rem;
  margin-top: -0.75rem;
  box-shadow: 0rem 0.5rem 1.25rem rgba(0, 0, 0, 0.1);
`;

export const PartnerAccountSearchFieldContainer = styled(Box)`
  padding: 0.625rem;
  cursor: pointer;
  transition: 200ms;

  &:hover {
    background-color: ${({ theme }) => theme.palette.custom.primary[10]};
  }

  &:last-child {
    border-bottom-left-radius: 0.875rem;
    border-bottom-right-radius: 0.875rem;
  }
`;

export const InputIconContainer = styled('div')<{
  displaySearchMagnifierIcon: boolean;
}>`
  position: absolute;
  right: 0.75rem;
  top: ${({ displaySearchMagnifierIcon }) =>
    displaySearchMagnifierIcon ? '0.75rem' : '0.9375rem'};
`;

export const ClearButton = styled('button')`
  cursor: pointer;
  border: none;
  background: none;
`;
