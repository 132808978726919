// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { Button } from 'materialui-next';

export const CheckboxItemWrapper = ({
  subItem,
  children,
  checked,
  theme,
  dataTestid,
  ...rest
}: any) => (
  <Button
    data-testid={dataTestid}
    sx={{
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-start',
      alignItems: 'center',
      minHeight: '2rem',
      padding: `0.25rem ${subItem ? '1.75rem' : '0.5rem'}`,
      gap: '0.375rem',
      borderRadius: '0.25rem',
      color: 'custom.neutral.grey.900',
      transition: 'background-color 200ms',
      backgroundColor: checked ? 'primary.25' : 'custom.neutral.grey.0',
      border: `0.0625rem solid checked ? 'primary.25' : 'custom.neutral.grey.0'`,
      textAlign: 'left',
      '&.Mui-focusVisible': {
        outline: `0.0625rem solid ${theme.palette.primary[300]}`,
        zIndex: 1,
      },
      '&:hover': {
        backgroundColor: checked ? 'primary.25' : 'custom.neutral.grey.25',
      },
    }}
    {...rest}
  >
    {children}
  </Button>
);
