// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useQuery } from '@tanstack/react-query';
import { CustomAPIError } from 'core/errors/CustomAPIError';
import useImperativeRequest from 'hooks/useImperativeRequest';
import { ExtractFnReturnType, QueryConfig } from 'lib/react-query';
import { IContactAddress } from 'models/contactAddress';
import { useParams } from 'react-router-dom';

export const getAddressQueryKey = ({
  partnerId,
  addressId,
}: {
  partnerId: string;
  addressId: string;
}) => ['partnership', partnerId, 'address', addressId];

export const getAddress = async ({
  addressId,
  partnerId,
  makeRequest,
}: {
  addressId: string;
  partnerId: string;
  makeRequest: any;
}): Promise<IContactAddress> => {
  const { data, error } = await makeRequest({
    path: `api/v1/me/partners/${partnerId}/addresses?addressId=${addressId}`,
  });

  if (error) {
    throw CustomAPIError.create(error.status, error?.response?.data);
  }

  return data;
};

type QueryFnType = typeof getAddress;

type UseAddressOptions = {
  addressId: string;
  partnershipId?: string;
  config?: QueryConfig<QueryFnType>;
};

export const useAddress = ({
  addressId,
  partnershipId,
  config,
}: UseAddressOptions) => {
  const { partnerId = partnershipId } = useParams();

  const [, makeRequest] = useImperativeRequest('lpVillage');

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: getAddressQueryKey({ partnerId: partnerId!, addressId }),
    queryFn: () =>
      getAddress({ addressId, partnerId: partnerId!, makeRequest }),
  });
};
